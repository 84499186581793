/* Generated by restful-react */

import React from 'react';
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from 'restful-react';
export const SPEC_VERSION = '5.24.0';
/**
 * UUIDv4 string. See [RFC 4112](https://tools.ietf.org/html/rfc4122)
 */
export type Uuid = string;

export interface UserIdentifier {
  id: Uuid;
  type: 'users';
}

/**
 * A unique resource identifier
 */
export type Uri = string;

/**
 * Hexadecimal color code
 */
export type Color = string;

/**
 * User's display name
 */
export type Name = string;

export type UserSummary = UserIdentifier & {
  attributes: {
    /**
     * User's preference for receiving notification
     */
    notificationPreferences: {
      topics?: {
        chargingStarted: boolean;
        chargingInterrupted: boolean;
        chargingComplete: boolean;
      };
      enabled: boolean;
    };
    /**
     * Date the user was last updated
     */
    updated: string;
    /**
     * User's email address, which is also their Evnex account username
     */
    email: string;
    /**
     * Date the user was created
     */
    created: string;
    name?: Name;
    color?: Color;
    avatar?: Uri;
  };
};

export interface JobIdentifier {
  id: Uuid;
  type: 'jobs';
}

export interface LocationIdentifier {
  id: Uuid;
  type: 'locations';
}

/**
 * Organisation ID and User ID separated by an underscore, uniquely identifying a user's membership in an organisation.
 */
export type MembershipID = string;

export interface MembershipIdentifier {
  id: MembershipID;
  type: 'memberships';
}

export type User = UserSummary & {
  relationships: {
    /**
     * Organisation memberships for this user
     */
    memberships: {
      data: MembershipIdentifier[];
    };
    /**
     * Locations to which this (driver) user has access
     */
    locations: {
      data: LocationIdentifier[];
    };
    /**
     * Jobs assigned to this (installer) user
     */
    jobs: {
      data: JobIdentifier[];
    };
  };
};

/**
 * Type of user outside of any organisation context
 */
export type UserType = 'Superuser' | 'User' | 'Installer';

export type ScopedUser = User & {
  attributes: {
    userType: UserType;
  };
};

export interface OrganisationIdentifier {
  id: Uuid;
  type: 'organisations';
}

/**
 * Country code and party ID for this organisation in OCPI roaming context
 */
export type EmspId = string;

/**
 * Organisation's display name
 */
export type OrganisationName = string;

/**
 * Identifier that will be prefixed to the OCPP device IDs of this organisation's charge points
 */
export type NamespacePrefix = string;

/**
 * URL-compatible string, meant to be human-readable
 */
export type Slug = string;

/**
 * The billing tier of an organisation
 */
export type Tier = 1 | 2 | 4 | 5;

/**
 * Features and limits determined by the organisation's tier. If an attribute is present, the feature or limit applies; if not present, the tier has no associated limit/no access to that feature.
 */
export interface TierDetails {
  /**
   * Enabled feature: API Access
   */
  apiAccess?: boolean;
  /**
   * Enabled feature: Demand Response
   */
  demandResponse?: boolean;
}

export interface EnterpriseApiPermissions {
  allChargePointsList?: boolean;
  chargePointRead?: boolean;
  chargePointLoadManagementUpdate?: boolean;
  chargePointSessionsList?: boolean;
  chargePointMeterValuesList?: boolean;
  allSessionsList?: boolean;
}

export type OrganisationSummary = OrganisationIdentifier & {
  attributes: {
    /**
     * Date the organisation was last updated
     */
    updated: string;
    /**
     * Date the organisation was created
     */
    created: string;
    /**
     * Machine-to-machine API client ID
     */
    clientId?: string;
    apiPermissions?: EnterpriseApiPermissions;
    tierDetails: TierDetails;
    tier: Tier;
    slug: Slug;
    namespacePrefix: NamespacePrefix;
    name: OrganisationName;
    emspId?: EmspId;
    color?: Color;
    avatar?: Uri;
  };
};

/**
 * User's role in an organisation
 */
export type Role = 0 | 1 | 2;

export type MembershipSummary = MembershipIdentifier & {
  attributes: {
    /**
     * Timestamp when user's membership in the organisation was last updated
     */
    updatedDate: string;
    /**
     * Timestamp when user was added to the organisation
     */
    createdDate: string;
    /**
     * Whether this organisation is the user's default organisation
     */
    isDefault: boolean;
    role: Role;
  };
};

export type Membership = MembershipSummary & {
  relationships: {
    /**
     * Organisation in which user is a member
     */
    organisation: {
      data: OrganisationIdentifier;
    };
    /**
     * User who is a member of an organisation
     */
    user: {
      data: UserIdentifier;
    };
  };
};

export interface ResponseGetAuthenticatedUser {
  data: ScopedUser;
  included: (OrganisationSummary | Membership)[];
}

/**
 * Information about a problem encountered while performing an operation
 */
export interface APIError {
  id?: string;
  /**
   * An application-specific error code
   */
  code?: string;
  /**
   * A human-readable explanation specific to this occurrence of the problem
   */
  detail?: string;
  /**
   * Meta-information about the error
   */
  meta?: {
    correlationId: string;
  };
  /**
   * References to the source of the error
   */
  source?: {
    /**
     * Name of the URI query parameter that caused the error
     */
    parameter?: string;
    /**
     * A [JSON pointer](https://tools.ietf.org/html/rfc6901) to the associated entity in the request document that caused the error
     */
    pointer?: string;
  };
  /**
   * The HTTP [response status code](https://tools.ietf.org/html/rfc7231#section-6) applicable to the error
   */
  status: string;
  /**
   * A short, human-readable summary of the error type
   */
  title: string;
}

/**
 * An API error response object
 */
export interface ResponseApiErrors {
  errors: APIError[];
}

export interface RequestCreateUser {
  data: {
    attributes: {
      userType: UserType;
      name: Name;
      email: string;
      color?: Color;
      avatar?: Uri;
    };
    type: 'users';
  };
}

export interface Account {
  id: Uuid;
  email: string;
  enabled: boolean;
  status: 'ARCHIVED' | 'CONFIRMED' | 'FORCE_CHANGE_PASSWORD' | 'RESET_REQUIRED' | 'UNCONFIRMED' | 'UNKNOWN';
  createdDate: string;
  updatedDate: string;
}

export interface RequestModifyChargePointLocation {
  data: LocationIdentifier;
}

export interface ChargePointIdentifier {
  id: Uuid;
  type: 'chargePoints';
}

/**
 * [ISO 4217](https://www.iso.org/iso-4217-currency-codes.html) alphabetic currency code
 */
export type Currency = string;

/**
 * Price per kWh, with up to 4 decimal places of precision (hundredths of a cent)
 */
export type CurrencyAmount = string;

/**
 * Which electricity price applies to this time period in the schedule
 */
export type CostPeriodType =
  | 'Free'
  | 'Flat'
  | 'Day'
  | 'Night'
  | 'WeekendDay'
  | 'WeekendNight'
  | 'Peak'
  | 'OffPeak'
  | 'WeekendOffPeak';

/**
 * Electricity tariff periods over the course of a week
 */
export type Tariffs = {
  /**
   * Seconds after the beginning of the week (00:00:00 on Sunday)
   */
  start: number;
  type: CostPeriodType;
  rate: CurrencyAmount;
}[];

/**
 * Type of electricity tariff schedule
 */
export type TariffType = 'Flat' | 'DayNight' | 'PeakOffPeakNight';

/**
 * Weekly recurring electricity tariff schedule
 */
export interface ElectricityCost {
  currency: Currency;
  solarPrice?: CurrencyAmount;
  tariffs: Tariffs;
  tariffType: TariffType;
}

/**
 * Charge point network connectivity
 */
export type NetworkStatus = 'NEVER_CONNECTED' | 'OFFLINE' | 'ONLINE';

/**
 * Time period in a charging schedule
 */
export interface ChargingSchedulePeriod {
  /**
   * Number of seconds after the start of the schedule that the period begins
   */
  startPeriod: number;
  /**
   * Maximum current the charge point may draw during this period, in amperes
   */
  limit: number;
}

/**
 * Smart charging profile for a charge point. If multiple profiles are set, they will be combined into a single `combinedProfile`.
 */
export interface ChargingProfile {
  /**
   * Whether this profile is active
   */
  enabled: boolean;
  /**
   * Time periods in the charging profile
   */
  chargingSchedulePeriods: ChargingSchedulePeriod[];
}

/**
 * Smart charging profiles set on the charge point
 */
export interface ChargingProfiles {
  chargeSchedule?: ChargingProfile;
  loadManagement?: ChargingProfile;
  /**
   * Combined profile currently set on charge point. This is the default profile that will be set on the charge point during each Boot Notification process.
   */
  combinedProfile?: {
    /**
     * Time periods in the combined profile
     */
    chargingSchedulePeriods: ChargingSchedulePeriod[];
    /**
     * Starting time of the combined profile
     */
    startSchedule: string;
  };
}

/**
 * Time zone name from the [IANA Time Zone Database](https://www.iana.org/time-zones)
 */
export type TimeZone = string;

/**
 * Price per kWh, with a minimum of -2, with up to 4 decimal places of precision (hundredths of a cent)
 */
export type TariffAmount = string;

export interface TariffRate {
  amount: TariffAmount;
  type: 'TIME_OF_USE' | 'BLOCK';
}

export interface TariffRates {
  [key: string]: TariffRate;
}

export type Behaviour =
  | {
      type: 'Charge';
    }
  | {
      type: 'DoNotCharge';
    }
  | {
      type: 'Solar';
    };

export type TariffName = string;

export interface Period {
  importTariff: TariffName;
  behaviour: Behaviour;
  /**
   * Number of seconds since midnight of the start of the recurrence type
   */
  start: number;
}

/**
 * Format of a connector
 */
export type ConnectorFormat = 'CABLE' | 'SOCKET';

/**
 * Standard of a connector (`ConnectorType` in OCPI)
 */
export type ConnectorType =
  | 'CHADEMO'
  | 'IEC_62196_T1'
  | 'IEC_62196_T1_COMBO'
  | 'IEC_62196_T2'
  | 'IEC_62196_T2_COMBO'
  | 'IEC_62196_T3A'
  | 'IEC_62196_T3C';

/**
 * OCPP status of a connector's EVSE
 */
export type OcppStatus =
  | 'AVAILABLE'
  | 'CHARGING'
  | 'FAULTED'
  | 'FINISHING'
  | 'PREPARING'
  | 'RESERVED'
  | 'SUSPENDED_EV'
  | 'SUSPENDED_EVSE'
  | 'UNAVAILABLE';

/**
 * Charge point connector power type
 */
export type PowerType = 'AC_1_PHASE' | 'AC_3_PHASE' | 'DC';

/**
 * Last measured meter values for a connector
 */
export interface MeterValues {
  /**
   * Active current in amperes (A)
   */
  current?: number;
  /**
   * Active current measured on Phase 1 (A)
   */
  currentL1?: number;
  /**
   * Active current measured on Phase 2 (A)
   */
  currentL2?: number;
  /**
   * Active current measured on Phase 3 (A)
   */
  currentL3?: number;
  /**
   * Active frequency in hertz (Hz)
   */
  frequency?: number;
  /**
   * Active power in kilowatts (kW)
   */
  power?: number;
  /**
   * The meter's power register in watt-hours (Wh)
   */
  register?: number;
  /**
   * Percentage of battery charged
   */
  stateOfCharge?: number;
  /**
   * Active temperature in degrees Celsius (°C)
   */
  temperature?: number;
  /**
   * Date the meter value was last updated
   */
  updatedDate: string;
  /**
   * Active voltage in volts (V)
   */
  voltage?: number;
  /**
   * Active voltage measured on Phase 1 (V)
   */
  voltageL1N?: number;
  /**
   * Active voltage measured on Phase 2 (V)
   */
  voltageL2N?: number;
  /**
   * Active voltage measured on Phase 3 (V)
   */
  voltageL3N?: number;
}

/**
 * Charge point connector object
 */
export interface Connector {
  connectorFormat: ConnectorFormat;
  connectorType: ConnectorType;
  ocppStatus: OcppStatus;
  powerType: PowerType;
  meter?: MeterValues;
  /**
   * ID of the connector
   */
  connectorId: string;
  /**
   * Unique EVSE ID of the connector
   */
  evseId: string;
  /**
   * Maximum connector amperage value
   */
  maxAmperage: number;
  /**
   * Maximum connector voltage value
   */
  maxVoltage: number;
  /**
   * OCPP error code
   */
  ocppCode?: string;
  /**
   * Date the connector was last updated
   */
  updatedDate: string;
}

export type ChargePointSummary = ChargePointIdentifier & {
  attributes: {
    /**
     * Manufacturer of the charge point
     */
    vendor?: string;
    /**
     * Date the charge point was last updated
     */
    updatedDate: string;
    /**
     * Whether a token is required to start charging
     */
    tokenRequired: boolean;
    /**
     * Charge point's serial number
     */
    serial?: string;
    /**
     * Custom identifier for the charge point
     */
    referenceId?: string;
    /**
     * Charge point's device ID in OCPP
     */
    ocppChargePointId: string;
    /**
     * Date the charge point network status was last updated
     */
    networkStatusUpdatedDate: string;
    /**
     * Charge point's display name
     */
    name: string;
    /**
     * Charge point's model
     */
    model?: string;
    /**
     * Minimum charging current the charge point can draw, in amperes
     */
    minCurrent?: number;
    /**
     * Maximum charging current the charge point can draw, in amperes
     */
    maxCurrent: number;
    /**
     * Whether the charge point has Solar Diversion enabled.
     */
    isSolarEnabled?: boolean;
    /**
     * Unique serial number of charge point's SIM card (Integrated Circuit Card Identifier)
     */
    iccid?: string;
    /**
     * Charge point's firmware version
     */
    firmware?: string;
    /**
     * Default token to be set on charge point sessions
     */
    defaultToken?: string;
    /**
     * Date this charge point was created
     */
    createdDate: string;
    /**
     * Connectors on the charge point
     */
    connectors: Connector[];
    chargingConfiguration?: ChargingConfiguration;
    timeZone: TimeZone;
    profiles: ChargingProfiles;
    networkStatus: NetworkStatus;
    electricityCost?: ElectricityCost & {
      cost: number;
    };
  };
};

export type ChargePoint = ChargePointSummary & {
  relationships: {
    /**
     * Location where the charge point is installed
     */
    location: {
      data: LocationIdentifier | null;
    };
    /**
     * Organisation that owns the charge point
     */
    organisation: {
      data: OrganisationIdentifier | null;
    };
  };
};

/**
 * Location's display name
 */
export type LocationName = string;

export interface IcpDetails {
  /**
   * Where electricity flows out of the grid
   * Also known as grid exit point (GXP), a GXP often has multiple ICPs associated
   */
  networkConnectionPoint: string;
  /**
   * Name of distributor that provides power to the retailer in that region
   */
  electricityDistributor: string;
  /**
   * Name of retailer that sells power to the customer with this ICP
   */
  electricityRetailer: string;
}

export type LocationSummary = LocationIdentifier & {
  attributes: {
    /**
     * Date the location was last updated
     */
    updated: string;
    /**
     * Time zone of the location
     */
    timeZone: string;
    /**
     * Load balancing profile for a location
     */
    loadBalancing?: {
      /**
       * Maximum load amount in amps (A)
       */
      limit: number;
      /**
       * Whether the load balancing profile is enabled
       */
      enabled: boolean;
    };
    /**
     * Whether the location is available for public charging
     */
    isPublic: boolean;
    icpDetails?: IcpDetails;
    /**
     * Installation Control Point number, unique to a consumer's premises
     */
    icpNumber?: string;
    /**
     * Date the location was created
     */
    created: string;
    /**
     * GPS coordinates
     */
    coordinates: {
      /**
       * Degrees east (positive) or west (negative) of the prime meridian
       */
      longitude: string;
      /**
       * Degrees north (positive) or south (negative) of the equator
       */
      latitude: string;
    };
    /**
     * Physical address of the location
     */
    address: {
      /**
       * State or province
       */
      state?: string;
      /**
       * Postal code
       */
      postCode?: string;
      /**
       * ISO-31661 alpha-2 country code
       */
      country: string;
      /**
       * City or town
       */
      city: string;
      /**
       * Address line 3
       */
      address3?: string;
      /**
       * Address line 2
       */
      address2?: string;
      /**
       * Address line 1
       */
      address1: string;
    };
    name: LocationName;
  };
};

export interface ResponseListChargePoints {
  data: ChargePoint[];
  included: (OrganisationSummary | LocationSummary)[];
}

/**
 * Alphanumeric, hyphens, and underscores only; all characters must be percent-encoded
 */
export type UrlEncodedString = string;

export interface ResponseGetChargePoint {
  data: ChargePoint;
  included: LocationSummary[];
}

export interface ResponseUpdateChargePoint {
  data: ChargePointSummary;
}

export interface RequestUpdateChargePoint {
  data: {
    attributes: {
      tokenRequired?: boolean;
      referenceId?: string;
      name?: string;
      maxCurrent?: number;
      minCurrent?: number;
      electricityCost?: ElectricityCost;
      defaultToken?: string;
      chargingConfiguration?: ChargingConfiguration;
    };
    type: 'chargePoints';
    id: Uuid;
  };
}

export type FirmwareUpgradeStatus =
  | 'Accepted'
  | 'Unavailable'
  | 'Required'
  | 'Optional'
  | 'DownloadFailed'
  | 'Downloaded'
  | 'Downloading'
  | 'Idle'
  | 'InstallationFailed'
  | 'Installed'
  | 'Installing';

/**
 * A record of an event during the process of upgrading the charge point's
 * firmware.
 */
export interface FirmwareUpgrade {
  attributes: {
    /**
     * Human-readable message concerning the firmware upgrade
     */
    message?: string;
    /**
     * The next version of firmware to install
     */
    version?: string;
    status: FirmwareUpgradeStatus;
    /**
     * When the status was received
     */
    timestamp: string;
  };
  type: 'firmwareUpgrades';
  id: Uuid;
}

export interface ResponseGetFirmwareUpgrade {
  data: FirmwareUpgrade[];
}

export interface SessionIdentifier {
  id: Uuid;
  type: 'sessions';
}

/**
 * How this session was authorized
 */
export type AuthorizationMethod = 'NoTokenRequired' | 'Token' | 'OcpiWhitelist' | 'OcpiAuthRequest';

/**
 * Fraction out of 100
 */
export type Percent = number;

export interface PercentageByTariff {
  [key: string]: Percent;
}

export type WattHours = number;

/**
 * A session is considered to be in-progress unless its status is 'Completed' or 'Invalid'
 */
export type SessionStatus = 'Pending' | 'Authorized' | 'Active' | 'Closed' | 'Completed' | 'Invalid';

export interface LegacyPercentageByTariff {
  flat?: Percent;
  free?: Percent;
  solar?: Percent;
  day?: Percent;
  night?: Percent;
  peak?: Percent;
  offPeak?: Percent;
}

export interface PercentageByEnergySource {
  'AU-NSW'?: number;
  'AU-NT'?: number;
  'AU-QLD'?: number;
  'AU-SA'?: number;
  'AU-TAS'?: number;
  'AU-VIC'?: number;
  'AU-WA'?: number;
  /**
   * Proportion of energy discharged from battery energy storage systems (BESS)
   */
  batteryStorage?: number;
  /**
   * Proportion of energy from burning organic by-products
   */
  biomass?: number;
  /**
   * Proportion of energy from burning black or brown coal
   */
  coal?: number;
  /**
   * Proportion of energy from recycled gas in combined heat and power (CHP) systems
   */
  cogeneration?: number;
  /**
   * Proportion of energy from burning natural gas
   */
  gas?: number;
  /**
   * Proportion of energy from geothermal power stations
   */
  geothermal?: number;
  /**
   * Proportion of energy from hydroelectric stations
   */
  hydro?: number;
  /**
   * Proportion of energy discharged from pumped water storage systems
   */
  hydroStorage?: number;
  /**
   * Proportion of energy from nuclear fission reactors
   */
  nuclear?: number;
  /**
   * Proportion of energy from burning petroleum diesel, AKA distillate
   */
  oil?: number;
  /**
   * Proportion of energy from solar farms
   */
  solar?: number;
  /**
   * Proportion of energy from wind farms
   */
  wind?: number;
}

/**
 * Reason a transaction was stopped
 */
export type TransactionStopReason =
  | 'DeAuthorized'
  | 'EmergencyStop'
  | 'EVDisconnected'
  | 'HardReset'
  | 'Local'
  | 'Other'
  | 'PowerLoss'
  | 'Reboot'
  | 'Remote'
  | 'SoftReset'
  | 'UnlockCommand';

/**
 * A complete session object
 */
export type SessionSummary = SessionIdentifier & {
  attributes: {
    /**
     * Date session was last updated
     */
    updatedDate: string;
    /**
     * Session transaction details
     */
    transaction?: {
      /**
       * Transaction start date
       */
      startDate: string;
      /**
       * Transaction override date
       */
      overriddenAt?: string;
      /**
       * Transaction meter stop value (Wh)
       */
      meterStop?: number;
      /**
       * Transaction meter start value (Wh)
       */
      meterStart: number;
      /**
       * Transaction ID
       */
      id: number;
      /**
       * Transaction end date
       */
      endDate?: string;
      reason?: TransactionStopReason;
    };
    totalPowerUsage?: number;
    /**
     * Energy delivered during the session
     */
    totalEnergyUsage?: {
      /**
       * Total energy delivered (watt-hours)
       */
      total: number;
      /**
       * Detailed breakdown of the contribution to a session per mutually exclusive electricity production category, adding up to 100%
       */
      distributionByEnergySource?: PercentageByEnergySource & {
        /**
         * Proportion of energy from on-site solar diversion
         */
        localSolar?: number;
      };
      distributionByTariff?: LegacyPercentageByTariff;
    };
    /**
     * Time spent plugged in (minutes)
     */
    totalDuration?: number;
    /**
     * Session total electricity cost
     */
    totalCost?: {
      distribution?: LegacyPercentageByTariff;
      currency: Currency;
      amount: CurrencyAmount;
    };
    totalChargingTime?: number;
    /**
     * Total CO2 produced in grams
     */
    totalCarbonUsage?: number;
    /**
     * Token detail snapshot at the time of the session
     */
    token?: {
      /**
       * The human-readable name of the token
       */
      tokenName?: string;
      /**
       * Parent Token ID
       */
      parentTokenId?: string;
      /**
       * The UID of the RFID card
       */
      tokenId: string;
      tokenUuid?: Uuid;
    };
    /**
     * Battery level at the start and end of the session
     */
    stateOfCharge?: {
      /**
       * Battery level at the end of the session, or the most recent value if the session has not ended
       */
      finalBatteryLevel?: number;
      /**
       * Battery level at the start of the session
       */
      initialBatteryLevel: number;
    };
    /**
     * Date session started
     */
    startDate: string;
    sessionStatus: SessionStatus;
    /**
     * EVSE ID used for the session
     */
    evseId: string;
    /**
     * Energy delivered for the session
     */
    energyUsage?: {
      total: WattHours;
      distributionByTariff?: PercentageByTariff;
    };
    /**
     * Date session ended
     */
    endDate?: string;
    electricityCost?: ElectricityCost;
    /**
     * Date session was created
     */
    createdDate: string;
    /**
     * Energy cost for the session by tariff
     */
    cost?: {
      total: CurrencyAmount;
      distributionByTariff?: PercentageByTariff;
    };
    /**
     * Connector ID used for the session
     */
    connectorId: string;
    /**
     * When the charger stopped supplying power during the session
     */
    chargingStopped?: string;
    /**
     * When the charger started supplying power during the session
     */
    chargingStarted?: string;
    chargingConfiguration?: ChargingConfiguration;
    /**
     * Reference ID snapshot at the time of the session
     */
    chargePointReferenceId?: string;
    /**
     * Session authorization reference
     */
    authorizationReference?: string;
    authorizationMethod?: AuthorizationMethod;
  };
};

export type Session = SessionSummary & {
  relationships: {
    /**
     * The organisation for the session
     */
    organisation: {
      data: OrganisationIdentifier | null;
    };
    /**
     * The location for the session
     */
    location: {
      data: LocationIdentifier | null;
    };
    /**
     * The charge point for the session
     */
    chargePoint: {
      data: ChargePointIdentifier;
    };
  };
};

export interface ResponseListChargePointSessions {
  data: Session[];
}

export type CommandResponseStatus = 'Accepted' | 'Rejected';

export interface ResponseCommand {
  data: {
    status: CommandResponseStatus;
  };
}

export type ChangeAvailabilityType = 'Inoperative' | 'Operative';

export interface RequestCommandChangeAvailability {
  changeAvailabilityType: ChangeAvailabilityType;
  connectorId: number;
}

export type ConfigurationStatus = 'Accepted' | 'Rejected' | 'RebootRequired' | 'NotSupported';

export interface ResponseCommandChangeConfiguration {
  data: {
    status: ConfigurationStatus;
  };
}

export interface RequestCommandChangeConfiguration {
  key: string;
  value: string;
}

export interface ResponseCommandDataTransfer {
  data: {
    status: CommandResponseStatus;
    message?: string;
  };
}

export interface RequestCommandDataTransfer {
  /**
   * Data without specified length or format.
   */
  data?: string;
  /**
   * Additional identification field
   */
  messageId?: string;
  /**
   * Identifies the Vendor specific implementation
   */
  vendorId: string;
}

export interface ResponseCommandGetConfiguration {
  data: {
    items: {
      /**
       * Whether the value can be configured.
       */
      readonly: boolean;
      /**
       * The value of the configuration setting
       */
      value?: string;
      /**
       * The name of the configuration setting
       */
      key: string;
    }[];
  };
}

export interface RequestCommandGetConfiguration {
  keys: string[];
}

export interface RequestCommandGetDiagnostics {
  /**
   * Contains the location (directory) where the diagnostics file shall be uploaded to.
   */
  location: string;
}

export interface ResponseCommandGetOverride {
  chargeNow: boolean | 'NotSupported';
}

export type SinglePhase = 1;

export type ThreePhase = 3;

export interface Phases {
  /**
   * Will be 1 on E2, E7, X7 or X22 wired to one phase. 3 on X22 wired to three phases.
   *
   * Not supported if charger firmware < 2.4.4
   */
  numChargingPhases: SinglePhase | ThreePhase | 'NotSupported';
}

export type ResponseCommandGetSolar = Phases & {
  /**
   * The solar target in Watts.
   *
   * Positive is import, negative is export.  The default value is zero.
   *
   * Not supported if charger firmware < 2.4.4
   */
  solarControlTargetPower: number | 'NotSupported';
  /**
   * Power that must be exported in order to prevent the house battery from discharging, in watts
   *
   * Not supported if charger firmware is < v2.4.1
   */
  solarControlTargetOffset: number | 'NotSupported';
  /**
   * Power that must be imported to the house before charging is allowed to stop, in watts
   */
  solarStopImportPower: number;
  /**
   * Power that must be exported from the house before charging is allowed to start, in watts
   */
  solarStartExportPower: number;
  /**
   * Whether the charger has a power sensor installed
   */
  powerSensorInstalled: boolean;
  /**
   * Whether the charger is configured for solar.
   *
   * Not supported if charger firmware >= v2.5.1
   */
  solarWithSchedule: boolean | 'NotSupported';
};

export type ResetType = 'Hard' | 'Soft';

export interface RequestCommandReset {
  resetType: ResetType;
}

export interface RequestCommandSetOverride {
  connectorId: number;
  chargeNow: boolean;
}

export interface ResponseCommandSetSolar {
  data: {
    status: ConfigurationStatus | CommandResponseStatus;
  };
}

export interface RequestCommandSetSolar {
  /**
   * Whether the charger is configured for solar
   */
  solarWithSchedule: boolean;
  /**
   * Power that must be exported from the house before charging is allowed to start, in watts
   */
  solarStartExportPower?: number;
  /**
   * Power that must be imported to the house before charging is allowed to stop, in watts
   */
  solarStopImportPower?: number;
  /**
   * Power that must be exported in order to prevent the house battery from discharging, in watts
   */
  solarControlTargetOffset?: number;
  /**
   * The solar target in Watts. Positive is import, negative is export.
   */
  solarControlTargetPower?: number;
}

export interface RequestCommandSetAntiSleep {
  enabled: boolean;
}

export interface RequestCommandRemoteStart {
  connectorId: number;
  tokenUid: string;
}

export interface RequestCommandRemoteStop {
  connectorId: number;
}

export interface RequestCommandUnlockConnector {
  connectorId: number;
}

export interface RequestCommandUpdateFirmware {
  /**
   * URI point to a location from which to retrieve the firmware
   */
  location: string;
  /**
   * How many times for the charge point to retry the download
   */
  retries?: number;
  /**
   * Date and time after which the charge point is allowed to retrieve the new firmware
   */
  retrieveDate?: string;
  /**
   * Seconds after which a retry may be attempted
   */
  retryInterval?: number;
}

/**
 * Specifies the purpose of the charging profiles that will be set or cleared
 */
export type ChargingProfilePurposeType = 'ChargePointMaxProfile' | 'TxDefaultProfile' | 'TxProfile';

export interface RequestCommandClearChargingProfile {
  chargingProfilePurpose?: ChargingProfilePurposeType;
  /**
   * ID of charging profile to clear
   */
  id?: number;
  /**
   * ID of the connector for which to clear charging profiles. A connectorId of zero (0)
   * specifies the charging profile for the overall charge point. Absence of this parameter means the
   * clear applies to all charging profiles that match the other criteria in the request
   */
  connectorId?: number;
  /**
   * Specifies the stack level for which charging profiles will be cleared
   */
  stackLevel?: number;
}

/**
 * Type of action to be sent to the charge point
 */
export type SudoCommandType =
  | 'CancelReservation'
  | 'ChangeAvailability'
  | 'ChangeConfiguration'
  | 'ClearCache'
  | 'ClearChargingProfile'
  | 'DataTransfer'
  | 'DeleteCertificate'
  | 'GetCompositeSchedule'
  | 'GetConfiguration'
  | 'GetDiagnostics'
  | 'GetInstalledCertificateIds'
  | 'GetLocalListVersion'
  | 'GetLog'
  | 'InstallCertificate'
  | 'RemoteStartTransaction'
  | 'RemoteStopTransaction'
  | 'ReserveNow'
  | 'Reset'
  | 'SendLocalList'
  | 'SetChargingProfile'
  | 'TriggerMessage'
  | 'UnlockConnector'
  | 'UpdateFirmware';

export interface AnyObject {
  [key: string]: {};
}

export interface RequestCommandSudo {
  action: SudoCommandType;
  message?: AnyObject;
}

/**
 * Type of upload that the charge point should perform
 */
export type UploadDiagnosticsProtocol = 'http' | 'ftp';

export interface RequestCommandUploadDiagnostics {
  protocol: UploadDiagnosticsProtocol;
}

export type ChargingLogic = 'Unavailable' | 'NoVehicle' | 'Vehicle' | 'Transfer' | 'Fault';

export type ChargingCurrentControl =
  | 'FullPower'
  | 'ThermalLimited'
  | 'LLMLimited'
  | 'WaitingSchedule'
  | 'WaitingSolar'
  | 'SolarControl'
  | 'ScheduleLimited'
  | 'SupplyLimited';

export type E2LEDState =
  | 'Off'
  | 'Idle'
  | 'Charging'
  | 'ChargeNowCharging'
  | 'ChargeNowNotCharging'
  | 'Fault'
  | 'Disabled'
  | 'WaitSchedule'
  | 'WaitSolar'
  | 'WaitVehicle'
  | 'ShuttingDown';

export type AntiSleepState = 'Disabled' | 'Enabled' | 'Active' | 'NA';

export interface ResponseCommandGetStatus {
  data: {
    commandResultStatus: string;
    chargePointStatus?: {
      AntiSleep?: AntiSleepState;
      LEDState?: E2LEDState;
      chargingCurrentControl: ChargingCurrentControl;
      chargingLogic: ChargingLogic;
      chargeNow: boolean;
    };
  };
}

/**
 * Output from a current transducer attached to a charge point
 */
export interface CTReading {
  /**
   * Timestamp when the reading was taken
   */
  timestamp: string;
  /**
   * Measurements on the first phase
   */
  L1: {
    /**
     * Measured in watts. A negative number means the building is providing power to the grid; a positive number means it is consuming power from the grid.
     */
    activePower: number;
  };
}

export type ConfigurationStatusNotSupported = 'NotSupported';

export type ResponseCommandGetCTStatus =
  | CTReading
  | {
      status: ConfigurationStatusNotSupported;
    };

export type ICPIdentifier = string;

export interface InstallationControlPointIdentifier {
  id: ICPIdentifier;
  type: 'installationControlPoints';
}

export type InstallationControlPoint = InstallationControlPointIdentifier & {
  relationships?: {};
  attributes: {
    address: string;
  };
};

export interface ResponseListInstallationControlPoints {
  data: InstallationControlPoint[];
}

/**
 * Must contain at least 1 digit
 */
export type StreetNumberQuery = string;

/**
 * Must be at least 3 non-whitespace characters long
 */
export type StreetNameQuery = string;

export interface LoadBalancingGroupIdentifier {
  id: Uuid;
  type: 'loadBalancingGroups';
}

export interface RequestUpdateLoadBalancingGroup {
  data: LoadBalancingGroupIdentifier & {
    relationships: {
      chargePoints: {
        data: {
          id: Uuid;
          type: 'chargePoints';
        }[];
      };
    };
    attributes: {
      phases: {
        maximumCurrent: number;
      }[];
      enabled: boolean;
      name: string;
    };
  };
}

export type Location = LocationSummary & {
  relationships: {
    /**
     * Users who have been granted access to this location
     */
    users: {
      data: UserIdentifier[];
    };
    /**
     * Organisation that owns the location
     */
    organisation: {
      data: OrganisationIdentifier | null;
    };
    /**
     * Charge points at this location
     */
    chargePoints: {
      data: ChargePointIdentifier[];
    };
  };
};

export interface ResponseListLocations {
  data: Location[];
  included: OrganisationSummary[];
}

export interface ResponseGetLocation {
  data: Location;
  included: ChargePointSummary[];
}

export interface ResponseListLocationUsers {
  data: UserSummary[];
}

export interface ResponseUpdateLocation {
  data: LocationSummary;
}

export interface RequestUpdateLocation {
  data: {
    attributes: {
      timeZone?: string;
      name?: LocationName;
      isPublic?: boolean;
      icpNumber?: string;
      coordinates?: {
        /**
         * Degrees east (positive) or west (negative) of the prime meridian
         */
        longitude: string;
        /**
         * Degrees north (positive) or south (negative) of the equator
         */
        latitude: string;
      };
      address?: {
        /**
         * State or province
         */
        state?: string;
        /**
         * Postal code
         */
        postCode?: string;
        /**
         * ISO-31661 alpha-2 country code
         */
        country: string;
        /**
         * City or town
         */
        city: string;
        /**
         * Address line 3
         */
        address3?: string;
        /**
         * Address line 2
         */
        address2?: string;
        /**
         * Address line 1
         */
        address1: string;
      };
    };
    type: 'locations';
    id: Uuid;
  };
}

export interface RequestModifyLocationOrg {
  data: OrganisationIdentifier;
}

/**
 * If the state of the object on the server exactly matches the request, the `data` attribute must be omitted from the response.
 */
export interface ResponseBase {
  meta?: {};
}

export interface RequestModifyLocationUsers {
  data: UserIdentifier[];
}

export interface ResponseStepUp {
  hello: 'world';
}

export interface RequestMFAChallenge {
  code: string;
}

export type Organisation = OrganisationSummary & {
  relationships: {
    /**
     * Members of this organisation
     */
    memberships: {
      data: MembershipIdentifier[];
    };
    /**
     * Locations owned by this organisation
     */
    locations: {
      data: LocationIdentifier[];
    };
    /**
     * Charge points owned by this organisation
     */
    chargePoints: {
      data: ChargePointIdentifier[];
    };
  };
};

export interface ResponseListOrganisations {
  data: Organisation[];
}

export interface ResponseGetOrganisation {
  data: OrganisationSummary;
}

export interface ResponseUpdateOrganisation {
  data: OrganisationSummary;
}

export interface RequestUpdateOrganisation {
  data: {
    attributes: {
      apiPermissions: EnterpriseApiPermissions;
    };
    type: 'organisations';
    id: Uuid;
  };
}

export interface MeterValuesAC1Phase {
  frequency?: number;
  power?: number;
  powerType: string;
  register?: number;
  updatedDate: string;
  current?: number;
  voltage?: number;
}

export interface MeterValuesAC3Phase {
  frequency?: number;
  power?: number;
  powerType: string;
  register?: number;
  updatedDate: string;
  current?: {
    _3?: number;
    _2?: number;
    _1?: number;
  };
  voltage?: {
    _3?: number;
    _2?: number;
    _1?: number;
  };
}

export interface MeterValuesDC {
  frequency?: number;
  power?: number;
  powerType: string;
  register?: number;
  updatedDate: string;
  current?: number;
  stateOfCharge?: number;
  temperature?: number;
  voltage?: number;
}

/**
 * Address for a location
 */
export interface LegacyAddressDTO {
  /**
   * Address line 1
   */
  address1: string;
  /**
   * Address line 2
   */
  address2?: string;
  /**
   * Address line 3
   */
  address3?: string;
  /**
   * City or town
   */
  city: string;
  /**
   * Country
   */
  country: string;
  /**
   * Postal code
   */
  postCode?: string;
  /**
   * State or province
   */
  state?: string;
}

/**
 * Electricity unit type
 */
export type ElectricityUnit = 'A' | 'W';

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickLocationDTOAddressOrChargePointCountOrCoordinatesOrCreatedDateOrIdOrNameOrSettingsOrUpdatedDate {
  address: LegacyAddressDTO;
  /**
   * Number of charge points at this location
   */
  chargePointCount: number;
  /**
   * GPS coordinates
   */
  coordinates: {
    longitude: string;
    latitude: string;
  };
  /**
   * Date the location was created
   */
  createdDate: string;
  id: Uuid;
  /**
   * Location's display name
   */
  name: string;
  /**
   * Settings for a location
   */
  settings?: {
    /**
     * Load balancing profile for a location
     */
    loadBalancing?: {
      /**
       * Date the profile was last updated
       */
      updatedDate: string;
      /**
       * Maximum load amount in amps (A)
       */
      limit: number;
      /**
       * Whether the load balancing profile is enabled
       */
      enabled: boolean;
      /**
       * Date the profile was created
       */
      createdDate: string;
      units: ElectricityUnit;
    };
  };
  /**
   * Date the location was last updated
   */
  updatedDate: string;
}

export interface ChargePointInfoWithLocation {
  connectors: {
    voltage: number;
    updatedDate: string;
    powerType: PowerType;
    ocppStatus: OcppStatus;
    ocppCode?: string;
    meter?: MeterValuesAC1Phase | MeterValuesAC3Phase | MeterValuesDC;
    evseId: string;
    connectorType: ConnectorType;
    connectorId: string;
    connectorFormat: ConnectorFormat;
    amperage: number;
  }[];
  createdDate: string;
  maxCurrent: number;
  tokenRequired: boolean;
  defaultToken?: string;
  needsRegistrationInformation: boolean;
  details: {
    vendor?: string;
    model?: string;
    iccid?: string;
    firmware?: string;
  };
  id: string;
  ocppChargePointId?: string;
  lastHeard?: string;
  metadata?: {
    referenceId?: string;
  };
  name: string;
  networkStatus: NetworkStatus;
  serial?: string;
  networkStatusUpdatedDate: string;
  updatedDate: string;
  location: PickLocationDTOAddressOrChargePointCountOrCoordinatesOrCreatedDateOrIdOrNameOrSettingsOrUpdatedDate;
}

export interface ResponseGetOrganisationChargePoints {
  data: {
    items: ChargePointInfoWithLocation[];
  };
}

export interface ResponseGetOrganisationLocations {
  data: Location[];
  included: ChargePointSummary[];
}

export interface ResponseCreateLocation {
  data: Location;
}

export interface RequestCreateOrganisationLocation {
  data: {
    attributes: {
      icpNumber?: string;
      name: LocationName;
      isPublic: boolean;
      coordinates: {
        /**
         * Degrees east (positive) or west (negative) of the prime meridian
         */
        longitude: string;
        /**
         * Degrees north (positive) or south (negative) of the equator
         */
        latitude: string;
      };
      address: {
        /**
         * State or province
         */
        state?: string;
        /**
         * Postal code
         */
        postCode?: string;
        /**
         * ISO-31661 alpha-2 country code
         */
        country: string;
        /**
         * City or town
         */
        city: string;
        /**
         * Address line 3
         */
        address3?: string;
        /**
         * Address line 2
         */
        address2?: string;
        /**
         * Address line 1
         */
        address1: string;
      };
    } & {
      /**
       * Name of the timezone, as specified in the IANA Timezone Database
       */
      timeZone?: string;
    };
    type: 'locations';
  };
}

/**
 *
 * Organisation API client credentials
 */
export interface ClientCredentials {
  /**
   * Client ID
   */
  id: string;
  /**
   * Client Secret
   */
  secret: string;
}

export interface ResponseGetOrganisationClientCredentials {
  data: ClientCredentials;
}

export interface LegacyInsightsSummary {
  /**
   * Total carbon offset
   */
  carbonOffset: number;
  /**
   * Total cost of sessions
   */
  costs: {
    currency: Currency;
    cost: number;
  }[];
  /**
   * Total charging time
   */
  duration: number;
  /**
   * Total power usage
   */
  powerUsage: number;
  /**
   * Total number of charging sessions
   */
  sessions: number;
  /**
   * Start date of summary
   */
  startDate: string;
  /**
   * Total CO2 produced
   */
  totalCarbonUsage?: number;
}

export interface ResponseGetOrganisationSummaryInsightsLegacy {
  data: {
    items: LegacyInsightsSummary[];
  };
}

/**
 * Number of days in the past
 */
export type Days = 7 | 14 | 30;

/**
 * Summary of current charge point statuses
 */
export interface StatusSummary {
  /**
   * Number of charge points with status "AVAILABLE"
   */
  available: number;
  /**
   * Number of charge points with status "CHARGING"
   */
  charging: number;
  /**
   * Number of charge points with status "DISABLED"
   */
  disabled: number;
  /**
   * Number of charge points with status "FAULTED"
   */
  faulted: number;
  /**
   * Number of charge points with status "OCCUPIED"
   */
  occupied: number;
  /**
   * Number of charge points with status "OFFLINE"
   */
  offline: number;
  /**
   * Number of charge points with status "RESERVED"
   */
  reserved: number;
}

export interface ResponseGetOrganisationSummaryStatus {
  data: StatusSummary;
}

/**
 * Type of token
 */
export type TokenType = 'RFID' | 'APP_USER' | 'AD_HOC_USER' | 'OTHER';

/**
 * A complete token object
 */
export interface Token {
  id: Uuid;
  tokenType: TokenType;
  /**
   * Date the token was created
   */
  createdDate: string;
  /**
   * Whether the token is enabled or disabled
   */
  isValid: boolean;
  /**
   * Token's display name
   */
  name?: string;
  /**
   * Unique ID by which this token can be identified. This is the field used by the RFID reader on the charge point.
   */
  uid: string;
  /**
   * Date the token was last updated
   */
  updatedDate: string;
}

export interface ResponseGetOrganisationTokens {
  data: {
    items: Token[];
  };
}

export interface ResponsePostToken {
  data: Token;
}

export interface RequestCreateToken {
  tokenType: TokenType;
  /**
   * Whether the token is enabled or disabled
   */
  isValid: boolean;
  /**
   * Token's display name
   */
  name?: string;
  /**
   * Unique ID by which this token can be identified. This is the field used by the RFID reader on the charge point.
   */
  uid: string;
}

export interface ResponseCreateOrganisation {
  data: Organisation;
}

export interface RequestCreateOrganisation {
  data: {
    attributes: {
      tier?: Tier;
      slug?: Slug;
      namespacePrefix?: NamespacePrefix;
      name: OrganisationName;
      emspId?: EmspId;
      color?: Color;
      avatar?: Uri;
    };
    type: 'organisations';
  };
}

export interface ResponsePostInvitation {
  data: ScopedUser;
  included: MembershipSummary[];
}

export interface RequestInviteDriver {
  data: {
    attributes: {
      suppressInvitationEmail?: boolean;
      name: Name;
      email: string;
      color?: Color;
      avatar?: Uri;
    };
    type: 'users';
  };
}

export interface ResponseListMemberships {
  data: Membership[];
  included: UserSummary[];
}

export interface ResponseGetMembership {
  data: Membership;
  included: UserSummary[];
}

export interface ResponseMembership {
  data: Membership;
}

export interface RequestCreateMembership {
  data: {
    relationships: {
      organisation: {
        data: OrganisationIdentifier;
      };
      user: {
        data: UserIdentifier;
      };
    };
    attributes: {
      role: Role;
      isDefault: boolean;
    };
    type: 'memberships';
  };
}

export interface RequestUpdateMembership {
  data: {
    attributes: {
      role?: Role;
      isDefault?: boolean;
    };
    type: 'memberships';
    id: MembershipID;
  };
}

export interface RequestDeleteMemberships {
  data: MembershipIdentifier[];
}

export interface UsageByTariff {
  total: number;
  distributionByImportTariffs?: PercentageByTariff;
  distributionByExportTariffs?: PercentageByTariff;
}

export interface CostByCurrency {
  [key: string]: UsageByTariff;
}

export interface InsightsSummary {
  attributes: {
    /**
     * Start date of summary
     */
    startDate: string;
    /**
     * Total number of charging sessions
     */
    sessions: number;
    /**
     * Total power usage
     */
    powerUsage: number;
    /**
     * Total charging time
     */
    duration: number;
    cost: CostByCurrency;
    /**
     * Total CO2 produced
     */
    carbonUsage?: number;
    /**
     * Total carbon offset
     */
    carbonOffset: number;
  };
}

export interface ResponseGetOrganisationSummaryInsights {
  data: InsightsSummary[];
}

export interface ResponseListOrganisationSessions {
  data: Session[];
  included: (OrganisationSummary | LocationSummary | ChargePointSummary)[];
}

export interface ResponseListOrganisationChargePoints {
  data: ChargePoint[];
  included: LocationSummary[];
}

export interface ResponseCreateThirdPartyChargePoint {
  data: ChargePoint;
  included: (LocationSummary | OrganisationSummary)[];
}

export interface RequestCreateThirdPartyChargePoint {
  data: {
    relationships: {
      location: {
        data: {
          id: Uuid;
          type: 'locations';
        };
      };
    };
    attributes: {
      tokenRequired: boolean;
      referenceId?: string;
      ocppChargePointId: string;
      name: string;
      /**
       * Connectors on the charge point
       */
      connectors: {
        powerType: PowerType;
        maxVoltage: number;
        maxAmperage: number;
        connectorFormat: ConnectorFormat;
        connectorType: ConnectorType;
      }[];
    };
    type: 'chargePoints';
  };
}

export type LoadBalancingGroupSummary = LoadBalancingGroupIdentifier & {
  attributes: {
    /**
     * NB: Must be individually parsed into Charge Point objects.
     */
    chargePointJSONs?: string[];
    phases: {
      maximumCurrent: number;
    }[];
    enabled: boolean;
    name: string;
  };
};

export type LoadBalancingGroup = LoadBalancingGroupSummary & {
  relationships: {
    organisation: {
      data: OrganisationIdentifier | null;
    };
    chargePoints: {
      data: ChargePointIdentifier[];
    };
  };
};

export interface ResponseCreateOrganisationLoadBalancingGroup {
  data: LoadBalancingGroup;
}

export interface RequestCreateLoadBalancingGroup {
  data: {
    relationships: {
      chargePoints: {
        data: {
          id: Uuid;
          type: 'chargePoints';
        }[];
      };
      organisation: {
        data: {
          id: Uuid;
          type: 'organisations';
        };
      };
    };
    attributes: {
      phases: {
        maximumCurrent: number;
      }[];
      enabled: boolean;
      name: string;
    };
    type: 'loadBalancingGroups';
  };
}

export interface ResponseListOrganisationLoadBalancingGroups {
  data: LoadBalancingGroupSummary[];
}

/**
 * id is ChargePointId
 */
export interface DriverClaimSummary {
  id: Uuid;
  type: 'driverClaims';
  attributes: {
    /**
     * ISO-31661 alpha-2 country code
     */
    country: string;
  };
}

export interface ResponseCommandAuthorizeDriverClaim {
  data: DriverClaimSummary;
}

export interface RequestCommandDriverClaimBySerial {
  /**
   * Serial number of the Evnex charge point to claim
   */
  chargePointSerial: string;
  /**
   * ICP or NMI number of Evnex charge point location
   */
  icpNumber?: string;
}

/**
 * Aggregated totals over a set of charging sessions
 */
export interface SessionsReport {
  id: Uuid;
  type: 'sessionsReport';
  attributes: {
    totalEnergyUsage: {
      /**
       * Total energy delivered (watt-hours)
       */
      total: number;
      /**
       * Detailed breakdown of the contribution to a session per mutually exclusive electricity production category, adding up to 100%
       */
      distributionByEnergySource?: PercentageByEnergySource & {
        /**
         * Proportion of energy from on-site solar diversion
         */
        localSolar?: number;
      };
      distributionByTariff?: LegacyPercentageByTariff;
    };
    totalDuration: number;
    totalCost: {
      distribution?: LegacyPercentageByTariff;
      currency: Currency;
      amount: CurrencyAmount;
    }[];
    totalCarbonUsage?: number;
    totalCarbonOffset: number;
    /**
     * Latest session updatedDate in the data set
     */
    toDate: string;
    /**
     * Earliest session updatedDate in the data set
     */
    fromDate: string;
    energyUsage: UsageByTariff;
    duration: number;
    /**
     * Number of sessions in the data set
     */
    count: number;
    cost: CostByCurrency;
    /**
     * Total CO2 produced in grams
     */
    carbonUsage?: number;
    /**
     * Estimated carbon offset in kilograms (kg)
     */
    carbonOffset: number;
  };
}

export interface ResponseReportSessions {
  data: SessionsReport;
}

/**
 * Locale abbreviation and ISO timestamp, separated by _
 */
export type ElectricityGridIdentifier = string;

export type SourceLocale = 'AU-NSW' | 'AU-QLD' | 'AU-SA' | 'AU-TAS' | 'AU-VIC' | 'AU-WA' | 'AU-NT' | 'NZ';

export interface CarbonIntensityAttributes {
  percentRenewable: Percent;
  /**
   * Carbon emission rate of this trading period, in g/kWh
   */
  emissionCurrent: number;
  /**
   * How much the carbon emission rate of the grid changed since the previous trading period, in g/kWh
   */
  emissionDelta?: number;
  /**
   * Maximum carbon emission rate over the last 12 months, in g/kWh
   */
  emissionMax12Mth?: number;
  /**
   * Maximum carbon emission rate over the last day, in g/kWh
   */
  emissionMax24Hr: number;
  /**
   * Minimum carbon emission rate over the last day, in g/kWh
   */
  emissionMin24Hr: number;
  /**
   * Mean carbon emission rate over the current calendar month, in g/kWh
   */
  emissionMonthAvg?: number;
  /**
   * Mean carbon emission rate over the current calendar year, in g/kWh
   */
  emissionYearAvg?: number;
  locale: SourceLocale;
  /**
   * Total carbon produced during this trading period, in metric tonnes
   */
  totalCarbon?: number;
}

/**
 * Carbon intensity information of the electricity grid at the given locale and time (NB: 'Renewable' energy sources are geothermal, hydro, solar, and wind)
 */
export interface CarbonIntensityReport {
  id: ElectricityGridIdentifier;
  type: 'carbonIntensityReport';
  attributes: CarbonIntensityAttributes;
}

export interface ResponseCarbonIntensity {
  data: CarbonIntensityReport;
}

/**
 * Country or region for which to request electricity grid data (case-insensitive)
 */
export type ElectricityGridLocale = string;

export interface GenerationMixAttributes {
  'AU-NSW'?: number;
  'AU-NT'?: number;
  'AU-QLD'?: number;
  'AU-SA'?: number;
  'AU-TAS'?: number;
  'AU-VIC'?: number;
  'AU-WA'?: number;
  /**
   * Proportion of energy discharged from battery energy storage systems (BESS)
   */
  batteryStorage?: number;
  /**
   * Proportion of energy from burning organic by-products
   */
  biomass?: number;
  /**
   * Proportion of energy from burning black or brown coal
   */
  coal?: number;
  /**
   * Proportion of energy from recycled gas in combined heat and power (CHP) systems
   */
  cogeneration?: number;
  /**
   * Proportion of energy from burning natural gas
   */
  gas?: number;
  /**
   * Proportion of energy from geothermal power stations
   */
  geothermal?: number;
  /**
   * Proportion of energy from hydroelectric stations
   */
  hydro?: number;
  /**
   * Proportion of energy discharged from pumped water storage systems
   */
  hydroStorage?: number;
  /**
   * Proportion of energy from nuclear fission reactors
   */
  nuclear?: number;
  /**
   * Proportion of energy from burning petroleum diesel, AKA distillate
   */
  oil?: number;
  /**
   * Proportion of energy from solar farms
   */
  solar?: number;
  /**
   * Proportion of energy from wind farms
   */
  wind?: number;
  locale: SourceLocale;
  /**
   * All grid power being generated at this locale and timestamp, in megawatts
   */
  totalPowerGeneration: number;
}

export interface GenerationMixReport {
  id: ElectricityGridIdentifier;
  type: 'gridMixReport';
  attributes: GenerationMixAttributes;
}

export interface ResponseGridGenerationMix {
  data: GenerationMixReport;
}

export interface CarbonIntensity {
  id: ElectricityGridIdentifier;
  type: 'carbonIntensity';
  attributes: CarbonIntensityAttributes & {
    /**
     * Date when the carbon intensity was recorded
     */
    timestamp: string;
  };
}

export interface ResponseCarbonIntensityRange {
  data: CarbonIntensity[];
}

export interface GenerationMix {
  id: ElectricityGridIdentifier;
  type: 'gridMix';
  attributes: GenerationMixAttributes & {
    /**
     * Date when the grid mix data was recorded
     */
    timestamp: string;
  };
}

export interface ResponseGridGenerationMixRange {
  data: GenerationMix[];
}

export interface ResponsePatchToken {
  data: Token;
}

export interface RequestUpdateToken {
  tokenType?: TokenType;
  /**
   * Whether the token is enabled or disabled
   */
  isValid?: boolean;
  /**
   * Token's display name
   */
  name?: string;
}

/**
 * A user's membership within an organisation
 */
export interface UserOrganisation {
  avatar?: Uri;
  color?: Color;
  createdDate: string;
  emspId?: string;
  id: Uuid;
  name: string;
  namespacePrefix: string;
  slug: Slug;
  tier: Tier;
  tierDetails: TierDetails;
  updatedDate: string;
  role: Role;
  /**
   * Whether this organisation is the user's default selected organisation
   */
  isDefault: boolean;
}

/**
 * A complete user object
 */
export interface LegacyUser {
  avatar?: Uri;
  color?: Color;
  id: Uuid;
  /**
   * Date the user was created
   */
  createdDate: string;
  /**
   * User's email address
   */
  email: string;
  /**
   * User's display name
   */
  name?: string;
  /**
   * List of organisations that the user belongs to
   */
  organisations: UserOrganisation[];
  /**
   * Date the user was last updated
   */
  updatedDate: string;
  type: UserType;
}

export interface ResponseGetLegacyAuthenticatedUser {
  data: LegacyUser;
}

/**
 * User ID and Integration ID separated by an underscore, uniquely identifying a user's integration.
 */
export type IntegrationId = string;

export interface IntegrationIdentifier {
  type: 'integrations';
  id: IntegrationId;
}

export type IntegrationProvider = 'tesla';

export type VehicleManufacturer = 'tesla';

/**
 * A unique 17-character number used to identify vehicles.
 */
export type VehicleIdentificationNumber = string;

export type IntegrationSummary = IntegrationIdentifier & {
  attributes: {
    vehicles: {
      vin: VehicleIdentificationNumber;
      name: string;
      manufacturer: VehicleManufacturer;
    }[];
    provider: IntegrationProvider;
  };
};

export interface ResponseAddIntegration {
  data: IntegrationSummary;
}

export interface RequestAddIntegration {
  data: {
    attributes: {
      redirectUri: string;
      provider: IntegrationProvider;
      authorizationCode: string;
    };
    type: 'integrations';
  };
}

export interface RequestAddVehicle {
  data: {
    attributes: {
      vin: VehicleIdentificationNumber;
      name: string;
      manufacturer: VehicleManufacturer;
    };
    type: 'vehicles';
  };
}

export interface VehicleIdentifier {
  type: 'vehicles';
  id: VehicleIdentificationNumber;
}

export type VehicleSummary = VehicleIdentifier & {
  attributes: {
    batteryLevel?: number;
    name: string;
    manufacturer: VehicleManufacturer;
  };
};

export interface ResponseListVehicles {
  data: VehicleSummary[];
}

export interface ResponseCreateUser {
  data: ScopedUser;
}

export interface ResponseListUsers {
  data: UserSummary[];
}

export interface ResponseGetUser {
  data: User;
  included: (MembershipSummary | OrganisationSummary)[];
}

export interface ResponseListUserLocations {
  data: Location[];
  included: OrganisationSummary[];
}

export interface ResponseUpdateUser {
  data: UserSummary;
}

export interface RequestUpdateUser {
  data: {
    attributes: {
      notificationPreferences?: {
        topics?: {
          chargingStarted: boolean;
          chargingInterrupted: boolean;
          chargingComplete: boolean;
        };
        enabled: boolean;
      };
      name?: Name;
      email?: string;
      color?: Color;
      avatar?: Uri;
    };
    type: 'users';
    id: Uuid;
  };
}

export interface OldResponseListChargePoints {
  data: ChargePoint[];
  included: (OrganisationSummary | LocationSummary)[];
}

export interface OldResponseGetChargePoint {
  data: ChargePoint;
  included: LocationSummary[];
}

export interface OldResponseUpdateChargePoint {
  data: ChargePointSummary;
}

export interface OldResponseListChargePointSessions {
  data: Session[];
}

export interface RequestModifyAssignedInstallers {
  data: UserIdentifier[];
}

export interface AttachmentIdentifier {
  id: Uri;
  type: 'attachments';
}

export type AttachmentSummary = AttachmentIdentifier & {
  attributes: {
    /**
     * Name and extension of file being uploaded
     */
    fileName: string;
  };
};

export interface ResponseAttachments {
  data: AttachmentSummary[];
}

export interface RequestAddAttachments {
  data: {
    attributes: {
      fileName: string;
    };
    type: 'attachments';
  }[];
}

export interface ResponseGetJobChargePoint {
  data: ChargePointSummary | null;
}

export interface RequestCommandCommissionChargePoint {
  chargePointId: Uuid;
  /**
   * The maximum current that the charger should allow when there are no
   * other constraints, in amperes
   */
  chargerCurrentLimit?: number;
}

export interface RequestConfirmCTStatus {
  date: string;
}

export type JobCustomerAccountStatus = 1 | 2 | 3 | 4;

export type JobCustomer = UserSummary & {
  attributes: {
    accountStatus: JobCustomerAccountStatus;
  };
};

export interface ResponseGetJobCustomer {
  data: JobCustomer;
}

export interface ResponseUpdateJobCustomer {
  data: UserSummary;
}

export interface RequestUpdateJobCustomer {
  data: {
    attributes: {
      name?: Name;
    };
    type: 'users';
    id: Uuid;
  };
}

/**
 * Duration of the job, in hours
 */
export type Duration = number;

/**
 * Type of job
 */
export type JobType = 0;

/**
 * ID of the job for lookup in enterprise resource management system
 */
export type ReferenceId = string;

/**
 * Status of job
 */
export type JobStatus = 0 | 1 | 2 | 3 | 4 | 5;

export type CommissionAttemptResult = 0 | 1 | 2 | 3 | 4 | 5;

export type Metres = number;

export type MillimetresSquared = number;

/**
 * Type of phase wiring for L1WiredToPhase. Can be L1(0), L2(1) or L3(2).
 */
export type PhaseWiring = 0 | 1 | 2;

export interface DistributionBoard {
  /**
   * Maximum total combined current to be drawn by the chargers connected to this board
   */
  supplyCurrentLimit?: number;
  /**
   * Describes whether there are one or three CTs wired to the power sensor on the charger. This determines if the
   * charger calculates net power consumption from one or three phases.
   *
   * Only valid for EX series chargers on >= 2.5.0 firmware
   */
  powerSensorCTOnThreePhases?: boolean;
  /**
   * Number of power sensors to be connected to this distribution board
   */
  powerSensorCount: number;
  l1WiredToPhase?: PhaseWiring;
  /**
   * Number of chargers to be connected to this distribution board
   */
  chargePointCount: number;
}

export type JobSummary = JobIdentifier & {
  attributes: {
    /**
     * Timestamp when job was last updated
     */
    updated: string;
    /**
     * Determines maximum charging current limit for charge points in Queensland, Australia.
     * Only applies to E2 series.
     */
    queenslandActiveDeviceManagement?: boolean;
    /**
     * Name of the organisation that the job is associated with
     */
    organisationName: string;
    /**
     * Free text field for any additional notes about this job
     */
    notes?: string;
    /**
     * Whether the job was created by a third-party installer
     */
    isThirdParty: boolean;
    /**
     * Whether the job will be a hardware-only installation (that is, not initially associated with a customer)
     */
    isHardwareOnly: boolean;
    /**
     * Site distribution boards
     */
    distributionBoards: DistributionBoard[];
    /**
     * Structured data associated with the job; format subject to change.
     */
    details: {
      cableConductorSize?: MillimetresSquared;
      totalCableRun?: Metres;
      /**
       * Warnings that may occur before the final commissioning process starts
       * Only record warnings for issues that prevent commissioning
       */
      commissionWarnings?: {
        date: string;
        reason: 'AccountNotActivated' | 'ChargePointOffline';
      }[];
      /**
       * Date the installer added connection point details
       * Note that this is currently only used for the hardware-only commissioning process;
       * in Evnex-managed jobs this is tracked under the customerDetailsDate attribute
       */
      connectionPointDate?: string;
      /**
       * Date the installer started creating a hardware-only job
       */
      hardwareOnlyJobCreationStartedDate?: string;
      /**
       * Date the CT (current transducer) status was confirmed
       */
      ctStatusConfirmedDate?: string;
      /**
       * Date the installer job failed
       */
      commissionFailDate?: string;
      /**
       * Date the job had a successful commission
       */
      commissionSuccessDate?: string;
      /**
       * Date the installer initiated the final commissioning process
       */
      commissionRequestDate?: string;
      /**
       * Date the installer uploaded photos of the charge point
       */
      installationPhotosDate?: string;
      /**
       * Date the installer added installation details
       */
      installationDetailsDate?: string;
      /**
       * Date the installer added system setup details
       */
      systemSetupDate?: string;
      /**
       * Date the installer added installation details
       */
      customerDetailsDate?: string;
      /**
       * Date the installer scanned the charge point
       */
      scanChargePointDate?: string;
      /**
       * Date the installer selected the job
       */
      selectJobDate?: string;
      surfaceCableLength?: Metres;
      /**
       * Installation Control Point number, unique to a consumer's premises
       */
      icpNumber?: string;
      /**
       * Maximum current limit in amperes, for configuring an installed charge point with a current sensor
       */
      houseMaxCurrent?: number;
      concealedCableLength?: Metres;
      timeZone?: TimeZone;
    };
    /**
     * Timestamp when job was added to the system
     */
    created: string;
    /**
     * Contact phone number for the job
     */
    contactPhoneNumber?: string;
    commissionResults?: {
      result: CommissionAttemptResult;
      date: string;
    }[];
    status: JobStatus;
    referenceId?: ReferenceId;
    jobType: JobType;
    duration?: Duration;
  };
};

export type Job = JobSummary & {
  relationships: {
    /**
     * Location where the job will be performed
     */
    location: {
      data: LocationIdentifier;
    };
    /**
     * Installers who are assigned to complete the job
     */
    assignedInstallers: {
      data: UserIdentifier[];
    };
    /**
     * Installer who completed the job
     */
    installer: {
      data: UserIdentifier | null;
    };
    /**
     * Customer contact for the job
     */
    customer: {
      data: UserIdentifier | null;
    };
    /**
     * Charging station that was installed
     */
    chargePoint: {
      data: ChargePointIdentifier | null;
    };
    /**
     * Media resources associated with the job; e.g. photos of the installation site, compliance documents
     */
    attachments: {
      data: AttachmentIdentifier[];
    };
  };
};

export interface ResponseListJobs {
  data: Job[];
  included: (LocationSummary | UserSummary)[];
}

export interface ResponseGetJob {
  data: Job;
  included: (LocationSummary | UserSummary | ChargePointSummary)[];
}

export interface ResponseUpdateJob {
  data: Job;
}

/**
 * Unique link to this resource in the Evnex API
 */
export type ResourceLink = string;

export interface RequestCreateJob {
  data: {
    relationships: {
      assignedInstallers: {
        data: UserIdentifier[];
      };
      location: {
        data: LocationIdentifier;
      };
      customer: {
        data: UserIdentifier | null;
      };
    };
    attributes: {
      referenceId?: ReferenceId;
      jobType: JobType;
      isThirdParty: boolean;
      distributionBoards: DistributionBoard[];
      details?: {
        cableConductorSize?: MillimetresSquared;
        totalCableRun?: Metres;
        /**
         * Warnings that may occur before the final commissioning process starts
         * Only record warnings for issues that prevent commissioning
         */
        commissionWarnings?: {
          date: string;
          reason: 'AccountNotActivated' | 'ChargePointOffline';
        }[];
        /**
         * Date the installer added connection point details
         * Note that this is currently only used for the hardware-only commissioning process;
         * in Evnex-managed jobs this is tracked under the customerDetailsDate attribute
         */
        connectionPointDate?: string;
        /**
         * Date the installer started creating a hardware-only job
         */
        hardwareOnlyJobCreationStartedDate?: string;
        /**
         * Date the CT (current transducer) status was confirmed
         */
        ctStatusConfirmedDate?: string;
        /**
         * Date the installer job failed
         */
        commissionFailDate?: string;
        /**
         * Date the job had a successful commission
         */
        commissionSuccessDate?: string;
        /**
         * Date the installer initiated the final commissioning process
         */
        commissionRequestDate?: string;
        /**
         * Date the installer uploaded photos of the charge point
         */
        installationPhotosDate?: string;
        /**
         * Date the installer added installation details
         */
        installationDetailsDate?: string;
        /**
         * Date the installer added system setup details
         */
        systemSetupDate?: string;
        /**
         * Date the installer added installation details
         */
        customerDetailsDate?: string;
        /**
         * Date the installer scanned the charge point
         */
        scanChargePointDate?: string;
        /**
         * Date the installer selected the job
         */
        selectJobDate?: string;
        surfaceCableLength?: Metres;
        /**
         * Installation Control Point number, unique to a consumer's premises
         */
        icpNumber?: string;
        /**
         * Maximum current limit in amperes, for configuring an installed charge point with a current sensor
         */
        houseMaxCurrent?: number;
        concealedCableLength?: Metres;
        timeZone?: TimeZone;
      };
      contactPhoneNumber: string;
    };
    type: 'jobs';
  };
}

/**
 * Values of JobStatus that may be set via the API
 */
export type JobStatusUpdate = 2 | 5 | 3;

export interface RequestUpdateJob {
  data: {
    relationships?: {
      installer?: {
        data: UserIdentifier;
      };
      attachments?: {
        data: AttachmentIdentifier[];
      };
      /**
       * Not allowed to be null if present.
       */
      chargePoint?: {
        data: ChargePointIdentifier;
      };
    };
    attributes?: {
      status?: JobStatusUpdate;
      referenceId?: ReferenceId;
      queenslandActiveDeviceManagement?: boolean;
      notes?: string;
      isThirdParty?: boolean;
      duration?: Duration;
      distributionBoards?: DistributionBoard[];
      details?: {
        cableConductorSize?: MillimetresSquared;
        totalCableRun?: Metres;
        /**
         * Warnings that may occur before the final commissioning process starts
         * Only record warnings for issues that prevent commissioning
         */
        commissionWarnings?: {
          date: string;
          reason: 'AccountNotActivated' | 'ChargePointOffline';
        }[];
        /**
         * Date the installer added connection point details
         * Note that this is currently only used for the hardware-only commissioning process;
         * in Evnex-managed jobs this is tracked under the customerDetailsDate attribute
         */
        connectionPointDate?: string;
        /**
         * Date the installer started creating a hardware-only job
         */
        hardwareOnlyJobCreationStartedDate?: string;
        /**
         * Date the CT (current transducer) status was confirmed
         */
        ctStatusConfirmedDate?: string;
        /**
         * Date the installer job failed
         */
        commissionFailDate?: string;
        /**
         * Date the job had a successful commission
         */
        commissionSuccessDate?: string;
        /**
         * Date the installer initiated the final commissioning process
         */
        commissionRequestDate?: string;
        /**
         * Date the installer uploaded photos of the charge point
         */
        installationPhotosDate?: string;
        /**
         * Date the installer added installation details
         */
        installationDetailsDate?: string;
        /**
         * Date the installer added system setup details
         */
        systemSetupDate?: string;
        /**
         * Date the installer added installation details
         */
        customerDetailsDate?: string;
        /**
         * Date the installer scanned the charge point
         */
        scanChargePointDate?: string;
        /**
         * Date the installer selected the job
         */
        selectJobDate?: string;
        surfaceCableLength?: Metres;
        /**
         * Installation Control Point number, unique to a consumer's premises
         */
        icpNumber?: string;
        /**
         * Maximum current limit in amperes, for configuring an installed charge point with a current sensor
         */
        houseMaxCurrent?: number;
        concealedCableLength?: Metres;
        timeZone?: TimeZone;
      };
      contactPhoneNumber?: string;
    };
    type: 'jobs';
    id: Uuid;
  };
}

export interface ResponseCreateJobOperation {
  'atomic:results': {
    data: Job | Location | User;
  }[];
}

export type OperationCode = 'add' | 'update' | 'remove';

export interface AtomicOperation {
  ref?: {};
  data?: {};
  op: OperationCode;
}

export interface RequestAtomicOperations {
  'atomic:operations': AtomicOperation[];
}

export interface OldResponseAttachments {
  data: AttachmentSummary[];
}

export interface OldResponseListJobs {
  data: Job[];
  included: (LocationSummary | UserSummary)[];
}

export interface OldResponseGetJob {
  data: Job;
  included: (LocationSummary | UserSummary | ChargePointSummary)[];
}

export interface OldResponseUpdateJob {
  data: Job;
}

export interface BaseChargingConfiguration {
  tariffs: {
    export: TariffRates;
    import: TariffRates;
  };
  currency: 'NZD' | 'AUD' | 'EUR' | 'GBP';
}

export type ChargingConfiguration =
  | (BaseChargingConfiguration & {
      periods: {
        /**
         * Schedule to applied on Saturdays
         */
        saturday: Period[];
        /**
         * Schedule to applied on Fridays
         */
        friday: Period[];
        /**
         * Schedule to applied on Thursdays
         */
        thursday: Period[];
        /**
         * Schedule to applied on Wednesdays
         */
        wednesday: Period[];
        /**
         * Schedule to applied on Tuesdays
         */
        tuesday: Period[];
        /**
         * Schedule to applied on Mondays
         */
        monday: Period[];
        /**
         * Schedule to applied on Sundays
         */
        sunday: Period[];
      };
      /**
       * Specify each day of the week
       */
      repeats: 'weekly';
    })
  | (BaseChargingConfiguration & {
      periods: {
        /**
         * Schedule to be applied on each day of the week
         */
        day: Period[];
      };
      /**
       * Specify a single day, to be repeated each day of the week
       */
      repeats: 'daily';
    })
  | (BaseChargingConfiguration & {
      periods: {
        /**
         * Schedule to be applied on each weekday
         */
        weekday: Period[];
        /**
         * Schedule to be applied on Saturday and Sunday
         */
        weekend: Period[];
      };
      /**
       * Specify separate schedules for the weekend and weekdays.
       */
      repeats: 'weekendWeekday';
    });

export type GetAuthenticatedUserProps = Omit<
  GetProps<ResponseGetAuthenticatedUser, ResponseApiErrors, void, void>,
  'path'
>;

/**
 * Get authenticated user
 *
 * Return the current user's details. The current user is determined by the `sub` in the JWT.
 */
export const GetAuthenticatedUser = (props: GetAuthenticatedUserProps) => (
  <Get<ResponseGetAuthenticatedUser, ResponseApiErrors, void, void> path={`/account`} {...props} />
);

export type UseGetAuthenticatedUserProps = Omit<
  UseGetProps<ResponseGetAuthenticatedUser, ResponseApiErrors, void, void>,
  'path'
>;

/**
 * Get authenticated user
 *
 * Return the current user's details. The current user is determined by the `sub` in the JWT.
 */
export const useGetAuthenticatedUser = (props: UseGetAuthenticatedUserProps) =>
  useGet<ResponseGetAuthenticatedUser, ResponseApiErrors, void, void>(`/account`, props);

export interface CreateAccountResponse {
  data: ScopedUser;
}

export type CreateAccountProps = Omit<
  MutateProps<CreateAccountResponse, ResponseApiErrors, void, RequestCreateUser, void>,
  'path' | 'verb'
>;

/**
 * Create user account
 *
 * Create a new user account outside the context of any organisation.
 */
export const CreateAccount = (props: CreateAccountProps) => (
  <Mutate<CreateAccountResponse, ResponseApiErrors, void, RequestCreateUser, void>
    verb="POST"
    path={`/account`}
    {...props}
  />
);

export type UseCreateAccountProps = Omit<
  UseMutateProps<CreateAccountResponse, ResponseApiErrors, void, RequestCreateUser, void>,
  'path' | 'verb'
>;

/**
 * Create user account
 *
 * Create a new user account outside the context of any organisation.
 */
export const useCreateAccount = (props: UseCreateAccountProps) =>
  useMutate<CreateAccountResponse, ResponseApiErrors, void, RequestCreateUser, void>('POST', `/account`, props);

export interface GetAccountStatePathParams {
  id: Uuid;
}

export type GetAccountStateProps = Omit<GetProps<Account, ResponseApiErrors, void, GetAccountStatePathParams>, 'path'> &
  GetAccountStatePathParams;

/**
 * Get user account identity
 *
 * Return a user's identity provider account state.
 */
export const GetAccountState = ({ id, ...props }: GetAccountStateProps) => (
  <Get<Account, ResponseApiErrors, void, GetAccountStatePathParams> path={`/account/${id}`} {...props} />
);

export type UseGetAccountStateProps = Omit<
  UseGetProps<Account, ResponseApiErrors, void, GetAccountStatePathParams>,
  'path'
> &
  GetAccountStatePathParams;

/**
 * Get user account identity
 *
 * Return a user's identity provider account state.
 */
export const useGetAccountState = ({ id, ...props }: UseGetAccountStateProps) =>
  useGet<Account, ResponseApiErrors, void, GetAccountStatePathParams>(
    (paramsInPath: GetAccountStatePathParams) => `/account/${paramsInPath.id}`,
    { pathParams: { id }, ...props },
  );

export type DisableUserProps = Omit<MutateProps<void, ResponseApiErrors, void, Uuid, void>, 'path' | 'verb'>;

/**
 * Disable a user's account
 */
export const DisableUser = (props: DisableUserProps) => (
  <Mutate<void, ResponseApiErrors, void, Uuid, void> verb="DELETE" path={`/account`} {...props} />
);

export type UseDisableUserProps = Omit<UseMutateProps<void, ResponseApiErrors, void, Uuid, void>, 'path' | 'verb'>;

/**
 * Disable a user's account
 */
export const useDisableUser = (props: UseDisableUserProps) =>
  useMutate<void, ResponseApiErrors, void, Uuid, void>('DELETE', `/account`, { ...props });

export interface SetUserPasswordPathParams {
  id: Uuid;
}

export type SetUserPasswordProps = Omit<
  MutateProps<void, ResponseApiErrors, void, void, SetUserPasswordPathParams>,
  'path' | 'verb'
> &
  SetUserPasswordPathParams;

/**
 * Set user password
 *
 * Set a random password on a user's account. This command will transition the account out of the `FORCE_CHANGE_PASSWORD` state.
 */
export const SetUserPassword = ({ id, ...props }: SetUserPasswordProps) => (
  <Mutate<void, ResponseApiErrors, void, void, SetUserPasswordPathParams>
    verb="POST"
    path={`/account/${id}/set-password`}
    {...props}
  />
);

export type UseSetUserPasswordProps = Omit<
  UseMutateProps<void, ResponseApiErrors, void, void, SetUserPasswordPathParams>,
  'path' | 'verb'
> &
  SetUserPasswordPathParams;

/**
 * Set user password
 *
 * Set a random password on a user's account. This command will transition the account out of the `FORCE_CHANGE_PASSWORD` state.
 */
export const useSetUserPassword = ({ id, ...props }: UseSetUserPasswordProps) =>
  useMutate<void, ResponseApiErrors, void, void, SetUserPasswordPathParams>(
    'POST',
    (paramsInPath: SetUserPasswordPathParams) => `/account/${paramsInPath.id}/set-password`,
    { pathParams: { id }, ...props },
  );

export interface MoveChargePointPathParams {
  id: Uuid;
}

export type MoveChargePointProps = Omit<
  MutateProps<void, unknown, void, RequestModifyChargePointLocation, MoveChargePointPathParams>,
  'path' | 'verb'
> &
  MoveChargePointPathParams;

/**
 * Move charge point
 *
 * /**
 *   Move a charge point to a different location.
 */
export const MoveChargePoint = ({ id, ...props }: MoveChargePointProps) => (
  <Mutate<void, unknown, void, RequestModifyChargePointLocation, MoveChargePointPathParams>
    verb="PATCH"
    path={`/charge-points/${id}/relationships/location`}
    {...props}
  />
);

export type UseMoveChargePointProps = Omit<
  UseMutateProps<void, unknown, void, RequestModifyChargePointLocation, MoveChargePointPathParams>,
  'path' | 'verb'
> &
  MoveChargePointPathParams;

/**
 * Move charge point
 *
 * /**
 *   Move a charge point to a different location.
 */
export const useMoveChargePoint = ({ id, ...props }: UseMoveChargePointProps) =>
  useMutate<void, unknown, void, RequestModifyChargePointLocation, MoveChargePointPathParams>(
    'PATCH',
    (paramsInPath: MoveChargePointPathParams) => `/charge-points/${paramsInPath.id}/relationships/location`,
    { pathParams: { id }, ...props },
  );

export type DeleteChargePointProps = Omit<MutateProps<void, ResponseApiErrors, void, Uuid, void>, 'path' | 'verb'>;

/**
 * Delete charge point
 *
 * Delete the requested charge point.
 */
export const DeleteChargePoint = (props: DeleteChargePointProps) => (
  <Mutate<void, ResponseApiErrors, void, Uuid, void> verb="DELETE" path={`/charge-points`} {...props} />
);

export type UseDeleteChargePointProps = Omit<
  UseMutateProps<void, ResponseApiErrors, void, Uuid, void>,
  'path' | 'verb'
>;

/**
 * Delete charge point
 *
 * Delete the requested charge point.
 */
export const useDeleteChargePoint = (props: UseDeleteChargePointProps) =>
  useMutate<void, ResponseApiErrors, void, Uuid, void>('DELETE', `/charge-points`, { ...props });

export interface GetChargePointPathParams {
  id: Uuid;
}

export type GetChargePointProps = Omit<
  GetProps<ResponseGetChargePoint, ResponseApiErrors, void, GetChargePointPathParams>,
  'path'
> &
  GetChargePointPathParams;

/**
 * Get charge point
 *
 * Get details of specified charge point.
 */
export const GetChargePoint = ({ id, ...props }: GetChargePointProps) => (
  <Get<ResponseGetChargePoint, ResponseApiErrors, void, GetChargePointPathParams>
    path={`/charge-points/${id}`}
    {...props}
  />
);

export type UseGetChargePointProps = Omit<
  UseGetProps<ResponseGetChargePoint, ResponseApiErrors, void, GetChargePointPathParams>,
  'path'
> &
  GetChargePointPathParams;

/**
 * Get charge point
 *
 * Get details of specified charge point.
 */
export const useGetChargePoint = ({ id, ...props }: UseGetChargePointProps) =>
  useGet<ResponseGetChargePoint, ResponseApiErrors, void, GetChargePointPathParams>(
    (paramsInPath: GetChargePointPathParams) => `/charge-points/${paramsInPath.id}`,
    { pathParams: { id }, ...props },
  );

export interface UpdateChargePointPathParams {
  id: Uuid;
}

export type UpdateChargePointProps = Omit<
  MutateProps<ResponseUpdateChargePoint, unknown, void, RequestUpdateChargePoint, UpdateChargePointPathParams>,
  'path' | 'verb'
> &
  UpdateChargePointPathParams;

/**
 * Update charge point
 *
 * Update details of specified charge point.
 */
export const UpdateChargePoint = ({ id, ...props }: UpdateChargePointProps) => (
  <Mutate<ResponseUpdateChargePoint, unknown, void, RequestUpdateChargePoint, UpdateChargePointPathParams>
    verb="PATCH"
    path={`/charge-points/${id}`}
    {...props}
  />
);

export type UseUpdateChargePointProps = Omit<
  UseMutateProps<ResponseUpdateChargePoint, unknown, void, RequestUpdateChargePoint, UpdateChargePointPathParams>,
  'path' | 'verb'
> &
  UpdateChargePointPathParams;

/**
 * Update charge point
 *
 * Update details of specified charge point.
 */
export const useUpdateChargePoint = ({ id, ...props }: UseUpdateChargePointProps) =>
  useMutate<ResponseUpdateChargePoint, unknown, void, RequestUpdateChargePoint, UpdateChargePointPathParams>(
    'PATCH',
    (paramsInPath: UpdateChargePointPathParams) => `/charge-points/${paramsInPath.id}`,
    { pathParams: { id }, ...props },
  );

export interface ListChargePointsQueryParams {
  serial?: UrlEncodedString;
}

export type ListChargePointsProps = Omit<
  GetProps<ResponseListChargePoints, unknown, ListChargePointsQueryParams, void>,
  'path'
>;

/**
 * List charge points
 *
 * List all charge points across user's organisations. If user is a Superuser, Evnex Admin, or Installer, list all
 * charge points in the system, filtered by serial number.
 */
export const ListChargePoints = (props: ListChargePointsProps) => (
  <Get<ResponseListChargePoints, unknown, ListChargePointsQueryParams, void> path={`/charge-points`} {...props} />
);

export type UseListChargePointsProps = Omit<
  UseGetProps<ResponseListChargePoints, unknown, ListChargePointsQueryParams, void>,
  'path'
>;

/**
 * List charge points
 *
 * List all charge points across user's organisations. If user is a Superuser, Evnex Admin, or Installer, list all
 * charge points in the system, filtered by serial number.
 */
export const useListChargePoints = (props: UseListChargePointsProps) =>
  useGet<ResponseListChargePoints, unknown, ListChargePointsQueryParams, void>(`/charge-points`, props);

export interface FirmwareUpgradePathParams {
  id: Uuid;
}

export type FirmwareUpgradeProps = Omit<
  GetProps<ResponseGetFirmwareUpgrade, unknown, void, FirmwareUpgradePathParams>,
  'path'
> &
  FirmwareUpgradePathParams;

export const FirmwareUpgrade = ({ id, ...props }: FirmwareUpgradeProps) => (
  <Get<ResponseGetFirmwareUpgrade, unknown, void, FirmwareUpgradePathParams>
    path={`/charge-points/${id}/firmware-upgrade`}
    {...props}
  />
);

export type UseFirmwareUpgradeProps = Omit<
  UseGetProps<ResponseGetFirmwareUpgrade, unknown, void, FirmwareUpgradePathParams>,
  'path'
> &
  FirmwareUpgradePathParams;

export const useFirmwareUpgrade = ({ id, ...props }: UseFirmwareUpgradeProps) =>
  useGet<ResponseGetFirmwareUpgrade, unknown, void, FirmwareUpgradePathParams>(
    (paramsInPath: FirmwareUpgradePathParams) => `/charge-points/${paramsInPath.id}/firmware-upgrade`,
    { pathParams: { id }, ...props },
  );

export interface ListChargePointSessionsQueryParams {
  from?: string;
  to?: string;
}

export interface ListChargePointSessionsPathParams {
  id: Uuid;
}

export type ListChargePointSessionsProps = Omit<
  GetProps<
    ResponseListChargePointSessions,
    unknown,
    ListChargePointSessionsQueryParams,
    ListChargePointSessionsPathParams
  >,
  'path'
> &
  ListChargePointSessionsPathParams;

/**
 * List charge point sessions
 *
 * List sessions for the charge point, optionally filtered by sessionStatus and/or updatedDate.
 */
export const ListChargePointSessions = ({ id, ...props }: ListChargePointSessionsProps) => (
  <Get<ResponseListChargePointSessions, unknown, ListChargePointSessionsQueryParams, ListChargePointSessionsPathParams>
    path={`/charge-points/${id}/sessions`}
    {...props}
  />
);

export type UseListChargePointSessionsProps = Omit<
  UseGetProps<
    ResponseListChargePointSessions,
    unknown,
    ListChargePointSessionsQueryParams,
    ListChargePointSessionsPathParams
  >,
  'path'
> &
  ListChargePointSessionsPathParams;

/**
 * List charge point sessions
 *
 * List sessions for the charge point, optionally filtered by sessionStatus and/or updatedDate.
 */
export const useListChargePointSessions = ({ id, ...props }: UseListChargePointSessionsProps) =>
  useGet<
    ResponseListChargePointSessions,
    unknown,
    ListChargePointSessionsQueryParams,
    ListChargePointSessionsPathParams
  >((paramsInPath: ListChargePointSessionsPathParams) => `/charge-points/${paramsInPath.id}/sessions`, {
    pathParams: { id },
    ...props,
  });

export interface CommandChangeAvailabilityPathParams {
  id: Uuid;
}

export type CommandChangeAvailabilityProps = Omit<
  MutateProps<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandChangeAvailability,
    CommandChangeAvailabilityPathParams
  >,
  'path' | 'verb'
> &
  CommandChangeAvailabilityPathParams;

/**
 * Change Availability command
 *
 * Tell the charge point to change its availability to operative or inoperative.
 */
export const CommandChangeAvailability = ({ id, ...props }: CommandChangeAvailabilityProps) => (
  <Mutate<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandChangeAvailability,
    CommandChangeAvailabilityPathParams
  >
    verb="POST"
    path={`/charge-points/${id}/commands/change-availability`}
    {...props}
  />
);

export type UseCommandChangeAvailabilityProps = Omit<
  UseMutateProps<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandChangeAvailability,
    CommandChangeAvailabilityPathParams
  >,
  'path' | 'verb'
> &
  CommandChangeAvailabilityPathParams;

/**
 * Change Availability command
 *
 * Tell the charge point to change its availability to operative or inoperative.
 */
export const useCommandChangeAvailability = ({ id, ...props }: UseCommandChangeAvailabilityProps) =>
  useMutate<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandChangeAvailability,
    CommandChangeAvailabilityPathParams
  >(
    'POST',
    (paramsInPath: CommandChangeAvailabilityPathParams) =>
      `/charge-points/${paramsInPath.id}/commands/change-availability`,
    { pathParams: { id }, ...props },
  );

export interface CommandChangeConfigurationPathParams {
  id: Uuid;
}

export type CommandChangeConfigurationProps = Omit<
  MutateProps<
    ResponseCommandChangeConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandChangeConfiguration,
    CommandChangeConfigurationPathParams
  >,
  'path' | 'verb'
> &
  CommandChangeConfigurationPathParams;

/**
 * Change Configuration command
 *
 * Set a configuration setting on a charge point to the specified value.
 */
export const CommandChangeConfiguration = ({ id, ...props }: CommandChangeConfigurationProps) => (
  <Mutate<
    ResponseCommandChangeConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandChangeConfiguration,
    CommandChangeConfigurationPathParams
  >
    verb="POST"
    path={`/charge-points/${id}/commands/change-configuration`}
    {...props}
  />
);

export type UseCommandChangeConfigurationProps = Omit<
  UseMutateProps<
    ResponseCommandChangeConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandChangeConfiguration,
    CommandChangeConfigurationPathParams
  >,
  'path' | 'verb'
> &
  CommandChangeConfigurationPathParams;

/**
 * Change Configuration command
 *
 * Set a configuration setting on a charge point to the specified value.
 */
export const useCommandChangeConfiguration = ({ id, ...props }: UseCommandChangeConfigurationProps) =>
  useMutate<
    ResponseCommandChangeConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandChangeConfiguration,
    CommandChangeConfigurationPathParams
  >(
    'POST',
    (paramsInPath: CommandChangeConfigurationPathParams) =>
      `/charge-points/${paramsInPath.id}/commands/change-configuration`,
    { pathParams: { id }, ...props },
  );

export interface CommandDataTransferPathParams {
  id: Uuid;
}

export type CommandDataTransferProps = Omit<
  MutateProps<
    ResponseCommandDataTransfer,
    ResponseApiErrors,
    void,
    RequestCommandDataTransfer,
    CommandDataTransferPathParams
  >,
  'path' | 'verb'
> &
  CommandDataTransferPathParams;

/**
 * Data Transfer command
 *
 * Send custom JSON data to the charge point.
 */
export const CommandDataTransfer = ({ id, ...props }: CommandDataTransferProps) => (
  <Mutate<
    ResponseCommandDataTransfer,
    ResponseApiErrors,
    void,
    RequestCommandDataTransfer,
    CommandDataTransferPathParams
  >
    verb="POST"
    path={`/charge-points/${id}/commands/data-transfer`}
    {...props}
  />
);

export type UseCommandDataTransferProps = Omit<
  UseMutateProps<
    ResponseCommandDataTransfer,
    ResponseApiErrors,
    void,
    RequestCommandDataTransfer,
    CommandDataTransferPathParams
  >,
  'path' | 'verb'
> &
  CommandDataTransferPathParams;

/**
 * Data Transfer command
 *
 * Send custom JSON data to the charge point.
 */
export const useCommandDataTransfer = ({ id, ...props }: UseCommandDataTransferProps) =>
  useMutate<
    ResponseCommandDataTransfer,
    ResponseApiErrors,
    void,
    RequestCommandDataTransfer,
    CommandDataTransferPathParams
  >(
    'POST',
    (paramsInPath: CommandDataTransferPathParams) => `/charge-points/${paramsInPath.id}/commands/data-transfer`,
    { pathParams: { id }, ...props },
  );

export interface CommandGetConfigurationPathParams {
  id: Uuid;
}

export type CommandGetConfigurationProps = Omit<
  MutateProps<
    ResponseCommandGetConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandGetConfiguration,
    CommandGetConfigurationPathParams
  >,
  'path' | 'verb'
> &
  CommandGetConfigurationPathParams;

/**
 * Get Configuration command
 *
 * Get configuration setting(s) on a charge point.
 */
export const CommandGetConfiguration = ({ id, ...props }: CommandGetConfigurationProps) => (
  <Mutate<
    ResponseCommandGetConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandGetConfiguration,
    CommandGetConfigurationPathParams
  >
    verb="POST"
    path={`/charge-points/${id}/commands/get-configuration`}
    {...props}
  />
);

export type UseCommandGetConfigurationProps = Omit<
  UseMutateProps<
    ResponseCommandGetConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandGetConfiguration,
    CommandGetConfigurationPathParams
  >,
  'path' | 'verb'
> &
  CommandGetConfigurationPathParams;

/**
 * Get Configuration command
 *
 * Get configuration setting(s) on a charge point.
 */
export const useCommandGetConfiguration = ({ id, ...props }: UseCommandGetConfigurationProps) =>
  useMutate<
    ResponseCommandGetConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandGetConfiguration,
    CommandGetConfigurationPathParams
  >(
    'POST',
    (paramsInPath: CommandGetConfigurationPathParams) => `/charge-points/${paramsInPath.id}/commands/get-configuration`,
    { pathParams: { id }, ...props },
  );

export interface CommandGetDiagnosticsPathParams {
  id: Uuid;
}

export type CommandGetDiagnosticsProps = Omit<
  MutateProps<ResponseCommand, ResponseApiErrors, void, RequestCommandGetDiagnostics, CommandGetDiagnosticsPathParams>,
  'path' | 'verb'
> &
  CommandGetDiagnosticsPathParams;

/**
 * Get Diagnostics command
 *
 * Command the charge point to upload its diagnostics data to the given URL.
 */
export const CommandGetDiagnostics = ({ id, ...props }: CommandGetDiagnosticsProps) => (
  <Mutate<ResponseCommand, ResponseApiErrors, void, RequestCommandGetDiagnostics, CommandGetDiagnosticsPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/get-diagnostics`}
    {...props}
  />
);

export type UseCommandGetDiagnosticsProps = Omit<
  UseMutateProps<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandGetDiagnostics,
    CommandGetDiagnosticsPathParams
  >,
  'path' | 'verb'
> &
  CommandGetDiagnosticsPathParams;

/**
 * Get Diagnostics command
 *
 * Command the charge point to upload its diagnostics data to the given URL.
 */
export const useCommandGetDiagnostics = ({ id, ...props }: UseCommandGetDiagnosticsProps) =>
  useMutate<ResponseCommand, ResponseApiErrors, void, RequestCommandGetDiagnostics, CommandGetDiagnosticsPathParams>(
    'POST',
    (paramsInPath: CommandGetDiagnosticsPathParams) => `/charge-points/${paramsInPath.id}/commands/get-diagnostics`,
    { pathParams: { id }, ...props },
  );

export interface CommandGetOverridePathParams {
  id: Uuid;
}

export type CommandGetOverrideProps = Omit<
  MutateProps<ResponseCommandGetOverride, ResponseApiErrors, void, void, CommandGetOverridePathParams>,
  'path' | 'verb'
> &
  CommandGetOverridePathParams;

/**
 * Get Override command
 *
 * Ask for a charge point's "charge now" override status.
 */
export const CommandGetOverride = ({ id, ...props }: CommandGetOverrideProps) => (
  <Mutate<ResponseCommandGetOverride, ResponseApiErrors, void, void, CommandGetOverridePathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/get-override`}
    {...props}
  />
);

export type UseCommandGetOverrideProps = Omit<
  UseMutateProps<ResponseCommandGetOverride, ResponseApiErrors, void, void, CommandGetOverridePathParams>,
  'path' | 'verb'
> &
  CommandGetOverridePathParams;

/**
 * Get Override command
 *
 * Ask for a charge point's "charge now" override status.
 */
export const useCommandGetOverride = ({ id, ...props }: UseCommandGetOverrideProps) =>
  useMutate<ResponseCommandGetOverride, ResponseApiErrors, void, void, CommandGetOverridePathParams>(
    'POST',
    (paramsInPath: CommandGetOverridePathParams) => `/charge-points/${paramsInPath.id}/commands/get-override`,
    { pathParams: { id }, ...props },
  );

export interface CommandGetSolarPathParams {
  id: Uuid;
}

export type CommandGetSolarProps = Omit<
  MutateProps<ResponseCommandGetSolar, ResponseApiErrors, void, void, CommandGetSolarPathParams>,
  'path' | 'verb'
> &
  CommandGetSolarPathParams;

/**
 * Get Solar command
 *
 * Ask for a charge point's solar configuration settings.
 */
export const CommandGetSolar = ({ id, ...props }: CommandGetSolarProps) => (
  <Mutate<ResponseCommandGetSolar, ResponseApiErrors, void, void, CommandGetSolarPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/get-solar`}
    {...props}
  />
);

export type UseCommandGetSolarProps = Omit<
  UseMutateProps<ResponseCommandGetSolar, ResponseApiErrors, void, void, CommandGetSolarPathParams>,
  'path' | 'verb'
> &
  CommandGetSolarPathParams;

/**
 * Get Solar command
 *
 * Ask for a charge point's solar configuration settings.
 */
export const useCommandGetSolar = ({ id, ...props }: UseCommandGetSolarProps) =>
  useMutate<ResponseCommandGetSolar, ResponseApiErrors, void, void, CommandGetSolarPathParams>(
    'POST',
    (paramsInPath: CommandGetSolarPathParams) => `/charge-points/${paramsInPath.id}/commands/get-solar`,
    { pathParams: { id }, ...props },
  );

export interface CommandResetPathParams {
  id: Uuid;
}

export type CommandResetProps = Omit<
  MutateProps<ResponseCommand, ResponseApiErrors, void, RequestCommandReset, CommandResetPathParams>,
  'path' | 'verb'
> &
  CommandResetPathParams;

/**
 * Reset command
 *
 * Command the charge point to do a hard or soft reboot.
 */
export const CommandReset = ({ id, ...props }: CommandResetProps) => (
  <Mutate<ResponseCommand, ResponseApiErrors, void, RequestCommandReset, CommandResetPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/reset`}
    {...props}
  />
);

export type UseCommandResetProps = Omit<
  UseMutateProps<ResponseCommand, ResponseApiErrors, void, RequestCommandReset, CommandResetPathParams>,
  'path' | 'verb'
> &
  CommandResetPathParams;

/**
 * Reset command
 *
 * Command the charge point to do a hard or soft reboot.
 */
export const useCommandReset = ({ id, ...props }: UseCommandResetProps) =>
  useMutate<ResponseCommand, ResponseApiErrors, void, RequestCommandReset, CommandResetPathParams>(
    'POST',
    (paramsInPath: CommandResetPathParams) => `/charge-points/${paramsInPath.id}/commands/reset`,
    { pathParams: { id }, ...props },
  );

export interface CommandSetOverridePathParams {
  id: Uuid;
}

export type CommandSetOverrideProps = Omit<
  MutateProps<void, ResponseApiErrors, void, RequestCommandSetOverride, CommandSetOverridePathParams>,
  'path' | 'verb'
> &
  CommandSetOverridePathParams;

/**
 * Set Override command
 *
 * Override the smart charging profiles set on the charge point temporarily, or cancel the override.
 */
export const CommandSetOverride = ({ id, ...props }: CommandSetOverrideProps) => (
  <Mutate<void, ResponseApiErrors, void, RequestCommandSetOverride, CommandSetOverridePathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/set-override`}
    {...props}
  />
);

export type UseCommandSetOverrideProps = Omit<
  UseMutateProps<void, ResponseApiErrors, void, RequestCommandSetOverride, CommandSetOverridePathParams>,
  'path' | 'verb'
> &
  CommandSetOverridePathParams;

/**
 * Set Override command
 *
 * Override the smart charging profiles set on the charge point temporarily, or cancel the override.
 */
export const useCommandSetOverride = ({ id, ...props }: UseCommandSetOverrideProps) =>
  useMutate<void, ResponseApiErrors, void, RequestCommandSetOverride, CommandSetOverridePathParams>(
    'POST',
    (paramsInPath: CommandSetOverridePathParams) => `/charge-points/${paramsInPath.id}/commands/set-override`,
    { pathParams: { id }, ...props },
  );

export interface CommandSetSolarPathParams {
  id: Uuid;
}

export type CommandSetSolarProps = Omit<
  MutateProps<ResponseCommandSetSolar, ResponseApiErrors, void, RequestCommandSetSolar, CommandSetSolarPathParams>,
  'path' | 'verb'
> &
  CommandSetSolarPathParams;

/**
 * Set Solar command
 *
 * Set a charge point's solar configuration settings.
 */
export const CommandSetSolar = ({ id, ...props }: CommandSetSolarProps) => (
  <Mutate<ResponseCommandSetSolar, ResponseApiErrors, void, RequestCommandSetSolar, CommandSetSolarPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/set-solar`}
    {...props}
  />
);

export type UseCommandSetSolarProps = Omit<
  UseMutateProps<ResponseCommandSetSolar, ResponseApiErrors, void, RequestCommandSetSolar, CommandSetSolarPathParams>,
  'path' | 'verb'
> &
  CommandSetSolarPathParams;

/**
 * Set Solar command
 *
 * Set a charge point's solar configuration settings.
 */
export const useCommandSetSolar = ({ id, ...props }: UseCommandSetSolarProps) =>
  useMutate<ResponseCommandSetSolar, ResponseApiErrors, void, RequestCommandSetSolar, CommandSetSolarPathParams>(
    'POST',
    (paramsInPath: CommandSetSolarPathParams) => `/charge-points/${paramsInPath.id}/commands/set-solar`,
    { pathParams: { id }, ...props },
  );

export interface CommandSetAntiSleepPathParams {
  id: Uuid;
}

export type CommandSetAntiSleepProps = Omit<
  MutateProps<void, ResponseApiErrors, void, RequestCommandSetAntiSleep, CommandSetAntiSleepPathParams>,
  'path' | 'verb'
> &
  CommandSetAntiSleepPathParams;

/**
 * Set AntiSleepEnabled command
 */
export const CommandSetAntiSleep = ({ id, ...props }: CommandSetAntiSleepProps) => (
  <Mutate<void, ResponseApiErrors, void, RequestCommandSetAntiSleep, CommandSetAntiSleepPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/set-anti-sleep`}
    {...props}
  />
);

export type UseCommandSetAntiSleepProps = Omit<
  UseMutateProps<void, ResponseApiErrors, void, RequestCommandSetAntiSleep, CommandSetAntiSleepPathParams>,
  'path' | 'verb'
> &
  CommandSetAntiSleepPathParams;

/**
 * Set AntiSleepEnabled command
 */
export const useCommandSetAntiSleep = ({ id, ...props }: UseCommandSetAntiSleepProps) =>
  useMutate<void, ResponseApiErrors, void, RequestCommandSetAntiSleep, CommandSetAntiSleepPathParams>(
    'POST',
    (paramsInPath: CommandSetAntiSleepPathParams) => `/charge-points/${paramsInPath.id}/commands/set-anti-sleep`,
    { pathParams: { id }, ...props },
  );

export interface CommandRemoteStartPathParams {
  id: Uuid;
}

export type CommandRemoteStartProps = Omit<
  MutateProps<ResponseCommand, ResponseApiErrors, void, RequestCommandRemoteStart, CommandRemoteStartPathParams>,
  'path' | 'verb'
> &
  CommandRemoteStartPathParams;

/**
 * Remote Start command
 *
 * Begin a charging session with the given authorization token.
 */
export const CommandRemoteStart = ({ id, ...props }: CommandRemoteStartProps) => (
  <Mutate<ResponseCommand, ResponseApiErrors, void, RequestCommandRemoteStart, CommandRemoteStartPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/remote-start`}
    {...props}
  />
);

export type UseCommandRemoteStartProps = Omit<
  UseMutateProps<ResponseCommand, ResponseApiErrors, void, RequestCommandRemoteStart, CommandRemoteStartPathParams>,
  'path' | 'verb'
> &
  CommandRemoteStartPathParams;

/**
 * Remote Start command
 *
 * Begin a charging session with the given authorization token.
 */
export const useCommandRemoteStart = ({ id, ...props }: UseCommandRemoteStartProps) =>
  useMutate<ResponseCommand, ResponseApiErrors, void, RequestCommandRemoteStart, CommandRemoteStartPathParams>(
    'POST',
    (paramsInPath: CommandRemoteStartPathParams) => `/charge-points/${paramsInPath.id}/commands/remote-start`,
    { pathParams: { id }, ...props },
  );

export interface CommandRemoteStopPathParams {
  id: Uuid;
}

export type CommandRemoteStopProps = Omit<
  MutateProps<ResponseCommand, ResponseApiErrors, void, RequestCommandRemoteStop, CommandRemoteStopPathParams>,
  'path' | 'verb'
> &
  CommandRemoteStopPathParams;

/**
 * Remote Stop command
 *
 * End the charging session in progress.
 */
export const CommandRemoteStop = ({ id, ...props }: CommandRemoteStopProps) => (
  <Mutate<ResponseCommand, ResponseApiErrors, void, RequestCommandRemoteStop, CommandRemoteStopPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/remote-stop`}
    {...props}
  />
);

export type UseCommandRemoteStopProps = Omit<
  UseMutateProps<ResponseCommand, ResponseApiErrors, void, RequestCommandRemoteStop, CommandRemoteStopPathParams>,
  'path' | 'verb'
> &
  CommandRemoteStopPathParams;

/**
 * Remote Stop command
 *
 * End the charging session in progress.
 */
export const useCommandRemoteStop = ({ id, ...props }: UseCommandRemoteStopProps) =>
  useMutate<ResponseCommand, ResponseApiErrors, void, RequestCommandRemoteStop, CommandRemoteStopPathParams>(
    'POST',
    (paramsInPath: CommandRemoteStopPathParams) => `/charge-points/${paramsInPath.id}/commands/remote-stop`,
    { pathParams: { id }, ...props },
  );

export interface CommandUnlockConnectorPathParams {
  id: Uuid;
}

export type CommandUnlockConnectorProps = Omit<
  MutateProps<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandUnlockConnector,
    CommandUnlockConnectorPathParams
  >,
  'path' | 'verb'
> &
  CommandUnlockConnectorPathParams;

/**
 * Unlock Connector command
 *
 * Command a connector to release the cable plugged into it.
 */
export const CommandUnlockConnector = ({ id, ...props }: CommandUnlockConnectorProps) => (
  <Mutate<ResponseCommand, ResponseApiErrors, void, RequestCommandUnlockConnector, CommandUnlockConnectorPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/unlock-connector`}
    {...props}
  />
);

export type UseCommandUnlockConnectorProps = Omit<
  UseMutateProps<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandUnlockConnector,
    CommandUnlockConnectorPathParams
  >,
  'path' | 'verb'
> &
  CommandUnlockConnectorPathParams;

/**
 * Unlock Connector command
 *
 * Command a connector to release the cable plugged into it.
 */
export const useCommandUnlockConnector = ({ id, ...props }: UseCommandUnlockConnectorProps) =>
  useMutate<ResponseCommand, ResponseApiErrors, void, RequestCommandUnlockConnector, CommandUnlockConnectorPathParams>(
    'POST',
    (paramsInPath: CommandUnlockConnectorPathParams) => `/charge-points/${paramsInPath.id}/commands/unlock-connector`,
    { pathParams: { id }, ...props },
  );

export interface CommandUpdateFirmwarePathParams {
  id: Uuid;
}

export type CommandUpdateFirmwareProps = Omit<
  MutateProps<void, ResponseApiErrors, void, RequestCommandUpdateFirmware, CommandUpdateFirmwarePathParams>,
  'path' | 'verb'
> &
  CommandUpdateFirmwarePathParams;

/**
 * Update Firmware command
 *
 * Tell the charge point to update its firmware.
 */
export const CommandUpdateFirmware = ({ id, ...props }: CommandUpdateFirmwareProps) => (
  <Mutate<void, ResponseApiErrors, void, RequestCommandUpdateFirmware, CommandUpdateFirmwarePathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/update-firmware`}
    {...props}
  />
);

export type UseCommandUpdateFirmwareProps = Omit<
  UseMutateProps<void, ResponseApiErrors, void, RequestCommandUpdateFirmware, CommandUpdateFirmwarePathParams>,
  'path' | 'verb'
> &
  CommandUpdateFirmwarePathParams;

/**
 * Update Firmware command
 *
 * Tell the charge point to update its firmware.
 */
export const useCommandUpdateFirmware = ({ id, ...props }: UseCommandUpdateFirmwareProps) =>
  useMutate<void, ResponseApiErrors, void, RequestCommandUpdateFirmware, CommandUpdateFirmwarePathParams>(
    'POST',
    (paramsInPath: CommandUpdateFirmwarePathParams) => `/charge-points/${paramsInPath.id}/commands/update-firmware`,
    { pathParams: { id }, ...props },
  );

export interface CommandUpgradePathParams {
  id: Uuid;
}

export type CommandUpgradeProps = Omit<
  MutateProps<void, ResponseApiErrors, void, void, CommandUpgradePathParams>,
  'path' | 'verb'
> &
  CommandUpgradePathParams;

/**
 * Upgrade firmware command
 *
 * Tell the charge point to upgrade to the next version of firmware.
 */
export const CommandUpgrade = ({ id, ...props }: CommandUpgradeProps) => (
  <Mutate<void, ResponseApiErrors, void, void, CommandUpgradePathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/upgrade`}
    {...props}
  />
);

export type UseCommandUpgradeProps = Omit<
  UseMutateProps<void, ResponseApiErrors, void, void, CommandUpgradePathParams>,
  'path' | 'verb'
> &
  CommandUpgradePathParams;

/**
 * Upgrade firmware command
 *
 * Tell the charge point to upgrade to the next version of firmware.
 */
export const useCommandUpgrade = ({ id, ...props }: UseCommandUpgradeProps) =>
  useMutate<void, ResponseApiErrors, void, void, CommandUpgradePathParams>(
    'POST',
    (paramsInPath: CommandUpgradePathParams) => `/charge-points/${paramsInPath.id}/commands/upgrade`,
    { pathParams: { id }, ...props },
  );

export interface CommandClearChargingProfilePathParams {
  id: Uuid;
}

export type CommandClearChargingProfileProps = Omit<
  MutateProps<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandClearChargingProfile,
    CommandClearChargingProfilePathParams
  >,
  'path' | 'verb'
> &
  CommandClearChargingProfilePathParams;

/**
 * Clear Charging Profile command
 *
 * Remove either a specific charging profile (by its ID) or a selection of charging profiles
 * (by connector ID, stack level, and charging profile purpose).
 */
export const CommandClearChargingProfile = ({ id, ...props }: CommandClearChargingProfileProps) => (
  <Mutate<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandClearChargingProfile,
    CommandClearChargingProfilePathParams
  >
    verb="POST"
    path={`/charge-points/${id}/commands/clear-charging-profile`}
    {...props}
  />
);

export type UseCommandClearChargingProfileProps = Omit<
  UseMutateProps<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandClearChargingProfile,
    CommandClearChargingProfilePathParams
  >,
  'path' | 'verb'
> &
  CommandClearChargingProfilePathParams;

/**
 * Clear Charging Profile command
 *
 * Remove either a specific charging profile (by its ID) or a selection of charging profiles
 * (by connector ID, stack level, and charging profile purpose).
 */
export const useCommandClearChargingProfile = ({ id, ...props }: UseCommandClearChargingProfileProps) =>
  useMutate<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandClearChargingProfile,
    CommandClearChargingProfilePathParams
  >(
    'POST',
    (paramsInPath: CommandClearChargingProfilePathParams) =>
      `/charge-points/${paramsInPath.id}/commands/clear-charging-profile`,
    { pathParams: { id }, ...props },
  );

export interface CommandClearCachePathParams {
  id: Uuid;
}

export type CommandClearCacheProps = Omit<
  MutateProps<ResponseCommand, ResponseApiErrors, void, void, CommandClearCachePathParams>,
  'path' | 'verb'
> &
  CommandClearCachePathParams;

/**
 * Clear Cache command
 *
 * Tell the charge point to clear its authorization cache.
 */
export const CommandClearCache = ({ id, ...props }: CommandClearCacheProps) => (
  <Mutate<ResponseCommand, ResponseApiErrors, void, void, CommandClearCachePathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/clear-cache`}
    {...props}
  />
);

export type UseCommandClearCacheProps = Omit<
  UseMutateProps<ResponseCommand, ResponseApiErrors, void, void, CommandClearCachePathParams>,
  'path' | 'verb'
> &
  CommandClearCachePathParams;

/**
 * Clear Cache command
 *
 * Tell the charge point to clear its authorization cache.
 */
export const useCommandClearCache = ({ id, ...props }: UseCommandClearCacheProps) =>
  useMutate<ResponseCommand, ResponseApiErrors, void, void, CommandClearCachePathParams>(
    'POST',
    (paramsInPath: CommandClearCachePathParams) => `/charge-points/${paramsInPath.id}/commands/clear-cache`,
    { pathParams: { id }, ...props },
  );

export interface CommandSudoPathParams {
  id: Uuid;
}

export type CommandSudoProps = Omit<
  MutateProps<ResponseCommand, ResponseApiErrors, void, RequestCommandSudo, CommandSudoPathParams>,
  'path' | 'verb'
> &
  CommandSudoPathParams;

/**
 * Sudo command
 *
 * Send a custom OCPP message to the charge point
 */
export const CommandSudo = ({ id, ...props }: CommandSudoProps) => (
  <Mutate<ResponseCommand, ResponseApiErrors, void, RequestCommandSudo, CommandSudoPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/sudo`}
    {...props}
  />
);

export type UseCommandSudoProps = Omit<
  UseMutateProps<ResponseCommand, ResponseApiErrors, void, RequestCommandSudo, CommandSudoPathParams>,
  'path' | 'verb'
> &
  CommandSudoPathParams;

/**
 * Sudo command
 *
 * Send a custom OCPP message to the charge point
 */
export const useCommandSudo = ({ id, ...props }: UseCommandSudoProps) =>
  useMutate<ResponseCommand, ResponseApiErrors, void, RequestCommandSudo, CommandSudoPathParams>(
    'POST',
    (paramsInPath: CommandSudoPathParams) => `/charge-points/${paramsInPath.id}/commands/sudo`,
    { pathParams: { id }, ...props },
  );

export interface CommandUploadDiagnosticsPathParams {
  id: Uuid;
}

export type CommandUploadDiagnosticsProps = Omit<
  MutateProps<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandUploadDiagnostics,
    CommandUploadDiagnosticsPathParams
  >,
  'path' | 'verb'
> &
  CommandUploadDiagnosticsPathParams;

/**
 * Upload Diagnostics command
 *
 * Upload charge point diagnostics logs using the specified protocol
 */
export const CommandUploadDiagnostics = ({ id, ...props }: CommandUploadDiagnosticsProps) => (
  <Mutate<ResponseCommand, ResponseApiErrors, void, RequestCommandUploadDiagnostics, CommandUploadDiagnosticsPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/upload-diagnostics`}
    {...props}
  />
);

export type UseCommandUploadDiagnosticsProps = Omit<
  UseMutateProps<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandUploadDiagnostics,
    CommandUploadDiagnosticsPathParams
  >,
  'path' | 'verb'
> &
  CommandUploadDiagnosticsPathParams;

/**
 * Upload Diagnostics command
 *
 * Upload charge point diagnostics logs using the specified protocol
 */
export const useCommandUploadDiagnostics = ({ id, ...props }: UseCommandUploadDiagnosticsProps) =>
  useMutate<
    ResponseCommand,
    ResponseApiErrors,
    void,
    RequestCommandUploadDiagnostics,
    CommandUploadDiagnosticsPathParams
  >(
    'POST',
    (paramsInPath: CommandUploadDiagnosticsPathParams) =>
      `/charge-points/${paramsInPath.id}/commands/upload-diagnostics`,
    { pathParams: { id }, ...props },
  );

export interface CommandGetStatusPathParams {
  id: Uuid;
}

export type CommandGetStatusProps = Omit<
  MutateProps<ResponseCommandGetStatus, ResponseApiErrors, void, void, CommandGetStatusPathParams>,
  'path' | 'verb'
> &
  CommandGetStatusPathParams;

/**
 * Get Status command
 *
 * Get charge point status (including information about the state of the charge point such as whether
 * charge now is active, the charging logic, and how the charging current is being controlled)
 */
export const CommandGetStatus = ({ id, ...props }: CommandGetStatusProps) => (
  <Mutate<ResponseCommandGetStatus, ResponseApiErrors, void, void, CommandGetStatusPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/get-status`}
    {...props}
  />
);

export type UseCommandGetStatusProps = Omit<
  UseMutateProps<ResponseCommandGetStatus, ResponseApiErrors, void, void, CommandGetStatusPathParams>,
  'path' | 'verb'
> &
  CommandGetStatusPathParams;

/**
 * Get Status command
 *
 * Get charge point status (including information about the state of the charge point such as whether
 * charge now is active, the charging logic, and how the charging current is being controlled)
 */
export const useCommandGetStatus = ({ id, ...props }: UseCommandGetStatusProps) =>
  useMutate<ResponseCommandGetStatus, ResponseApiErrors, void, void, CommandGetStatusPathParams>(
    'POST',
    (paramsInPath: CommandGetStatusPathParams) => `/charge-points/${paramsInPath.id}/commands/get-status`,
    { pathParams: { id }, ...props },
  );

export interface CommandGetCTStatusPathParams {
  id: Uuid;
}

export type CommandGetCTStatusProps = Omit<
  MutateProps<ResponseCommandGetCTStatus, ResponseApiErrors, void, void, CommandGetCTStatusPathParams>,
  'path' | 'verb'
> &
  CommandGetCTStatusPathParams;

/**
 * Get CT status
 *
 * Get a reading from the current transducer(s) attached to the charge point.
 */
export const CommandGetCTStatus = ({ id, ...props }: CommandGetCTStatusProps) => (
  <Mutate<ResponseCommandGetCTStatus, ResponseApiErrors, void, void, CommandGetCTStatusPathParams>
    verb="POST"
    path={`/charge-points/${id}/commands/get-ct-status`}
    {...props}
  />
);

export type UseCommandGetCTStatusProps = Omit<
  UseMutateProps<ResponseCommandGetCTStatus, ResponseApiErrors, void, void, CommandGetCTStatusPathParams>,
  'path' | 'verb'
> &
  CommandGetCTStatusPathParams;

/**
 * Get CT status
 *
 * Get a reading from the current transducer(s) attached to the charge point.
 */
export const useCommandGetCTStatus = ({ id, ...props }: UseCommandGetCTStatusProps) =>
  useMutate<ResponseCommandGetCTStatus, ResponseApiErrors, void, void, CommandGetCTStatusPathParams>(
    'POST',
    (paramsInPath: CommandGetCTStatusPathParams) => `/charge-points/${paramsInPath.id}/commands/get-ct-status`,
    { pathParams: { id }, ...props },
  );

export interface ListInstallationControlPointsQueryParams {
  /**
   * The street number of the address
   */
  streetNumber: StreetNumberQuery;
  /**
   * The street name of the address
   */
  streetName: StreetNameQuery;
  city?: string;
}

export type ListInstallationControlPointsProps = Omit<
  GetProps<ResponseListInstallationControlPoints, unknown, ListInstallationControlPointsQueryParams, void>,
  'path'
>;

/**
 * List Installation Control Points
 *
 * List ICP details for addresses matching the given query parameters
 */
export const ListInstallationControlPoints = (props: ListInstallationControlPointsProps) => (
  <Get<ResponseListInstallationControlPoints, unknown, ListInstallationControlPointsQueryParams, void>
    path={`/installation-control-points`}
    {...props}
  />
);

export type UseListInstallationControlPointsProps = Omit<
  UseGetProps<ResponseListInstallationControlPoints, unknown, ListInstallationControlPointsQueryParams, void>,
  'path'
>;

/**
 * List Installation Control Points
 *
 * List ICP details for addresses matching the given query parameters
 */
export const useListInstallationControlPoints = (props: UseListInstallationControlPointsProps) =>
  useGet<ResponseListInstallationControlPoints, unknown, ListInstallationControlPointsQueryParams, void>(
    `/installation-control-points`,
    props,
  );

export interface UpdateLoadBalancingGroupPathParams {
  loadBalancingGroupId: Uuid;
}

export type UpdateLoadBalancingGroupProps = Omit<
  MutateProps<void, unknown, void, RequestUpdateLoadBalancingGroup, UpdateLoadBalancingGroupPathParams>,
  'path' | 'verb'
> &
  UpdateLoadBalancingGroupPathParams;

/**
 * Update load balancing group
 *
 * Replace all attributes and charge points of the specified load balancing group.
 */
export const UpdateLoadBalancingGroup = ({ loadBalancingGroupId, ...props }: UpdateLoadBalancingGroupProps) => (
  <Mutate<void, unknown, void, RequestUpdateLoadBalancingGroup, UpdateLoadBalancingGroupPathParams>
    verb="PUT"
    path={`/load-balancing-groups/${loadBalancingGroupId}`}
    {...props}
  />
);

export type UseUpdateLoadBalancingGroupProps = Omit<
  UseMutateProps<void, unknown, void, RequestUpdateLoadBalancingGroup, UpdateLoadBalancingGroupPathParams>,
  'path' | 'verb'
> &
  UpdateLoadBalancingGroupPathParams;

/**
 * Update load balancing group
 *
 * Replace all attributes and charge points of the specified load balancing group.
 */
export const useUpdateLoadBalancingGroup = ({ loadBalancingGroupId, ...props }: UseUpdateLoadBalancingGroupProps) =>
  useMutate<void, unknown, void, RequestUpdateLoadBalancingGroup, UpdateLoadBalancingGroupPathParams>(
    'PUT',
    (paramsInPath: UpdateLoadBalancingGroupPathParams) => `/load-balancing-groups/${paramsInPath.loadBalancingGroupId}`,
    { pathParams: { loadBalancingGroupId }, ...props },
  );

export type DeleteLoadBalancingGroupProps = Omit<MutateProps<void, unknown, void, Uuid, void>, 'path' | 'verb'>;

/**
 * Delete load balancing group
 *
 * Delete the specified load balancing group.
 */
export const DeleteLoadBalancingGroup = (props: DeleteLoadBalancingGroupProps) => (
  <Mutate<void, unknown, void, Uuid, void> verb="DELETE" path={`/load-balancing-groups`} {...props} />
);

export type UseDeleteLoadBalancingGroupProps = Omit<UseMutateProps<void, unknown, void, Uuid, void>, 'path' | 'verb'>;

/**
 * Delete load balancing group
 *
 * Delete the specified load balancing group.
 */
export const useDeleteLoadBalancingGroup = (props: UseDeleteLoadBalancingGroupProps) =>
  useMutate<void, unknown, void, Uuid, void>('DELETE', `/load-balancing-groups`, { ...props });

export type ListLocationsProps = Omit<GetProps<ResponseListLocations, unknown, void, void>, 'path'>;

/**
 * List locations
 *
 * List all locations in the system.
 */
export const ListLocations = (props: ListLocationsProps) => (
  <Get<ResponseListLocations, unknown, void, void> path={`/locations`} {...props} />
);

export type UseListLocationsProps = Omit<UseGetProps<ResponseListLocations, unknown, void, void>, 'path'>;

/**
 * List locations
 *
 * List all locations in the system.
 */
export const useListLocations = (props: UseListLocationsProps) =>
  useGet<ResponseListLocations, unknown, void, void>(`/locations`, props);

export interface GetLocationPathParams {
  locationId: Uuid;
}

export type GetLocationProps = Omit<
  GetProps<ResponseGetLocation, ResponseApiErrors, void, GetLocationPathParams>,
  'path'
> &
  GetLocationPathParams;

/**
 * Get location
 *
 * Get a single location.
 */
export const GetLocation = ({ locationId, ...props }: GetLocationProps) => (
  <Get<ResponseGetLocation, ResponseApiErrors, void, GetLocationPathParams>
    path={`/locations/${locationId}`}
    {...props}
  />
);

export type UseGetLocationProps = Omit<
  UseGetProps<ResponseGetLocation, ResponseApiErrors, void, GetLocationPathParams>,
  'path'
> &
  GetLocationPathParams;

/**
 * Get location
 *
 * Get a single location.
 */
export const useGetLocation = ({ locationId, ...props }: UseGetLocationProps) =>
  useGet<ResponseGetLocation, ResponseApiErrors, void, GetLocationPathParams>(
    (paramsInPath: GetLocationPathParams) => `/locations/${paramsInPath.locationId}`,
    { pathParams: { locationId }, ...props },
  );

export interface UpdateLocationPathParams {
  locationId: Uuid;
}

export type UpdateLocationProps = Omit<
  MutateProps<ResponseUpdateLocation, unknown, void, RequestUpdateLocation, UpdateLocationPathParams>,
  'path' | 'verb'
> &
  UpdateLocationPathParams;

/**
 * Update location
 *
 * Update the attributes of a location.
 */
export const UpdateLocation = ({ locationId, ...props }: UpdateLocationProps) => (
  <Mutate<ResponseUpdateLocation, unknown, void, RequestUpdateLocation, UpdateLocationPathParams>
    verb="PATCH"
    path={`/locations/${locationId}`}
    {...props}
  />
);

export type UseUpdateLocationProps = Omit<
  UseMutateProps<ResponseUpdateLocation, unknown, void, RequestUpdateLocation, UpdateLocationPathParams>,
  'path' | 'verb'
> &
  UpdateLocationPathParams;

/**
 * Update location
 *
 * Update the attributes of a location.
 */
export const useUpdateLocation = ({ locationId, ...props }: UseUpdateLocationProps) =>
  useMutate<ResponseUpdateLocation, unknown, void, RequestUpdateLocation, UpdateLocationPathParams>(
    'PATCH',
    (paramsInPath: UpdateLocationPathParams) => `/locations/${paramsInPath.locationId}`,
    { pathParams: { locationId }, ...props },
  );

export interface ListLocationUsersPathParams {
  locationId: Uuid;
}

export type ListLocationUsersProps = Omit<
  GetProps<ResponseListLocationUsers, unknown, void, ListLocationUsersPathParams>,
  'path'
> &
  ListLocationUsersPathParams;

/**
 * List location users
 *
 * List users who have been granted access to a location.
 */
export const ListLocationUsers = ({ locationId, ...props }: ListLocationUsersProps) => (
  <Get<ResponseListLocationUsers, unknown, void, ListLocationUsersPathParams>
    path={`/locations/${locationId}/users`}
    {...props}
  />
);

export type UseListLocationUsersProps = Omit<
  UseGetProps<ResponseListLocationUsers, unknown, void, ListLocationUsersPathParams>,
  'path'
> &
  ListLocationUsersPathParams;

/**
 * List location users
 *
 * List users who have been granted access to a location.
 */
export const useListLocationUsers = ({ locationId, ...props }: UseListLocationUsersProps) =>
  useGet<ResponseListLocationUsers, unknown, void, ListLocationUsersPathParams>(
    (paramsInPath: ListLocationUsersPathParams) => `/locations/${paramsInPath.locationId}/users`,
    { pathParams: { locationId }, ...props },
  );

export interface MoveLocationPathParams {
  locationId: Uuid;
}

export type MoveLocationProps = Omit<
  MutateProps<void, unknown, void, RequestModifyLocationOrg, MoveLocationPathParams>,
  'path' | 'verb'
> &
  MoveLocationPathParams;

/**
 * Move location
 *
 * Move a location to a different organisation, along with its associated charge point and invited user.
 */
export const MoveLocation = ({ locationId, ...props }: MoveLocationProps) => (
  <Mutate<void, unknown, void, RequestModifyLocationOrg, MoveLocationPathParams>
    verb="PATCH"
    path={`/locations/${locationId}/relationships/organisation`}
    {...props}
  />
);

export type UseMoveLocationProps = Omit<
  UseMutateProps<void, unknown, void, RequestModifyLocationOrg, MoveLocationPathParams>,
  'path' | 'verb'
> &
  MoveLocationPathParams;

/**
 * Move location
 *
 * Move a location to a different organisation, along with its associated charge point and invited user.
 */
export const useMoveLocation = ({ locationId, ...props }: UseMoveLocationProps) =>
  useMutate<void, unknown, void, RequestModifyLocationOrg, MoveLocationPathParams>(
    'PATCH',
    (paramsInPath: MoveLocationPathParams) => `/locations/${paramsInPath.locationId}/relationships/organisation`,
    { pathParams: { locationId }, ...props },
  );

export interface RemoveLocationUsersPathParams {
  locationId: Uuid;
}

export type RemoveLocationUsersProps = Omit<
  MutateProps<ResponseBase, unknown, void, RequestModifyLocationUsers, RemoveLocationUsersPathParams>,
  'path' | 'verb'
> &
  RemoveLocationUsersPathParams;

/**
 * Remove location users
 *
 * Remove users from a location.
 */
export const RemoveLocationUsers = ({ locationId, ...props }: RemoveLocationUsersProps) => (
  <Mutate<ResponseBase, unknown, void, RequestModifyLocationUsers, RemoveLocationUsersPathParams>
    verb="DELETE"
    path={`/locations/${locationId}/relationships/users`}
    {...props}
  />
);

export type UseRemoveLocationUsersProps = Omit<
  UseMutateProps<ResponseBase, unknown, void, RequestModifyLocationUsers, RemoveLocationUsersPathParams>,
  'path' | 'verb'
> &
  RemoveLocationUsersPathParams;

/**
 * Remove location users
 *
 * Remove users from a location.
 */
export const useRemoveLocationUsers = ({ locationId, ...props }: UseRemoveLocationUsersProps) =>
  useMutate<ResponseBase, unknown, void, RequestModifyLocationUsers, RemoveLocationUsersPathParams>(
    'DELETE',
    (paramsInPath: RemoveLocationUsersPathParams) => `/locations/${paramsInPath.locationId}/relationships/users`,
    { pathParams: { locationId }, ...props },
  );

export interface AddLocationUsersPathParams {
  locationId: Uuid;
}

export type AddLocationUsersProps = Omit<
  MutateProps<ResponseBase, unknown, void, RequestModifyLocationUsers, AddLocationUsersPathParams>,
  'path' | 'verb'
> &
  AddLocationUsersPathParams;

/**
 * Add location users
 *
 * Add users to a location.
 */
export const AddLocationUsers = ({ locationId, ...props }: AddLocationUsersProps) => (
  <Mutate<ResponseBase, unknown, void, RequestModifyLocationUsers, AddLocationUsersPathParams>
    verb="POST"
    path={`/locations/${locationId}/relationships/users`}
    {...props}
  />
);

export type UseAddLocationUsersProps = Omit<
  UseMutateProps<ResponseBase, unknown, void, RequestModifyLocationUsers, AddLocationUsersPathParams>,
  'path' | 'verb'
> &
  AddLocationUsersPathParams;

/**
 * Add location users
 *
 * Add users to a location.
 */
export const useAddLocationUsers = ({ locationId, ...props }: UseAddLocationUsersProps) =>
  useMutate<ResponseBase, unknown, void, RequestModifyLocationUsers, AddLocationUsersPathParams>(
    'POST',
    (paramsInPath: AddLocationUsersPathParams) => `/locations/${paramsInPath.locationId}/relationships/users`,
    { pathParams: { locationId }, ...props },
  );

export type StepUpProps = Omit<GetProps<ResponseStepUp, unknown, void, void>, 'path'>;

/**
 * Get a resource protected by step-up authorization
 *
 * Test that step-up authorization works
 */
export const StepUp = (props: StepUpProps) => (
  <Get<ResponseStepUp, unknown, void, void> path={`/mfa/step-up`} {...props} />
);

export type UseStepUpProps = Omit<UseGetProps<ResponseStepUp, unknown, void, void>, 'path'>;

/**
 * Get a resource protected by step-up authorization
 *
 * Test that step-up authorization works
 */
export const useStepUp = (props: UseStepUpProps) => useGet<ResponseStepUp, unknown, void, void>(`/mfa/step-up`, props);

export type ChallengeResponseProps = Omit<MutateProps<void, unknown, void, RequestMFAChallenge, void>, 'path' | 'verb'>;

export const ChallengeResponse = (props: ChallengeResponseProps) => (
  <Mutate<void, unknown, void, RequestMFAChallenge, void> verb="POST" path={`/mfa/challenge-response`} {...props} />
);

export type UseChallengeResponseProps = Omit<
  UseMutateProps<void, unknown, void, RequestMFAChallenge, void>,
  'path' | 'verb'
>;

export const useChallengeResponse = (props: UseChallengeResponseProps) =>
  useMutate<void, unknown, void, RequestMFAChallenge, void>('POST', `/mfa/challenge-response`, props);

export type ListOrganisationsProps = Omit<GetProps<ResponseListOrganisations, unknown, void, void>, 'path'>;

/**
 * List organisations
 *
 * List all organisations in Evnex.
 */
export const ListOrganisations = (props: ListOrganisationsProps) => (
  <Get<ResponseListOrganisations, unknown, void, void> path={`/v2/apps/organisations`} {...props} />
);

export type UseListOrganisationsProps = Omit<UseGetProps<ResponseListOrganisations, unknown, void, void>, 'path'>;

/**
 * List organisations
 *
 * List all organisations in Evnex.
 */
export const useListOrganisations = (props: UseListOrganisationsProps) =>
  useGet<ResponseListOrganisations, unknown, void, void>(`/v2/apps/organisations`, props);

export type CreateOrganisationProps = Omit<
  MutateProps<ResponseCreateOrganisation, unknown, void, RequestCreateOrganisation, void>,
  'path' | 'verb'
>;

/**
 * Create organisation
 *
 * Create a new organisation. The requesting user will be set as the organisation's admin.
 */
export const CreateOrganisation = (props: CreateOrganisationProps) => (
  <Mutate<ResponseCreateOrganisation, unknown, void, RequestCreateOrganisation, void>
    verb="POST"
    path={`/v2/apps/organisations`}
    {...props}
  />
);

export type UseCreateOrganisationProps = Omit<
  UseMutateProps<ResponseCreateOrganisation, unknown, void, RequestCreateOrganisation, void>,
  'path' | 'verb'
>;

/**
 * Create organisation
 *
 * Create a new organisation. The requesting user will be set as the organisation's admin.
 */
export const useCreateOrganisation = (props: UseCreateOrganisationProps) =>
  useMutate<ResponseCreateOrganisation, unknown, void, RequestCreateOrganisation, void>(
    'POST',
    `/v2/apps/organisations`,
    props,
  );

export interface GetOrganisationPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type GetOrganisationProps = Omit<
  GetProps<ResponseGetOrganisation, ResponseApiErrors, void, GetOrganisationPathParams>,
  'path'
> &
  GetOrganisationPathParams;

/**
 * Get organisation
 *
 * Return the requested organisation.
 */
export const GetOrganisation = ({ orgSlug, ...props }: GetOrganisationProps) => (
  <Get<ResponseGetOrganisation, ResponseApiErrors, void, GetOrganisationPathParams>
    path={`/v2/apps/organisations/${orgSlug}`}
    {...props}
  />
);

export type UseGetOrganisationProps = Omit<
  UseGetProps<ResponseGetOrganisation, ResponseApiErrors, void, GetOrganisationPathParams>,
  'path'
> &
  GetOrganisationPathParams;

/**
 * Get organisation
 *
 * Return the requested organisation.
 */
export const useGetOrganisation = ({ orgSlug, ...props }: UseGetOrganisationProps) =>
  useGet<ResponseGetOrganisation, ResponseApiErrors, void, GetOrganisationPathParams>(
    (paramsInPath: GetOrganisationPathParams) => `/v2/apps/organisations/${paramsInPath.orgSlug}`,
    { pathParams: { orgSlug }, ...props },
  );

export interface UpdateOrganisationPathParams {
  /**
   * Id of an organisation
   */
  orgId: string;
}

export type UpdateOrganisationProps = Omit<
  MutateProps<
    ResponseUpdateOrganisation,
    ResponseApiErrors,
    void,
    RequestUpdateOrganisation,
    UpdateOrganisationPathParams
  >,
  'path' | 'verb'
> &
  UpdateOrganisationPathParams;

/**
 * Update organisation attributes
 *
 * Update an organisation's details.
 */
export const UpdateOrganisation = ({ orgId, ...props }: UpdateOrganisationProps) => (
  <Mutate<ResponseUpdateOrganisation, ResponseApiErrors, void, RequestUpdateOrganisation, UpdateOrganisationPathParams>
    verb="PATCH"
    path={`/v2/apps/organisations/${orgId}`}
    {...props}
  />
);

export type UseUpdateOrganisationProps = Omit<
  UseMutateProps<
    ResponseUpdateOrganisation,
    ResponseApiErrors,
    void,
    RequestUpdateOrganisation,
    UpdateOrganisationPathParams
  >,
  'path' | 'verb'
> &
  UpdateOrganisationPathParams;

/**
 * Update organisation attributes
 *
 * Update an organisation's details.
 */
export const useUpdateOrganisation = ({ orgId, ...props }: UseUpdateOrganisationProps) =>
  useMutate<
    ResponseUpdateOrganisation,
    ResponseApiErrors,
    void,
    RequestUpdateOrganisation,
    UpdateOrganisationPathParams
  >('PATCH', (paramsInPath: UpdateOrganisationPathParams) => `/v2/apps/organisations/${paramsInPath.orgId}`, {
    pathParams: { orgId },
    ...props,
  });

export interface LegacyGetOrganisationChargePointsPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type LegacyGetOrganisationChargePointsProps = Omit<
  GetProps<ResponseGetOrganisationChargePoints, ResponseApiErrors, void, LegacyGetOrganisationChargePointsPathParams>,
  'path'
> &
  LegacyGetOrganisationChargePointsPathParams;

/**
 * Get organisation charge points
 *
 * Return the charge points owned by an organisation.
 */
export const LegacyGetOrganisationChargePoints = ({ orgSlug, ...props }: LegacyGetOrganisationChargePointsProps) => (
  <Get<ResponseGetOrganisationChargePoints, ResponseApiErrors, void, LegacyGetOrganisationChargePointsPathParams>
    path={`/v2/apps/organisations/${orgSlug}/charge-points`}
    {...props}
  />
);

export type UseLegacyGetOrganisationChargePointsProps = Omit<
  UseGetProps<
    ResponseGetOrganisationChargePoints,
    ResponseApiErrors,
    void,
    LegacyGetOrganisationChargePointsPathParams
  >,
  'path'
> &
  LegacyGetOrganisationChargePointsPathParams;

/**
 * Get organisation charge points
 *
 * Return the charge points owned by an organisation.
 */
export const useLegacyGetOrganisationChargePoints = ({
  orgSlug,
  ...props
}: UseLegacyGetOrganisationChargePointsProps) =>
  useGet<ResponseGetOrganisationChargePoints, ResponseApiErrors, void, LegacyGetOrganisationChargePointsPathParams>(
    (paramsInPath: LegacyGetOrganisationChargePointsPathParams) =>
      `/v2/apps/organisations/${paramsInPath.orgSlug}/charge-points`,
    { pathParams: { orgSlug }, ...props },
  );

export interface GetOrganisationLocationsPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type GetOrganisationLocationsProps = Omit<
  GetProps<ResponseGetOrganisationLocations, ResponseApiErrors, void, GetOrganisationLocationsPathParams>,
  'path'
> &
  GetOrganisationLocationsPathParams;

/**
 * List organisation locations
 *
 * Return the locations owned by an organisation.
 */
export const GetOrganisationLocations = ({ orgSlug, ...props }: GetOrganisationLocationsProps) => (
  <Get<ResponseGetOrganisationLocations, ResponseApiErrors, void, GetOrganisationLocationsPathParams>
    path={`/v2/apps/organisations/${orgSlug}/locations`}
    {...props}
  />
);

export type UseGetOrganisationLocationsProps = Omit<
  UseGetProps<ResponseGetOrganisationLocations, ResponseApiErrors, void, GetOrganisationLocationsPathParams>,
  'path'
> &
  GetOrganisationLocationsPathParams;

/**
 * List organisation locations
 *
 * Return the locations owned by an organisation.
 */
export const useGetOrganisationLocations = ({ orgSlug, ...props }: UseGetOrganisationLocationsProps) =>
  useGet<ResponseGetOrganisationLocations, ResponseApiErrors, void, GetOrganisationLocationsPathParams>(
    (paramsInPath: GetOrganisationLocationsPathParams) => `/v2/apps/organisations/${paramsInPath.orgSlug}/locations`,
    { pathParams: { orgSlug }, ...props },
  );

export interface CreateLocationPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type CreateLocationProps = Omit<
  MutateProps<
    ResponseCreateLocation,
    ResponseApiErrors,
    void,
    RequestCreateOrganisationLocation,
    CreateLocationPathParams
  >,
  'path' | 'verb'
> &
  CreateLocationPathParams;

/**
 * Create location
 *
 * Create a new location in the organisation.
 */
export const CreateLocation = ({ orgSlug, ...props }: CreateLocationProps) => (
  <Mutate<ResponseCreateLocation, ResponseApiErrors, void, RequestCreateOrganisationLocation, CreateLocationPathParams>
    verb="POST"
    path={`/v2/apps/organisations/${orgSlug}/locations`}
    {...props}
  />
);

export type UseCreateLocationProps = Omit<
  UseMutateProps<
    ResponseCreateLocation,
    ResponseApiErrors,
    void,
    RequestCreateOrganisationLocation,
    CreateLocationPathParams
  >,
  'path' | 'verb'
> &
  CreateLocationPathParams;

/**
 * Create location
 *
 * Create a new location in the organisation.
 */
export const useCreateLocation = ({ orgSlug, ...props }: UseCreateLocationProps) =>
  useMutate<
    ResponseCreateLocation,
    ResponseApiErrors,
    void,
    RequestCreateOrganisationLocation,
    CreateLocationPathParams
  >('POST', (paramsInPath: CreateLocationPathParams) => `/v2/apps/organisations/${paramsInPath.orgSlug}/locations`, {
    pathParams: { orgSlug },
    ...props,
  });

export interface GetOrganisationClientCredentialsPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type GetOrganisationClientCredentialsProps = Omit<
  GetProps<
    ResponseGetOrganisationClientCredentials,
    ResponseApiErrors,
    void,
    GetOrganisationClientCredentialsPathParams
  >,
  'path'
> &
  GetOrganisationClientCredentialsPathParams;

/**
 * Get organisation client credentials
 *
 * Return the organisation's API client credentials.
 */
export const GetOrganisationClientCredentials = ({ orgSlug, ...props }: GetOrganisationClientCredentialsProps) => (
  <Get<ResponseGetOrganisationClientCredentials, ResponseApiErrors, void, GetOrganisationClientCredentialsPathParams>
    path={`/v2/apps/organisations/${orgSlug}/client-credentials`}
    {...props}
  />
);

export type UseGetOrganisationClientCredentialsProps = Omit<
  UseGetProps<
    ResponseGetOrganisationClientCredentials,
    ResponseApiErrors,
    void,
    GetOrganisationClientCredentialsPathParams
  >,
  'path'
> &
  GetOrganisationClientCredentialsPathParams;

/**
 * Get organisation client credentials
 *
 * Return the organisation's API client credentials.
 */
export const useGetOrganisationClientCredentials = ({ orgSlug, ...props }: UseGetOrganisationClientCredentialsProps) =>
  useGet<ResponseGetOrganisationClientCredentials, ResponseApiErrors, void, GetOrganisationClientCredentialsPathParams>(
    (paramsInPath: GetOrganisationClientCredentialsPathParams) =>
      `/v2/apps/organisations/${paramsInPath.orgSlug}/client-credentials`,
    { pathParams: { orgSlug }, ...props },
  );

export interface GetOrganisationLegacyInsightsSummaryQueryParams {
  /**
   * Number of days in the past
   */
  days?: Days;
  /**
   * Time zone offset (h)
   */
  'tz-offset'?: number;
}

export interface GetOrganisationLegacyInsightsSummaryPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type GetOrganisationLegacyInsightsSummaryProps = Omit<
  GetProps<
    ResponseGetOrganisationSummaryInsightsLegacy,
    ResponseApiErrors,
    GetOrganisationLegacyInsightsSummaryQueryParams,
    GetOrganisationLegacyInsightsSummaryPathParams
  >,
  'path'
> &
  GetOrganisationLegacyInsightsSummaryPathParams;

/**
 * Get organisation insights summary
 *
 * Return a summary of the organisation's recent EV charging statistics.
 */
export const GetOrganisationLegacyInsightsSummary = ({
  orgSlug,
  ...props
}: GetOrganisationLegacyInsightsSummaryProps) => (
  <Get<
    ResponseGetOrganisationSummaryInsightsLegacy,
    ResponseApiErrors,
    GetOrganisationLegacyInsightsSummaryQueryParams,
    GetOrganisationLegacyInsightsSummaryPathParams
  >
    path={`/v2/apps/organisations/${orgSlug}/summary/insights`}
    {...props}
  />
);

export type UseGetOrganisationLegacyInsightsSummaryProps = Omit<
  UseGetProps<
    ResponseGetOrganisationSummaryInsightsLegacy,
    ResponseApiErrors,
    GetOrganisationLegacyInsightsSummaryQueryParams,
    GetOrganisationLegacyInsightsSummaryPathParams
  >,
  'path'
> &
  GetOrganisationLegacyInsightsSummaryPathParams;

/**
 * Get organisation insights summary
 *
 * Return a summary of the organisation's recent EV charging statistics.
 */
export const useGetOrganisationLegacyInsightsSummary = ({
  orgSlug,
  ...props
}: UseGetOrganisationLegacyInsightsSummaryProps) =>
  useGet<
    ResponseGetOrganisationSummaryInsightsLegacy,
    ResponseApiErrors,
    GetOrganisationLegacyInsightsSummaryQueryParams,
    GetOrganisationLegacyInsightsSummaryPathParams
  >(
    (paramsInPath: GetOrganisationLegacyInsightsSummaryPathParams) =>
      `/v2/apps/organisations/${paramsInPath.orgSlug}/summary/insights`,
    { pathParams: { orgSlug }, ...props },
  );

export interface GetOrganisationStatusSummaryPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type GetOrganisationStatusSummaryProps = Omit<
  GetProps<ResponseGetOrganisationSummaryStatus, ResponseApiErrors, void, GetOrganisationStatusSummaryPathParams>,
  'path'
> &
  GetOrganisationStatusSummaryPathParams;

/**
 * Get organisation status summary
 *
 * Return a summary of all charge point statuses for an organisation.
 */
export const GetOrganisationStatusSummary = ({ orgSlug, ...props }: GetOrganisationStatusSummaryProps) => (
  <Get<ResponseGetOrganisationSummaryStatus, ResponseApiErrors, void, GetOrganisationStatusSummaryPathParams>
    path={`/v2/apps/organisations/${orgSlug}/summary/status`}
    {...props}
  />
);

export type UseGetOrganisationStatusSummaryProps = Omit<
  UseGetProps<ResponseGetOrganisationSummaryStatus, ResponseApiErrors, void, GetOrganisationStatusSummaryPathParams>,
  'path'
> &
  GetOrganisationStatusSummaryPathParams;

/**
 * Get organisation status summary
 *
 * Return a summary of all charge point statuses for an organisation.
 */
export const useGetOrganisationStatusSummary = ({ orgSlug, ...props }: UseGetOrganisationStatusSummaryProps) =>
  useGet<ResponseGetOrganisationSummaryStatus, ResponseApiErrors, void, GetOrganisationStatusSummaryPathParams>(
    (paramsInPath: GetOrganisationStatusSummaryPathParams) =>
      `/v2/apps/organisations/${paramsInPath.orgSlug}/summary/status`,
    { pathParams: { orgSlug }, ...props },
  );

export interface GetOrganisationTokensPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type GetOrganisationTokensProps = Omit<
  GetProps<ResponseGetOrganisationTokens, ResponseApiErrors, void, GetOrganisationTokensPathParams>,
  'path'
> &
  GetOrganisationTokensPathParams;

/**
 * List organisation tokens
 *
 * Return the tokens owned by an organisation.
 */
export const GetOrganisationTokens = ({ orgSlug, ...props }: GetOrganisationTokensProps) => (
  <Get<ResponseGetOrganisationTokens, ResponseApiErrors, void, GetOrganisationTokensPathParams>
    path={`/v2/apps/organisations/${orgSlug}/tokens`}
    {...props}
  />
);

export type UseGetOrganisationTokensProps = Omit<
  UseGetProps<ResponseGetOrganisationTokens, ResponseApiErrors, void, GetOrganisationTokensPathParams>,
  'path'
> &
  GetOrganisationTokensPathParams;

/**
 * List organisation tokens
 *
 * Return the tokens owned by an organisation.
 */
export const useGetOrganisationTokens = ({ orgSlug, ...props }: UseGetOrganisationTokensProps) =>
  useGet<ResponseGetOrganisationTokens, ResponseApiErrors, void, GetOrganisationTokensPathParams>(
    (paramsInPath: GetOrganisationTokensPathParams) => `/v2/apps/organisations/${paramsInPath.orgSlug}/tokens`,
    { pathParams: { orgSlug }, ...props },
  );

export interface CreateTokenPathParams {
  orgSlug: Slug;
}

export type CreateTokenProps = Omit<
  MutateProps<ResponsePostToken, ResponseApiErrors, void, RequestCreateToken, CreateTokenPathParams>,
  'path' | 'verb'
> &
  CreateTokenPathParams;

/**
 * Create token
 *
 * Create a new token in an organisation.
 */
export const CreateToken = ({ orgSlug, ...props }: CreateTokenProps) => (
  <Mutate<ResponsePostToken, ResponseApiErrors, void, RequestCreateToken, CreateTokenPathParams>
    verb="POST"
    path={`/v2/apps/organisations/${orgSlug}/tokens`}
    {...props}
  />
);

export type UseCreateTokenProps = Omit<
  UseMutateProps<ResponsePostToken, ResponseApiErrors, void, RequestCreateToken, CreateTokenPathParams>,
  'path' | 'verb'
> &
  CreateTokenPathParams;

/**
 * Create token
 *
 * Create a new token in an organisation.
 */
export const useCreateToken = ({ orgSlug, ...props }: UseCreateTokenProps) =>
  useMutate<ResponsePostToken, ResponseApiErrors, void, RequestCreateToken, CreateTokenPathParams>(
    'POST',
    (paramsInPath: CreateTokenPathParams) => `/v2/apps/organisations/${paramsInPath.orgSlug}/tokens`,
    { pathParams: { orgSlug }, ...props },
  );

export interface CreateOrganisationUserPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type CreateOrganisationUserProps = Omit<
  MutateProps<ResponsePostInvitation, unknown, void, RequestInviteDriver, CreateOrganisationUserPathParams>,
  'path' | 'verb'
> &
  CreateOrganisationUserPathParams;

/**
 * Create and invite user
 *
 * Create a new user and invite them to the organisation as a Driver.
 */
export const CreateOrganisationUser = ({ orgSlug, ...props }: CreateOrganisationUserProps) => (
  <Mutate<ResponsePostInvitation, unknown, void, RequestInviteDriver, CreateOrganisationUserPathParams>
    verb="POST"
    path={`/v2/apps/organisations/${orgSlug}/users`}
    {...props}
  />
);

export type UseCreateOrganisationUserProps = Omit<
  UseMutateProps<ResponsePostInvitation, unknown, void, RequestInviteDriver, CreateOrganisationUserPathParams>,
  'path' | 'verb'
> &
  CreateOrganisationUserPathParams;

/**
 * Create and invite user
 *
 * Create a new user and invite them to the organisation as a Driver.
 */
export const useCreateOrganisationUser = ({ orgSlug, ...props }: UseCreateOrganisationUserProps) =>
  useMutate<ResponsePostInvitation, unknown, void, RequestInviteDriver, CreateOrganisationUserPathParams>(
    'POST',
    (paramsInPath: CreateOrganisationUserPathParams) => `/v2/apps/organisations/${paramsInPath.orgSlug}/users`,
    { pathParams: { orgSlug }, ...props },
  );

export interface ListOrganisationMembersPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type ListOrganisationMembersProps = Omit<
  GetProps<ResponseListMemberships, unknown, void, ListOrganisationMembersPathParams>,
  'path'
> &
  ListOrganisationMembersPathParams;

/**
 * List organisation memberships
 *
 * List user memberships in an organisation.
 */
export const ListOrganisationMembers = ({ orgSlug, ...props }: ListOrganisationMembersProps) => (
  <Get<ResponseListMemberships, unknown, void, ListOrganisationMembersPathParams>
    path={`/v2/apps/organisations/${orgSlug}/memberships`}
    {...props}
  />
);

export type UseListOrganisationMembersProps = Omit<
  UseGetProps<ResponseListMemberships, unknown, void, ListOrganisationMembersPathParams>,
  'path'
> &
  ListOrganisationMembersPathParams;

/**
 * List organisation memberships
 *
 * List user memberships in an organisation.
 */
export const useListOrganisationMembers = ({ orgSlug, ...props }: UseListOrganisationMembersProps) =>
  useGet<ResponseListMemberships, unknown, void, ListOrganisationMembersPathParams>(
    (paramsInPath: ListOrganisationMembersPathParams) => `/v2/apps/organisations/${paramsInPath.orgSlug}/memberships`,
    { pathParams: { orgSlug }, ...props },
  );

export interface CreateOrganisationMembershipPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type CreateOrganisationMembershipProps = Omit<
  MutateProps<
    ResponseMembership,
    ResponseApiErrors,
    void,
    RequestCreateMembership,
    CreateOrganisationMembershipPathParams
  >,
  'path' | 'verb'
> &
  CreateOrganisationMembershipPathParams;

/**
 * Create organisation membership
 *
 * Create or replace a user's membership in an organisation.
 */
export const CreateOrganisationMembership = ({ orgSlug, ...props }: CreateOrganisationMembershipProps) => (
  <Mutate<ResponseMembership, ResponseApiErrors, void, RequestCreateMembership, CreateOrganisationMembershipPathParams>
    verb="POST"
    path={`/v2/apps/organisations/${orgSlug}/memberships`}
    {...props}
  />
);

export type UseCreateOrganisationMembershipProps = Omit<
  UseMutateProps<
    ResponseMembership,
    ResponseApiErrors,
    void,
    RequestCreateMembership,
    CreateOrganisationMembershipPathParams
  >,
  'path' | 'verb'
> &
  CreateOrganisationMembershipPathParams;

/**
 * Create organisation membership
 *
 * Create or replace a user's membership in an organisation.
 */
export const useCreateOrganisationMembership = ({ orgSlug, ...props }: UseCreateOrganisationMembershipProps) =>
  useMutate<
    ResponseMembership,
    ResponseApiErrors,
    void,
    RequestCreateMembership,
    CreateOrganisationMembershipPathParams
  >(
    'POST',
    (paramsInPath: CreateOrganisationMembershipPathParams) =>
      `/v2/apps/organisations/${paramsInPath.orgSlug}/memberships`,
    { pathParams: { orgSlug }, ...props },
  );

export interface GetOrganisationMembershipPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
  /**
   * UUID of a user whose membership to get
   */
  userId: Uuid;
}

export type GetOrganisationMembershipProps = Omit<
  GetProps<ResponseGetMembership, ResponseApiErrors, void, GetOrganisationMembershipPathParams>,
  'path'
> &
  GetOrganisationMembershipPathParams;

/**
 * Get organisation membership
 *
 * Get details of a user's membership in an organisation.
 */
export const GetOrganisationMembership = ({ orgSlug, userId, ...props }: GetOrganisationMembershipProps) => (
  <Get<ResponseGetMembership, ResponseApiErrors, void, GetOrganisationMembershipPathParams>
    path={`/v2/apps/organisations/${orgSlug}/memberships/${userId}`}
    {...props}
  />
);

export type UseGetOrganisationMembershipProps = Omit<
  UseGetProps<ResponseGetMembership, ResponseApiErrors, void, GetOrganisationMembershipPathParams>,
  'path'
> &
  GetOrganisationMembershipPathParams;

/**
 * Get organisation membership
 *
 * Get details of a user's membership in an organisation.
 */
export const useGetOrganisationMembership = ({ orgSlug, userId, ...props }: UseGetOrganisationMembershipProps) =>
  useGet<ResponseGetMembership, ResponseApiErrors, void, GetOrganisationMembershipPathParams>(
    (paramsInPath: GetOrganisationMembershipPathParams) =>
      `/v2/apps/organisations/${paramsInPath.orgSlug}/memberships/${paramsInPath.userId}`,
    { pathParams: { orgSlug, userId }, ...props },
  );

export interface UpdateOrganisationMembershipPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
  /**
   * UUID of the user with membership in the organisation
   */
  userId: Uuid;
}

export type UpdateOrganisationMembershipProps = Omit<
  MutateProps<
    ResponseMembership,
    ResponseApiErrors,
    void,
    RequestUpdateMembership,
    UpdateOrganisationMembershipPathParams
  >,
  'path' | 'verb'
> &
  UpdateOrganisationMembershipPathParams;

/**
 * Update organisation membership
 *
 * Update a user's membership in an organisation.
 */
export const UpdateOrganisationMembership = ({ orgSlug, userId, ...props }: UpdateOrganisationMembershipProps) => (
  <Mutate<ResponseMembership, ResponseApiErrors, void, RequestUpdateMembership, UpdateOrganisationMembershipPathParams>
    verb="PATCH"
    path={`/v2/apps/organisations/${orgSlug}/memberships/${userId}`}
    {...props}
  />
);

export type UseUpdateOrganisationMembershipProps = Omit<
  UseMutateProps<
    ResponseMembership,
    ResponseApiErrors,
    void,
    RequestUpdateMembership,
    UpdateOrganisationMembershipPathParams
  >,
  'path' | 'verb'
> &
  UpdateOrganisationMembershipPathParams;

/**
 * Update organisation membership
 *
 * Update a user's membership in an organisation.
 */
export const useUpdateOrganisationMembership = ({ orgSlug, userId, ...props }: UseUpdateOrganisationMembershipProps) =>
  useMutate<
    ResponseMembership,
    ResponseApiErrors,
    void,
    RequestUpdateMembership,
    UpdateOrganisationMembershipPathParams
  >(
    'PATCH',
    (paramsInPath: UpdateOrganisationMembershipPathParams) =>
      `/v2/apps/organisations/${paramsInPath.orgSlug}/memberships/${paramsInPath.userId}`,
    { pathParams: { orgSlug, userId }, ...props },
  );

export interface DeleteOrganisationMembershipsPathParams {
  /**
   * Slug of an organisation
   */
  orgSlug: Slug;
}

export type DeleteOrganisationMembershipsProps = Omit<
  MutateProps<ResponseBase, unknown, void, RequestDeleteMemberships, DeleteOrganisationMembershipsPathParams>,
  'path' | 'verb'
> &
  DeleteOrganisationMembershipsPathParams;

/**
 * Delete organisation memberships
 *
 * Delete memberships from an organisation.
 */
export const DeleteOrganisationMemberships = ({ orgSlug, ...props }: DeleteOrganisationMembershipsProps) => (
  <Mutate<ResponseBase, unknown, void, RequestDeleteMemberships, DeleteOrganisationMembershipsPathParams>
    verb="DELETE"
    path={`/v2/apps/organisations/${orgSlug}/relationships/memberships`}
    {...props}
  />
);

export type UseDeleteOrganisationMembershipsProps = Omit<
  UseMutateProps<ResponseBase, unknown, void, RequestDeleteMemberships, DeleteOrganisationMembershipsPathParams>,
  'path' | 'verb'
> &
  DeleteOrganisationMembershipsPathParams;

/**
 * Delete organisation memberships
 *
 * Delete memberships from an organisation.
 */
export const useDeleteOrganisationMemberships = ({ orgSlug, ...props }: UseDeleteOrganisationMembershipsProps) =>
  useMutate<ResponseBase, unknown, void, RequestDeleteMemberships, DeleteOrganisationMembershipsPathParams>(
    'DELETE',
    (paramsInPath: DeleteOrganisationMembershipsPathParams) =>
      `/v2/apps/organisations/${paramsInPath.orgSlug}/relationships/memberships`,
    { pathParams: { orgSlug }, ...props },
  );

export interface GetOrganisationInsightsSummaryQueryParams {
  /**
   * Number of days in the past
   */
  days?: Days;
  /**
   * Time zone offset (h)
   */
  'tz-offset'?: number;
}

export interface GetOrganisationInsightsSummaryPathParams {
  /**
   * ID of an organisation
   */
  id: Uuid;
}

export type GetOrganisationInsightsSummaryProps = Omit<
  GetProps<
    ResponseGetOrganisationSummaryInsights,
    ResponseApiErrors,
    GetOrganisationInsightsSummaryQueryParams,
    GetOrganisationInsightsSummaryPathParams
  >,
  'path'
> &
  GetOrganisationInsightsSummaryPathParams;

/**
 * Get organisation insights summary
 *
 * Return a summary of the organisation's recent EV charging statistics.
 */
export const GetOrganisationInsightsSummary = ({ id, ...props }: GetOrganisationInsightsSummaryProps) => (
  <Get<
    ResponseGetOrganisationSummaryInsights,
    ResponseApiErrors,
    GetOrganisationInsightsSummaryQueryParams,
    GetOrganisationInsightsSummaryPathParams
  >
    path={`/organisations/${id}/summary/insights`}
    {...props}
  />
);

export type UseGetOrganisationInsightsSummaryProps = Omit<
  UseGetProps<
    ResponseGetOrganisationSummaryInsights,
    ResponseApiErrors,
    GetOrganisationInsightsSummaryQueryParams,
    GetOrganisationInsightsSummaryPathParams
  >,
  'path'
> &
  GetOrganisationInsightsSummaryPathParams;

/**
 * Get organisation insights summary
 *
 * Return a summary of the organisation's recent EV charging statistics.
 */
export const useGetOrganisationInsightsSummary = ({ id, ...props }: UseGetOrganisationInsightsSummaryProps) =>
  useGet<
    ResponseGetOrganisationSummaryInsights,
    ResponseApiErrors,
    GetOrganisationInsightsSummaryQueryParams,
    GetOrganisationInsightsSummaryPathParams
  >((paramsInPath: GetOrganisationInsightsSummaryPathParams) => `/organisations/${paramsInPath.id}/summary/insights`, {
    pathParams: { id },
    ...props,
  });

export interface ListOrganisationSessionsQueryParams {
  /**
   * Date to return the sessions from
   */
  from: string;
  /**
   * Date to return the sessions to
   */
  to?: string;
}

export interface ListOrganisationSessionsPathParams {
  /**
   * Organisation ID
   */
  organisationId: Uuid;
}

export type ListOrganisationSessionsProps = Omit<
  GetProps<
    ResponseListOrganisationSessions,
    unknown,
    ListOrganisationSessionsQueryParams,
    ListOrganisationSessionsPathParams
  >,
  'path'
> &
  ListOrganisationSessionsPathParams;

/**
 * List organisations sessions
 *
 * Return all completed sessions for an organisation.
 */
export const ListOrganisationSessions = ({ organisationId, ...props }: ListOrganisationSessionsProps) => (
  <Get<
    ResponseListOrganisationSessions,
    unknown,
    ListOrganisationSessionsQueryParams,
    ListOrganisationSessionsPathParams
  >
    path={`/organisations/${organisationId}/sessions`}
    {...props}
  />
);

export type UseListOrganisationSessionsProps = Omit<
  UseGetProps<
    ResponseListOrganisationSessions,
    unknown,
    ListOrganisationSessionsQueryParams,
    ListOrganisationSessionsPathParams
  >,
  'path'
> &
  ListOrganisationSessionsPathParams;

/**
 * List organisations sessions
 *
 * Return all completed sessions for an organisation.
 */
export const useListOrganisationSessions = ({ organisationId, ...props }: UseListOrganisationSessionsProps) =>
  useGet<
    ResponseListOrganisationSessions,
    unknown,
    ListOrganisationSessionsQueryParams,
    ListOrganisationSessionsPathParams
  >((paramsInPath: ListOrganisationSessionsPathParams) => `/organisations/${paramsInPath.organisationId}/sessions`, {
    pathParams: { organisationId },
    ...props,
  });

export interface ListOrganisationChargePointsPathParams {
  orgId: Uuid;
}

export type ListOrganisationChargePointsProps = Omit<
  GetProps<ResponseListOrganisationChargePoints, ResponseApiErrors, void, ListOrganisationChargePointsPathParams>,
  'path'
> &
  ListOrganisationChargePointsPathParams;

/**
 * List organisation charge points
 *
 * List all charge points in the organisation, along with their locations.
 */
export const ListOrganisationChargePoints = ({ orgId, ...props }: ListOrganisationChargePointsProps) => (
  <Get<ResponseListOrganisationChargePoints, ResponseApiErrors, void, ListOrganisationChargePointsPathParams>
    path={`/organisations/${orgId}/charge-points`}
    {...props}
  />
);

export type UseListOrganisationChargePointsProps = Omit<
  UseGetProps<ResponseListOrganisationChargePoints, ResponseApiErrors, void, ListOrganisationChargePointsPathParams>,
  'path'
> &
  ListOrganisationChargePointsPathParams;

/**
 * List organisation charge points
 *
 * List all charge points in the organisation, along with their locations.
 */
export const useListOrganisationChargePoints = ({ orgId, ...props }: UseListOrganisationChargePointsProps) =>
  useGet<ResponseListOrganisationChargePoints, ResponseApiErrors, void, ListOrganisationChargePointsPathParams>(
    (paramsInPath: ListOrganisationChargePointsPathParams) => `/organisations/${paramsInPath.orgId}/charge-points`,
    { pathParams: { orgId }, ...props },
  );

export interface CreateThirdPartyChargePointPathParams {
  orgId: string;
}

export type CreateThirdPartyChargePointProps = Omit<
  MutateProps<
    ResponseCreateThirdPartyChargePoint,
    unknown,
    void,
    RequestCreateThirdPartyChargePoint,
    CreateThirdPartyChargePointPathParams
  >,
  'path' | 'verb'
> &
  CreateThirdPartyChargePointPathParams;

export const CreateThirdPartyChargePoint = ({ orgId, ...props }: CreateThirdPartyChargePointProps) => (
  <Mutate<
    ResponseCreateThirdPartyChargePoint,
    unknown,
    void,
    RequestCreateThirdPartyChargePoint,
    CreateThirdPartyChargePointPathParams
  >
    verb="POST"
    path={`/organisations/${orgId}/charge-points`}
    {...props}
  />
);

export type UseCreateThirdPartyChargePointProps = Omit<
  UseMutateProps<
    ResponseCreateThirdPartyChargePoint,
    unknown,
    void,
    RequestCreateThirdPartyChargePoint,
    CreateThirdPartyChargePointPathParams
  >,
  'path' | 'verb'
> &
  CreateThirdPartyChargePointPathParams;

export const useCreateThirdPartyChargePoint = ({ orgId, ...props }: UseCreateThirdPartyChargePointProps) =>
  useMutate<
    ResponseCreateThirdPartyChargePoint,
    unknown,
    void,
    RequestCreateThirdPartyChargePoint,
    CreateThirdPartyChargePointPathParams
  >(
    'POST',
    (paramsInPath: CreateThirdPartyChargePointPathParams) => `/organisations/${paramsInPath.orgId}/charge-points`,
    { pathParams: { orgId }, ...props },
  );

export interface CreateOrganisationLoadBalancingGroupPathParams {
  orgId: Uuid;
}

export type CreateOrganisationLoadBalancingGroupProps = Omit<
  MutateProps<
    ResponseCreateOrganisationLoadBalancingGroup,
    unknown,
    void,
    RequestCreateLoadBalancingGroup,
    CreateOrganisationLoadBalancingGroupPathParams
  >,
  'path' | 'verb'
> &
  CreateOrganisationLoadBalancingGroupPathParams;

/**
 * Create load balancing group
 *
 * Create a new load balancing group in the specified organisation.
 */
export const CreateOrganisationLoadBalancingGroup = ({
  orgId,
  ...props
}: CreateOrganisationLoadBalancingGroupProps) => (
  <Mutate<
    ResponseCreateOrganisationLoadBalancingGroup,
    unknown,
    void,
    RequestCreateLoadBalancingGroup,
    CreateOrganisationLoadBalancingGroupPathParams
  >
    verb="POST"
    path={`/organisations/${orgId}/load-balancing-groups`}
    {...props}
  />
);

export type UseCreateOrganisationLoadBalancingGroupProps = Omit<
  UseMutateProps<
    ResponseCreateOrganisationLoadBalancingGroup,
    unknown,
    void,
    RequestCreateLoadBalancingGroup,
    CreateOrganisationLoadBalancingGroupPathParams
  >,
  'path' | 'verb'
> &
  CreateOrganisationLoadBalancingGroupPathParams;

/**
 * Create load balancing group
 *
 * Create a new load balancing group in the specified organisation.
 */
export const useCreateOrganisationLoadBalancingGroup = ({
  orgId,
  ...props
}: UseCreateOrganisationLoadBalancingGroupProps) =>
  useMutate<
    ResponseCreateOrganisationLoadBalancingGroup,
    unknown,
    void,
    RequestCreateLoadBalancingGroup,
    CreateOrganisationLoadBalancingGroupPathParams
  >(
    'POST',
    (paramsInPath: CreateOrganisationLoadBalancingGroupPathParams) =>
      `/organisations/${paramsInPath.orgId}/load-balancing-groups`,
    { pathParams: { orgId }, ...props },
  );

export interface ListOrganisationLoadBalancingGroupsPathParams {
  orgId: Uuid;
}

export type ListOrganisationLoadBalancingGroupsProps = Omit<
  GetProps<ResponseListOrganisationLoadBalancingGroups, unknown, void, ListOrganisationLoadBalancingGroupsPathParams>,
  'path'
> &
  ListOrganisationLoadBalancingGroupsPathParams;

/**
 * List load balancing groups
 *
 * Return the load balancing groups owned by an organisation.
 */
export const ListOrganisationLoadBalancingGroups = ({ orgId, ...props }: ListOrganisationLoadBalancingGroupsProps) => (
  <Get<ResponseListOrganisationLoadBalancingGroups, unknown, void, ListOrganisationLoadBalancingGroupsPathParams>
    path={`/organisations/${orgId}/load-balancing-groups`}
    {...props}
  />
);

export type UseListOrganisationLoadBalancingGroupsProps = Omit<
  UseGetProps<
    ResponseListOrganisationLoadBalancingGroups,
    unknown,
    void,
    ListOrganisationLoadBalancingGroupsPathParams
  >,
  'path'
> &
  ListOrganisationLoadBalancingGroupsPathParams;

/**
 * List load balancing groups
 *
 * Return the load balancing groups owned by an organisation.
 */
export const useListOrganisationLoadBalancingGroups = ({
  orgId,
  ...props
}: UseListOrganisationLoadBalancingGroupsProps) =>
  useGet<ResponseListOrganisationLoadBalancingGroups, unknown, void, ListOrganisationLoadBalancingGroupsPathParams>(
    (paramsInPath: ListOrganisationLoadBalancingGroupsPathParams) =>
      `/organisations/${paramsInPath.orgId}/load-balancing-groups`,
    { pathParams: { orgId }, ...props },
  );

export interface GetDriverClaimAuthorizationQueryParams {
  serial: UrlEncodedString;
}

export type GetDriverClaimAuthorizationProps = Omit<
  GetProps<ResponseCommandAuthorizeDriverClaim, ResponseApiErrors, GetDriverClaimAuthorizationQueryParams, void>,
  'path'
>;

/**
 * Returns the identifier for the scanned charge point if the following conditions are true:
 *    - Serial number is that of a valid charge point
 *    - Charge point has been commissioned in a residential organisation
 *    - Charge point's location has no user relationships
 */
export const GetDriverClaimAuthorization = (props: GetDriverClaimAuthorizationProps) => (
  <Get<ResponseCommandAuthorizeDriverClaim, ResponseApiErrors, GetDriverClaimAuthorizationQueryParams, void>
    path={`/commands/driver-claim`}
    {...props}
  />
);

export type UseGetDriverClaimAuthorizationProps = Omit<
  UseGetProps<ResponseCommandAuthorizeDriverClaim, ResponseApiErrors, GetDriverClaimAuthorizationQueryParams, void>,
  'path'
>;

/**
 * Returns the identifier for the scanned charge point if the following conditions are true:
 *    - Serial number is that of a valid charge point
 *    - Charge point has been commissioned in a residential organisation
 *    - Charge point's location has no user relationships
 */
export const useGetDriverClaimAuthorization = (props: UseGetDriverClaimAuthorizationProps) =>
  useGet<ResponseCommandAuthorizeDriverClaim, ResponseApiErrors, GetDriverClaimAuthorizationQueryParams, void>(
    `/commands/driver-claim`,
    props,
  );

export type CommandDriverClaimBySerialProps = Omit<
  MutateProps<void, ResponseApiErrors, void, RequestCommandDriverClaimBySerial, void>,
  'path' | 'verb'
>;

/**
 * Claim charge point for driver
 *
 * Prerequisites:
 *    - Serial number is that of a valid charge point
 *    - Charge point has been commissioned in a residential organisation
 *    - Charge point's location has no user relationships
 *    - Charge point's location has ICP
 *
 * Resulting effects if prerequisites are met:
 *    - Add user to the charge point's organisation with the Driver role, if they aren't already a member
 *    - Add the requesting user to the charge point's location, if their role in the organisation is Driver
 *    - Rename the charge point and location based on requesting user's name
 *    - Enable the charge point for use
 */
export const CommandDriverClaimBySerial = (props: CommandDriverClaimBySerialProps) => (
  <Mutate<void, ResponseApiErrors, void, RequestCommandDriverClaimBySerial, void>
    verb="POST"
    path={`/commands/driver-claim`}
    {...props}
  />
);

export type UseCommandDriverClaimBySerialProps = Omit<
  UseMutateProps<void, ResponseApiErrors, void, RequestCommandDriverClaimBySerial, void>,
  'path' | 'verb'
>;

/**
 * Claim charge point for driver
 *
 * Prerequisites:
 *    - Serial number is that of a valid charge point
 *    - Charge point has been commissioned in a residential organisation
 *    - Charge point's location has no user relationships
 *    - Charge point's location has ICP
 *
 * Resulting effects if prerequisites are met:
 *    - Add user to the charge point's organisation with the Driver role, if they aren't already a member
 *    - Add the requesting user to the charge point's location, if their role in the organisation is Driver
 *    - Rename the charge point and location based on requesting user's name
 *    - Enable the charge point for use
 */
export const useCommandDriverClaimBySerial = (props: UseCommandDriverClaimBySerialProps) =>
  useMutate<void, ResponseApiErrors, void, RequestCommandDriverClaimBySerial, void>(
    'POST',
    `/commands/driver-claim`,
    props,
  );

export interface ReportSessionsByChargePointQueryParams {
  from: string;
  to?: string;
}

export interface ReportSessionsByChargePointPathParams {
  id: Uuid;
}

export type ReportSessionsByChargePointProps = Omit<
  GetProps<
    ResponseReportSessions,
    unknown,
    ReportSessionsByChargePointQueryParams,
    ReportSessionsByChargePointPathParams
  >,
  'path'
> &
  ReportSessionsByChargePointPathParams;

/**
 * Summarise session statistics for a single charge point over a time period.
 */
export const ReportSessionsByChargePoint = ({ id, ...props }: ReportSessionsByChargePointProps) => (
  <Get<ResponseReportSessions, unknown, ReportSessionsByChargePointQueryParams, ReportSessionsByChargePointPathParams>
    path={`/sessions/reports/by-charge-point/${id}`}
    {...props}
  />
);

export type UseReportSessionsByChargePointProps = Omit<
  UseGetProps<
    ResponseReportSessions,
    unknown,
    ReportSessionsByChargePointQueryParams,
    ReportSessionsByChargePointPathParams
  >,
  'path'
> &
  ReportSessionsByChargePointPathParams;

/**
 * Summarise session statistics for a single charge point over a time period.
 */
export const useReportSessionsByChargePoint = ({ id, ...props }: UseReportSessionsByChargePointProps) =>
  useGet<
    ResponseReportSessions,
    unknown,
    ReportSessionsByChargePointQueryParams,
    ReportSessionsByChargePointPathParams
  >((paramsInPath: ReportSessionsByChargePointPathParams) => `/sessions/reports/by-charge-point/${paramsInPath.id}`, {
    pathParams: { id },
    ...props,
  });

export interface GetGridCarbonIntensityQueryParams {
  /**
   * Where to get the carbon intensity data for (e.g. 'Pacific/Auckland', 'Australia/Melbourne')
   */
  locale: ElectricityGridLocale;
  /**
   * If no timestamp is provided, the most current statistics will be reported
   */
  timestamp?: string;
}

export type GetGridCarbonIntensityProps = Omit<
  GetProps<ResponseCarbonIntensity, ResponseApiErrors, GetGridCarbonIntensityQueryParams, void>,
  'path'
>;

/**
 * Get grid carbon intensity
 *
 * Retrieve information about the carbon intensity of the electricity grid in a locale at a point in time.
 */
export const GetGridCarbonIntensity = (props: GetGridCarbonIntensityProps) => (
  <Get<ResponseCarbonIntensity, ResponseApiErrors, GetGridCarbonIntensityQueryParams, void>
    path={`/statistics/electricity-grid/carbon-intensity`}
    {...props}
  />
);

export type UseGetGridCarbonIntensityProps = Omit<
  UseGetProps<ResponseCarbonIntensity, ResponseApiErrors, GetGridCarbonIntensityQueryParams, void>,
  'path'
>;

/**
 * Get grid carbon intensity
 *
 * Retrieve information about the carbon intensity of the electricity grid in a locale at a point in time.
 */
export const useGetGridCarbonIntensity = (props: UseGetGridCarbonIntensityProps) =>
  useGet<ResponseCarbonIntensity, ResponseApiErrors, GetGridCarbonIntensityQueryParams, void>(
    `/statistics/electricity-grid/carbon-intensity`,
    props,
  );

export interface GetGridGenerationMixQueryParams {
  /**
   * Where to get the generation mix data for (e.g. 'Pacific/Auckland', 'Australia/Melbourne')
   */
  locale: ElectricityGridLocale;
  /**
   * If no timestamp is provided, the most current statistics will be reported
   */
  timestamp?: string;
}

export type GetGridGenerationMixProps = Omit<
  GetProps<ResponseGridGenerationMix, ResponseApiErrors, GetGridGenerationMixQueryParams, void>,
  'path'
>;

/**
 * Get grid generation mix
 *
 * Retrieve information about the power being generated on the electricity grid in a locale at a point in time.
 */
export const GetGridGenerationMix = (props: GetGridGenerationMixProps) => (
  <Get<ResponseGridGenerationMix, ResponseApiErrors, GetGridGenerationMixQueryParams, void>
    path={`/statistics/electricity-grid/generation-mix`}
    {...props}
  />
);

export type UseGetGridGenerationMixProps = Omit<
  UseGetProps<ResponseGridGenerationMix, ResponseApiErrors, GetGridGenerationMixQueryParams, void>,
  'path'
>;

/**
 * Get grid generation mix
 *
 * Retrieve information about the power being generated on the electricity grid in a locale at a point in time.
 */
export const useGetGridGenerationMix = (props: UseGetGridGenerationMixProps) =>
  useGet<ResponseGridGenerationMix, ResponseApiErrors, GetGridGenerationMixQueryParams, void>(
    `/statistics/electricity-grid/generation-mix`,
    props,
  );

export interface GetCarbonIntensityOverRangeQueryParams {
  /**
   * Where to get the carbon intensity data for (e.g. 'Pacific/Auckland', 'Australia/Melbourne')
   */
  locale: ElectricityGridLocale;
  /**
   * The starting date for which to get the data for
   */
  from: string;
  /**
   * The end date for which to get the data up to (if not provided, get the data up to the current time)
   */
  to?: string;
}

export type GetCarbonIntensityOverRangeProps = Omit<
  GetProps<ResponseCarbonIntensityRange, ResponseApiErrors, GetCarbonIntensityOverRangeQueryParams, void>,
  'path'
>;

/**
 * Get grid carbon intensity over range
 *
 * Retrieve information about the carbon intensity of the electricity grid in a locale over a date range.
 */
export const GetCarbonIntensityOverRange = (props: GetCarbonIntensityOverRangeProps) => (
  <Get<ResponseCarbonIntensityRange, ResponseApiErrors, GetCarbonIntensityOverRangeQueryParams, void>
    path={`/statistics/electricity-grid/carbon-intensity-range`}
    {...props}
  />
);

export type UseGetCarbonIntensityOverRangeProps = Omit<
  UseGetProps<ResponseCarbonIntensityRange, ResponseApiErrors, GetCarbonIntensityOverRangeQueryParams, void>,
  'path'
>;

/**
 * Get grid carbon intensity over range
 *
 * Retrieve information about the carbon intensity of the electricity grid in a locale over a date range.
 */
export const useGetCarbonIntensityOverRange = (props: UseGetCarbonIntensityOverRangeProps) =>
  useGet<ResponseCarbonIntensityRange, ResponseApiErrors, GetCarbonIntensityOverRangeQueryParams, void>(
    `/statistics/electricity-grid/carbon-intensity-range`,
    props,
  );

export interface GetGridGenerationMixOverRangeQueryParams {
  /**
   * Where to get the generation mix data for (e.g. 'Pacific/Auckland', 'Australia/Melbourne')
   */
  locale: ElectricityGridLocale;
  /**
   * The starting date for which to get the data for
   */
  from: string;
  /**
   * The end date for which to get the data up to (if not provided, get the data up to the current time)
   */
  to?: string;
}

export type GetGridGenerationMixOverRangeProps = Omit<
  GetProps<ResponseGridGenerationMixRange, ResponseApiErrors, GetGridGenerationMixOverRangeQueryParams, void>,
  'path'
>;

/**
 * Get grid generation mix over range
 *
 * Retrieve information about the power being generated on the electricity grid in a locale over a date range.
 */
export const GetGridGenerationMixOverRange = (props: GetGridGenerationMixOverRangeProps) => (
  <Get<ResponseGridGenerationMixRange, ResponseApiErrors, GetGridGenerationMixOverRangeQueryParams, void>
    path={`/statistics/electricity-grid/generation-mix-range`}
    {...props}
  />
);

export type UseGetGridGenerationMixOverRangeProps = Omit<
  UseGetProps<ResponseGridGenerationMixRange, ResponseApiErrors, GetGridGenerationMixOverRangeQueryParams, void>,
  'path'
>;

/**
 * Get grid generation mix over range
 *
 * Retrieve information about the power being generated on the electricity grid in a locale over a date range.
 */
export const useGetGridGenerationMixOverRange = (props: UseGetGridGenerationMixOverRangeProps) =>
  useGet<ResponseGridGenerationMixRange, ResponseApiErrors, GetGridGenerationMixOverRangeQueryParams, void>(
    `/statistics/electricity-grid/generation-mix-range`,
    props,
  );

export interface UpdateTokenPathParams {
  tokenId: Uuid;
}

export type UpdateTokenProps = Omit<
  MutateProps<ResponsePatchToken, unknown, void, RequestUpdateToken, UpdateTokenPathParams>,
  'path' | 'verb'
> &
  UpdateTokenPathParams;

/**
 * Update token
 *
 * Update a token's details.
 */
export const UpdateToken = ({ tokenId, ...props }: UpdateTokenProps) => (
  <Mutate<ResponsePatchToken, unknown, void, RequestUpdateToken, UpdateTokenPathParams>
    verb="PATCH"
    path={`/tokens/${tokenId}`}
    {...props}
  />
);

export type UseUpdateTokenProps = Omit<
  UseMutateProps<ResponsePatchToken, unknown, void, RequestUpdateToken, UpdateTokenPathParams>,
  'path' | 'verb'
> &
  UpdateTokenPathParams;

/**
 * Update token
 *
 * Update a token's details.
 */
export const useUpdateToken = ({ tokenId, ...props }: UseUpdateTokenProps) =>
  useMutate<ResponsePatchToken, unknown, void, RequestUpdateToken, UpdateTokenPathParams>(
    'PATCH',
    (paramsInPath: UpdateTokenPathParams) => `/tokens/${paramsInPath.tokenId}`,
    { pathParams: { tokenId }, ...props },
  );

export type GetLegacyAuthenticatedUserProps = Omit<
  GetProps<ResponseGetLegacyAuthenticatedUser, ResponseApiErrors, void, void>,
  'path'
>;

/**
 * Get authenticated user
 *
 * Return the current user's details. The current user is determined by the `sub` in the JWT.
 */
export const GetLegacyAuthenticatedUser = (props: GetLegacyAuthenticatedUserProps) => (
  <Get<ResponseGetLegacyAuthenticatedUser, ResponseApiErrors, void, void> path={`/v2/apps/user`} {...props} />
);

export type UseGetLegacyAuthenticatedUserProps = Omit<
  UseGetProps<ResponseGetLegacyAuthenticatedUser, ResponseApiErrors, void, void>,
  'path'
>;

/**
 * Get authenticated user
 *
 * Return the current user's details. The current user is determined by the `sub` in the JWT.
 */
export const useGetLegacyAuthenticatedUser = (props: UseGetLegacyAuthenticatedUserProps) =>
  useGet<ResponseGetLegacyAuthenticatedUser, ResponseApiErrors, void, void>(`/v2/apps/user`, props);

export interface AddIntegrationPathParams {
  userId: Uuid;
}

export type AddIntegrationProps = Omit<
  MutateProps<ResponseAddIntegration, unknown, void, RequestAddIntegration, AddIntegrationPathParams>,
  'path' | 'verb'
> &
  AddIntegrationPathParams;

export const AddIntegration = ({ userId, ...props }: AddIntegrationProps) => (
  <Mutate<ResponseAddIntegration, unknown, void, RequestAddIntegration, AddIntegrationPathParams>
    verb="POST"
    path={`/users/${userId}/relationships/integrations`}
    {...props}
  />
);

export type UseAddIntegrationProps = Omit<
  UseMutateProps<ResponseAddIntegration, unknown, void, RequestAddIntegration, AddIntegrationPathParams>,
  'path' | 'verb'
> &
  AddIntegrationPathParams;

export const useAddIntegration = ({ userId, ...props }: UseAddIntegrationProps) =>
  useMutate<ResponseAddIntegration, unknown, void, RequestAddIntegration, AddIntegrationPathParams>(
    'POST',
    (paramsInPath: AddIntegrationPathParams) => `/users/${paramsInPath.userId}/relationships/integrations`,
    { pathParams: { userId }, ...props },
  );

export interface DeleteIntegrationsPathParams {
  userId: Uuid;
}

export type DeleteIntegrationsProps = Omit<
  MutateProps<void, unknown, void, void, DeleteIntegrationsPathParams>,
  'path' | 'verb'
> &
  DeleteIntegrationsPathParams;

export const DeleteIntegrations = ({ userId, ...props }: DeleteIntegrationsProps) => (
  <Mutate<void, unknown, void, void, DeleteIntegrationsPathParams>
    verb="DELETE"
    path={`/users/${userId}/relationships/integrations`}
    {...props}
  />
);

export type UseDeleteIntegrationsProps = Omit<
  UseMutateProps<void, unknown, void, void, DeleteIntegrationsPathParams>,
  'path' | 'verb'
> &
  DeleteIntegrationsPathParams;

export const useDeleteIntegrations = ({ userId, ...props }: UseDeleteIntegrationsProps) =>
  useMutate<void, unknown, void, void, DeleteIntegrationsPathParams>(
    'DELETE',
    (paramsInPath: DeleteIntegrationsPathParams) => `/users/${paramsInPath.userId}/relationships/integrations`,
    { pathParams: { userId }, ...props },
  );

export interface AddVehiclePathParams {
  userId: Uuid;
}

export type AddVehicleProps = Omit<
  MutateProps<ResponseBase, unknown, void, RequestAddVehicle, AddVehiclePathParams>,
  'path' | 'verb'
> &
  AddVehiclePathParams;

export const AddVehicle = ({ userId, ...props }: AddVehicleProps) => (
  <Mutate<ResponseBase, unknown, void, RequestAddVehicle, AddVehiclePathParams>
    verb="POST"
    path={`/users/${userId}/relationships/vehicles`}
    {...props}
  />
);

export type UseAddVehicleProps = Omit<
  UseMutateProps<ResponseBase, unknown, void, RequestAddVehicle, AddVehiclePathParams>,
  'path' | 'verb'
> &
  AddVehiclePathParams;

export const useAddVehicle = ({ userId, ...props }: UseAddVehicleProps) =>
  useMutate<ResponseBase, unknown, void, RequestAddVehicle, AddVehiclePathParams>(
    'POST',
    (paramsInPath: AddVehiclePathParams) => `/users/${paramsInPath.userId}/relationships/vehicles`,
    { pathParams: { userId }, ...props },
  );

export interface ListVehiclesPathParams {
  userId: Uuid;
}

export type ListVehiclesProps = Omit<GetProps<ResponseListVehicles, unknown, void, ListVehiclesPathParams>, 'path'> &
  ListVehiclesPathParams;

export const ListVehicles = ({ userId, ...props }: ListVehiclesProps) => (
  <Get<ResponseListVehicles, unknown, void, ListVehiclesPathParams>
    path={`/users/${userId}/relationships/vehicles`}
    {...props}
  />
);

export type UseListVehiclesProps = Omit<
  UseGetProps<ResponseListVehicles, unknown, void, ListVehiclesPathParams>,
  'path'
> &
  ListVehiclesPathParams;

export const useListVehicles = ({ userId, ...props }: UseListVehiclesProps) =>
  useGet<ResponseListVehicles, unknown, void, ListVehiclesPathParams>(
    (paramsInPath: ListVehiclesPathParams) => `/users/${paramsInPath.userId}/relationships/vehicles`,
    { pathParams: { userId }, ...props },
  );

export interface DeleteVehiclePathParams {
  userId: Uuid;
}

export type DeleteVehicleProps = Omit<
  MutateProps<void, unknown, void, string, DeleteVehiclePathParams>,
  'path' | 'verb'
> &
  DeleteVehiclePathParams;

export const DeleteVehicle = ({ userId, ...props }: DeleteVehicleProps) => (
  <Mutate<void, unknown, void, string, DeleteVehiclePathParams>
    verb="DELETE"
    path={`/users/${userId}/relationships/vehicles`}
    {...props}
  />
);

export type UseDeleteVehicleProps = Omit<
  UseMutateProps<void, unknown, void, string, DeleteVehiclePathParams>,
  'path' | 'verb'
> &
  DeleteVehiclePathParams;

export const useDeleteVehicle = ({ userId, ...props }: UseDeleteVehicleProps) =>
  useMutate<void, unknown, void, string, DeleteVehiclePathParams>(
    'DELETE',
    (paramsInPath: DeleteVehiclePathParams) => `/users/${paramsInPath.userId}/relationships/vehicles`,
    { pathParams: { userId }, ...props },
  );

export type CreateUserProps = Omit<
  MutateProps<ResponseCreateUser, ResponseApiErrors, void, RequestCreateUser, void>,
  'path' | 'verb'
>;

/**
 * Create user
 *
 * Create a new user outside the context of any organisation.
 */
export const CreateUser = (props: CreateUserProps) => (
  <Mutate<ResponseCreateUser, ResponseApiErrors, void, RequestCreateUser, void>
    verb="POST"
    path={`/users`}
    {...props}
  />
);

export type UseCreateUserProps = Omit<
  UseMutateProps<ResponseCreateUser, ResponseApiErrors, void, RequestCreateUser, void>,
  'path' | 'verb'
>;

/**
 * Create user
 *
 * Create a new user outside the context of any organisation.
 */
export const useCreateUser = (props: UseCreateUserProps) =>
  useMutate<ResponseCreateUser, ResponseApiErrors, void, RequestCreateUser, void>('POST', `/users`, props);

export interface ListUsersQueryParams {
  /**
   * Filter on type of user
   */
  type?: string;
}

export type ListUsersProps = Omit<GetProps<ResponseListUsers, unknown, ListUsersQueryParams, void>, 'path'>;

/**
 * List users
 *
 * List users outside of organisation context.
 */
export const ListUsers = (props: ListUsersProps) => (
  <Get<ResponseListUsers, unknown, ListUsersQueryParams, void> path={`/users`} {...props} />
);

export type UseListUsersProps = Omit<UseGetProps<ResponseListUsers, unknown, ListUsersQueryParams, void>, 'path'>;

/**
 * List users
 *
 * List users outside of organisation context.
 */
export const useListUsers = (props: UseListUsersProps) =>
  useGet<ResponseListUsers, unknown, ListUsersQueryParams, void>(`/users`, props);

export interface GetUserPathParams {
  id: Uuid;
}

export type GetUserProps = Omit<GetProps<ResponseGetUser, ResponseApiErrors, void, GetUserPathParams>, 'path'> &
  GetUserPathParams;

/**
 * Get user
 *
 * Get details of a user outside of organisation context, with all the user's organisation memberships.
 */
export const GetUser = ({ id, ...props }: GetUserProps) => (
  <Get<ResponseGetUser, ResponseApiErrors, void, GetUserPathParams> path={`/users/${id}`} {...props} />
);

export type UseGetUserProps = Omit<UseGetProps<ResponseGetUser, ResponseApiErrors, void, GetUserPathParams>, 'path'> &
  GetUserPathParams;

/**
 * Get user
 *
 * Get details of a user outside of organisation context, with all the user's organisation memberships.
 */
export const useGetUser = ({ id, ...props }: UseGetUserProps) =>
  useGet<ResponseGetUser, ResponseApiErrors, void, GetUserPathParams>(
    (paramsInPath: GetUserPathParams) => `/users/${paramsInPath.id}`,
    { pathParams: { id }, ...props },
  );

export interface UpdateUserPathParams {
  id: Uuid;
}

export type UpdateUserProps = Omit<
  MutateProps<ResponseUpdateUser, unknown, void, RequestUpdateUser, UpdateUserPathParams>,
  'path' | 'verb'
> &
  UpdateUserPathParams;

/**
 * Update user
 *
 * Update details of specified user.
 */
export const UpdateUser = ({ id, ...props }: UpdateUserProps) => (
  <Mutate<ResponseUpdateUser, unknown, void, RequestUpdateUser, UpdateUserPathParams>
    verb="PATCH"
    path={`/users/${id}`}
    {...props}
  />
);

export type UseUpdateUserProps = Omit<
  UseMutateProps<ResponseUpdateUser, unknown, void, RequestUpdateUser, UpdateUserPathParams>,
  'path' | 'verb'
> &
  UpdateUserPathParams;

/**
 * Update user
 *
 * Update details of specified user.
 */
export const useUpdateUser = ({ id, ...props }: UseUpdateUserProps) =>
  useMutate<ResponseUpdateUser, unknown, void, RequestUpdateUser, UpdateUserPathParams>(
    'PATCH',
    (paramsInPath: UpdateUserPathParams) => `/users/${paramsInPath.id}`,
    { pathParams: { id }, ...props },
  );

export interface ListUserLocationsPathParams {
  id: Uuid;
}

export type ListUserLocationsProps = Omit<
  GetProps<ResponseListUserLocations, unknown, void, ListUserLocationsPathParams>,
  'path'
> &
  ListUserLocationsPathParams;

/**
 * List user locations
 *
 * List locations where a user has been granted access, and the organisations that own those locations.
 */
export const ListUserLocations = ({ id, ...props }: ListUserLocationsProps) => (
  <Get<ResponseListUserLocations, unknown, void, ListUserLocationsPathParams>
    path={`/users/${id}/locations`}
    {...props}
  />
);

export type UseListUserLocationsProps = Omit<
  UseGetProps<ResponseListUserLocations, unknown, void, ListUserLocationsPathParams>,
  'path'
> &
  ListUserLocationsPathParams;

/**
 * List user locations
 *
 * List locations where a user has been granted access, and the organisations that own those locations.
 */
export const useListUserLocations = ({ id, ...props }: UseListUserLocationsProps) =>
  useGet<ResponseListUserLocations, unknown, void, ListUserLocationsPathParams>(
    (paramsInPath: ListUserLocationsPathParams) => `/users/${paramsInPath.id}/locations`,
    { pathParams: { id }, ...props },
  );

export type OldDeleteChargePointProps = Omit<MutateProps<void, ResponseApiErrors, void, Uuid, void>, 'path' | 'verb'>;

/**
 * Delete charge point
 *
 * Delete the requested charge point.
 */
export const OldDeleteChargePoint = (props: OldDeleteChargePointProps) => (
  <Mutate<void, ResponseApiErrors, void, Uuid, void> verb="DELETE" path={`/v3/charge-points`} {...props} />
);

export type UseOldDeleteChargePointProps = Omit<
  UseMutateProps<void, ResponseApiErrors, void, Uuid, void>,
  'path' | 'verb'
>;

/**
 * Delete charge point
 *
 * Delete the requested charge point.
 */
export const useOldDeleteChargePoint = (props: UseOldDeleteChargePointProps) =>
  useMutate<void, ResponseApiErrors, void, Uuid, void>('DELETE', `/v3/charge-points`, { ...props });

export interface OldGetChargePointPathParams {
  id: Uuid;
}

export type OldGetChargePointProps = Omit<
  GetProps<OldResponseGetChargePoint, ResponseApiErrors, void, OldGetChargePointPathParams>,
  'path'
> &
  OldGetChargePointPathParams;

/**
 * Get charge point
 *
 * Get details of specified charge point.
 */
export const OldGetChargePoint = ({ id, ...props }: OldGetChargePointProps) => (
  <Get<OldResponseGetChargePoint, ResponseApiErrors, void, OldGetChargePointPathParams>
    path={`/v3/charge-points/${id}`}
    {...props}
  />
);

export type UseOldGetChargePointProps = Omit<
  UseGetProps<OldResponseGetChargePoint, ResponseApiErrors, void, OldGetChargePointPathParams>,
  'path'
> &
  OldGetChargePointPathParams;

/**
 * Get charge point
 *
 * Get details of specified charge point.
 */
export const useOldGetChargePoint = ({ id, ...props }: UseOldGetChargePointProps) =>
  useGet<OldResponseGetChargePoint, ResponseApiErrors, void, OldGetChargePointPathParams>(
    (paramsInPath: OldGetChargePointPathParams) => `/v3/charge-points/${paramsInPath.id}`,
    { pathParams: { id }, ...props },
  );

export interface OldUpdateChargePointPathParams {
  id: Uuid;
}

export type OldUpdateChargePointProps = Omit<
  MutateProps<OldResponseUpdateChargePoint, unknown, void, RequestUpdateChargePoint, OldUpdateChargePointPathParams>,
  'path' | 'verb'
> &
  OldUpdateChargePointPathParams;

/**
 * Update charge point
 *
 * Update details of specified charge point.
 */
export const OldUpdateChargePoint = ({ id, ...props }: OldUpdateChargePointProps) => (
  <Mutate<OldResponseUpdateChargePoint, unknown, void, RequestUpdateChargePoint, OldUpdateChargePointPathParams>
    verb="PATCH"
    path={`/v3/charge-points/${id}`}
    {...props}
  />
);

export type UseOldUpdateChargePointProps = Omit<
  UseMutateProps<OldResponseUpdateChargePoint, unknown, void, RequestUpdateChargePoint, OldUpdateChargePointPathParams>,
  'path' | 'verb'
> &
  OldUpdateChargePointPathParams;

/**
 * Update charge point
 *
 * Update details of specified charge point.
 */
export const useOldUpdateChargePoint = ({ id, ...props }: UseOldUpdateChargePointProps) =>
  useMutate<OldResponseUpdateChargePoint, unknown, void, RequestUpdateChargePoint, OldUpdateChargePointPathParams>(
    'PATCH',
    (paramsInPath: OldUpdateChargePointPathParams) => `/v3/charge-points/${paramsInPath.id}`,
    { pathParams: { id }, ...props },
  );

export interface OldListChargePointsQueryParams {
  serial?: string;
}

export type OldListChargePointsProps = Omit<
  GetProps<OldResponseListChargePoints, unknown, OldListChargePointsQueryParams, void>,
  'path'
>;

/**
 * List charge points
 *
 * List all charge points across user's organisations. If user is a Superuser, Evnex Admin, or Installer, list all
 * charge points in the system, filtered by serial number.
 */
export const OldListChargePoints = (props: OldListChargePointsProps) => (
  <Get<OldResponseListChargePoints, unknown, OldListChargePointsQueryParams, void>
    path={`/v3/charge-points`}
    {...props}
  />
);

export type UseOldListChargePointsProps = Omit<
  UseGetProps<OldResponseListChargePoints, unknown, OldListChargePointsQueryParams, void>,
  'path'
>;

/**
 * List charge points
 *
 * List all charge points across user's organisations. If user is a Superuser, Evnex Admin, or Installer, list all
 * charge points in the system, filtered by serial number.
 */
export const useOldListChargePoints = (props: UseOldListChargePointsProps) =>
  useGet<OldResponseListChargePoints, unknown, OldListChargePointsQueryParams, void>(`/v3/charge-points`, props);

export interface OldListChargePointSessionsQueryParams {
  from?: string;
  to?: string;
}

export interface OldListChargePointSessionsPathParams {
  id: Uuid;
}

export type OldListChargePointSessionsProps = Omit<
  GetProps<
    OldResponseListChargePointSessions,
    unknown,
    OldListChargePointSessionsQueryParams,
    OldListChargePointSessionsPathParams
  >,
  'path'
> &
  OldListChargePointSessionsPathParams;

/**
 * List charge point sessions
 *
 * List sessions for the charge point, optionally filtered by sessionStatus and/or updatedDate.
 */
export const OldListChargePointSessions = ({ id, ...props }: OldListChargePointSessionsProps) => (
  <Get<
    OldResponseListChargePointSessions,
    unknown,
    OldListChargePointSessionsQueryParams,
    OldListChargePointSessionsPathParams
  >
    path={`/v3/charge-points/${id}/sessions`}
    {...props}
  />
);

export type UseOldListChargePointSessionsProps = Omit<
  UseGetProps<
    OldResponseListChargePointSessions,
    unknown,
    OldListChargePointSessionsQueryParams,
    OldListChargePointSessionsPathParams
  >,
  'path'
> &
  OldListChargePointSessionsPathParams;

/**
 * List charge point sessions
 *
 * List sessions for the charge point, optionally filtered by sessionStatus and/or updatedDate.
 */
export const useOldListChargePointSessions = ({ id, ...props }: UseOldListChargePointSessionsProps) =>
  useGet<
    OldResponseListChargePointSessions,
    unknown,
    OldListChargePointSessionsQueryParams,
    OldListChargePointSessionsPathParams
  >((paramsInPath: OldListChargePointSessionsPathParams) => `/v3/charge-points/${paramsInPath.id}/sessions`, {
    pathParams: { id },
    ...props,
  });

export interface OldCommandChangeAvailabilityPathParams {
  id: Uuid;
}

export type OldCommandChangeAvailabilityProps = Omit<
  MutateProps<ResponseCommand, unknown, void, RequestCommandChangeAvailability, OldCommandChangeAvailabilityPathParams>,
  'path' | 'verb'
> &
  OldCommandChangeAvailabilityPathParams;

/**
 * Change Availability command
 *
 * Tell the charge point to change its availability to operative or inoperative.
 */
export const OldCommandChangeAvailability = ({ id, ...props }: OldCommandChangeAvailabilityProps) => (
  <Mutate<ResponseCommand, unknown, void, RequestCommandChangeAvailability, OldCommandChangeAvailabilityPathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/change-availability`}
    {...props}
  />
);

export type UseOldCommandChangeAvailabilityProps = Omit<
  UseMutateProps<
    ResponseCommand,
    unknown,
    void,
    RequestCommandChangeAvailability,
    OldCommandChangeAvailabilityPathParams
  >,
  'path' | 'verb'
> &
  OldCommandChangeAvailabilityPathParams;

/**
 * Change Availability command
 *
 * Tell the charge point to change its availability to operative or inoperative.
 */
export const useOldCommandChangeAvailability = ({ id, ...props }: UseOldCommandChangeAvailabilityProps) =>
  useMutate<ResponseCommand, unknown, void, RequestCommandChangeAvailability, OldCommandChangeAvailabilityPathParams>(
    'POST',
    (paramsInPath: OldCommandChangeAvailabilityPathParams) =>
      `/v3/charge-points/${paramsInPath.id}/commands/change-availability`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandChangeConfigurationPathParams {
  id: Uuid;
}

export type OldCommandChangeConfigurationProps = Omit<
  MutateProps<
    ResponseCommandChangeConfiguration,
    unknown,
    void,
    RequestCommandChangeConfiguration,
    OldCommandChangeConfigurationPathParams
  >,
  'path' | 'verb'
> &
  OldCommandChangeConfigurationPathParams;

/**
 * Change Configuration command
 *
 * Set a configuration setting on a charge point to the specified value.
 */
export const OldCommandChangeConfiguration = ({ id, ...props }: OldCommandChangeConfigurationProps) => (
  <Mutate<
    ResponseCommandChangeConfiguration,
    unknown,
    void,
    RequestCommandChangeConfiguration,
    OldCommandChangeConfigurationPathParams
  >
    verb="POST"
    path={`/v3/charge-points/${id}/commands/change-configuration`}
    {...props}
  />
);

export type UseOldCommandChangeConfigurationProps = Omit<
  UseMutateProps<
    ResponseCommandChangeConfiguration,
    unknown,
    void,
    RequestCommandChangeConfiguration,
    OldCommandChangeConfigurationPathParams
  >,
  'path' | 'verb'
> &
  OldCommandChangeConfigurationPathParams;

/**
 * Change Configuration command
 *
 * Set a configuration setting on a charge point to the specified value.
 */
export const useOldCommandChangeConfiguration = ({ id, ...props }: UseOldCommandChangeConfigurationProps) =>
  useMutate<
    ResponseCommandChangeConfiguration,
    unknown,
    void,
    RequestCommandChangeConfiguration,
    OldCommandChangeConfigurationPathParams
  >(
    'POST',
    (paramsInPath: OldCommandChangeConfigurationPathParams) =>
      `/v3/charge-points/${paramsInPath.id}/commands/change-configuration`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandDataTransferPathParams {
  id: Uuid;
}

export type OldCommandDataTransferProps = Omit<
  MutateProps<ResponseCommandDataTransfer, unknown, void, RequestCommandDataTransfer, OldCommandDataTransferPathParams>,
  'path' | 'verb'
> &
  OldCommandDataTransferPathParams;

/**
 * Data Transfer command
 *
 * Send custom JSON data to the charge point.
 */
export const OldCommandDataTransfer = ({ id, ...props }: OldCommandDataTransferProps) => (
  <Mutate<ResponseCommandDataTransfer, unknown, void, RequestCommandDataTransfer, OldCommandDataTransferPathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/data-transfer`}
    {...props}
  />
);

export type UseOldCommandDataTransferProps = Omit<
  UseMutateProps<
    ResponseCommandDataTransfer,
    unknown,
    void,
    RequestCommandDataTransfer,
    OldCommandDataTransferPathParams
  >,
  'path' | 'verb'
> &
  OldCommandDataTransferPathParams;

/**
 * Data Transfer command
 *
 * Send custom JSON data to the charge point.
 */
export const useOldCommandDataTransfer = ({ id, ...props }: UseOldCommandDataTransferProps) =>
  useMutate<ResponseCommandDataTransfer, unknown, void, RequestCommandDataTransfer, OldCommandDataTransferPathParams>(
    'POST',
    (paramsInPath: OldCommandDataTransferPathParams) => `/v3/charge-points/${paramsInPath.id}/commands/data-transfer`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandGetConfigurationPathParams {
  id: Uuid;
}

export type OldCommandGetConfigurationProps = Omit<
  MutateProps<
    ResponseCommandGetConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandGetConfiguration,
    OldCommandGetConfigurationPathParams
  >,
  'path' | 'verb'
> &
  OldCommandGetConfigurationPathParams;

/**
 * Get Configuration command
 *
 * Get configuration setting(s) on a charge point.
 */
export const OldCommandGetConfiguration = ({ id, ...props }: OldCommandGetConfigurationProps) => (
  <Mutate<
    ResponseCommandGetConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandGetConfiguration,
    OldCommandGetConfigurationPathParams
  >
    verb="POST"
    path={`/v3/charge-points/${id}/commands/get-configuration`}
    {...props}
  />
);

export type UseOldCommandGetConfigurationProps = Omit<
  UseMutateProps<
    ResponseCommandGetConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandGetConfiguration,
    OldCommandGetConfigurationPathParams
  >,
  'path' | 'verb'
> &
  OldCommandGetConfigurationPathParams;

/**
 * Get Configuration command
 *
 * Get configuration setting(s) on a charge point.
 */
export const useOldCommandGetConfiguration = ({ id, ...props }: UseOldCommandGetConfigurationProps) =>
  useMutate<
    ResponseCommandGetConfiguration,
    ResponseApiErrors,
    void,
    RequestCommandGetConfiguration,
    OldCommandGetConfigurationPathParams
  >(
    'POST',
    (paramsInPath: OldCommandGetConfigurationPathParams) =>
      `/v3/charge-points/${paramsInPath.id}/commands/get-configuration`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandGetDiagnosticsPathParams {
  id: Uuid;
}

export type OldCommandGetDiagnosticsProps = Omit<
  MutateProps<ResponseCommand, unknown, void, RequestCommandGetDiagnostics, OldCommandGetDiagnosticsPathParams>,
  'path' | 'verb'
> &
  OldCommandGetDiagnosticsPathParams;

/**
 * Get Diagnostics command
 *
 * Command the charge point to upload its diagnostics data to the given URL.
 */
export const OldCommandGetDiagnostics = ({ id, ...props }: OldCommandGetDiagnosticsProps) => (
  <Mutate<ResponseCommand, unknown, void, RequestCommandGetDiagnostics, OldCommandGetDiagnosticsPathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/get-diagnostics`}
    {...props}
  />
);

export type UseOldCommandGetDiagnosticsProps = Omit<
  UseMutateProps<ResponseCommand, unknown, void, RequestCommandGetDiagnostics, OldCommandGetDiagnosticsPathParams>,
  'path' | 'verb'
> &
  OldCommandGetDiagnosticsPathParams;

/**
 * Get Diagnostics command
 *
 * Command the charge point to upload its diagnostics data to the given URL.
 */
export const useOldCommandGetDiagnostics = ({ id, ...props }: UseOldCommandGetDiagnosticsProps) =>
  useMutate<ResponseCommand, unknown, void, RequestCommandGetDiagnostics, OldCommandGetDiagnosticsPathParams>(
    'POST',
    (paramsInPath: OldCommandGetDiagnosticsPathParams) =>
      `/v3/charge-points/${paramsInPath.id}/commands/get-diagnostics`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandGetOverridePathParams {
  id: Uuid;
}

export type OldCommandGetOverrideProps = Omit<
  MutateProps<ResponseCommandGetOverride, ResponseApiErrors, void, void, OldCommandGetOverridePathParams>,
  'path' | 'verb'
> &
  OldCommandGetOverridePathParams;

/**
 * Get Override command
 *
 * Ask for a charge point's "charge now" override status.
 */
export const OldCommandGetOverride = ({ id, ...props }: OldCommandGetOverrideProps) => (
  <Mutate<ResponseCommandGetOverride, ResponseApiErrors, void, void, OldCommandGetOverridePathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/get-override`}
    {...props}
  />
);

export type UseOldCommandGetOverrideProps = Omit<
  UseMutateProps<ResponseCommandGetOverride, ResponseApiErrors, void, void, OldCommandGetOverridePathParams>,
  'path' | 'verb'
> &
  OldCommandGetOverridePathParams;

/**
 * Get Override command
 *
 * Ask for a charge point's "charge now" override status.
 */
export const useOldCommandGetOverride = ({ id, ...props }: UseOldCommandGetOverrideProps) =>
  useMutate<ResponseCommandGetOverride, ResponseApiErrors, void, void, OldCommandGetOverridePathParams>(
    'POST',
    (paramsInPath: OldCommandGetOverridePathParams) => `/v3/charge-points/${paramsInPath.id}/commands/get-override`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandResetPathParams {
  id: Uuid;
}

export type OldCommandResetProps = Omit<
  MutateProps<ResponseCommand, unknown, void, RequestCommandReset, OldCommandResetPathParams>,
  'path' | 'verb'
> &
  OldCommandResetPathParams;

/**
 * Reset command
 *
 * Command the charge point to do a hard or soft reboot.
 */
export const OldCommandReset = ({ id, ...props }: OldCommandResetProps) => (
  <Mutate<ResponseCommand, unknown, void, RequestCommandReset, OldCommandResetPathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/reset`}
    {...props}
  />
);

export type UseOldCommandResetProps = Omit<
  UseMutateProps<ResponseCommand, unknown, void, RequestCommandReset, OldCommandResetPathParams>,
  'path' | 'verb'
> &
  OldCommandResetPathParams;

/**
 * Reset command
 *
 * Command the charge point to do a hard or soft reboot.
 */
export const useOldCommandReset = ({ id, ...props }: UseOldCommandResetProps) =>
  useMutate<ResponseCommand, unknown, void, RequestCommandReset, OldCommandResetPathParams>(
    'POST',
    (paramsInPath: OldCommandResetPathParams) => `/v3/charge-points/${paramsInPath.id}/commands/reset`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandSetOverridePathParams {
  id: Uuid;
}

export type OldCommandSetOverrideProps = Omit<
  MutateProps<void, ResponseApiErrors, void, RequestCommandSetOverride, OldCommandSetOverridePathParams>,
  'path' | 'verb'
> &
  OldCommandSetOverridePathParams;

/**
 * Set Override command
 *
 * Override the smart charging profiles set on the charge point temporarily, or cancel the override.
 */
export const OldCommandSetOverride = ({ id, ...props }: OldCommandSetOverrideProps) => (
  <Mutate<void, ResponseApiErrors, void, RequestCommandSetOverride, OldCommandSetOverridePathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/set-override`}
    {...props}
  />
);

export type UseOldCommandSetOverrideProps = Omit<
  UseMutateProps<void, ResponseApiErrors, void, RequestCommandSetOverride, OldCommandSetOverridePathParams>,
  'path' | 'verb'
> &
  OldCommandSetOverridePathParams;

/**
 * Set Override command
 *
 * Override the smart charging profiles set on the charge point temporarily, or cancel the override.
 */
export const useOldCommandSetOverride = ({ id, ...props }: UseOldCommandSetOverrideProps) =>
  useMutate<void, ResponseApiErrors, void, RequestCommandSetOverride, OldCommandSetOverridePathParams>(
    'POST',
    (paramsInPath: OldCommandSetOverridePathParams) => `/v3/charge-points/${paramsInPath.id}/commands/set-override`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandRemoteStartPathParams {
  id: Uuid;
}

export type OldCommandRemoteStartProps = Omit<
  MutateProps<ResponseCommand, unknown, void, RequestCommandRemoteStart, OldCommandRemoteStartPathParams>,
  'path' | 'verb'
> &
  OldCommandRemoteStartPathParams;

export const OldCommandRemoteStart = ({ id, ...props }: OldCommandRemoteStartProps) => (
  <Mutate<ResponseCommand, unknown, void, RequestCommandRemoteStart, OldCommandRemoteStartPathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/remote-start`}
    {...props}
  />
);

export type UseOldCommandRemoteStartProps = Omit<
  UseMutateProps<ResponseCommand, unknown, void, RequestCommandRemoteStart, OldCommandRemoteStartPathParams>,
  'path' | 'verb'
> &
  OldCommandRemoteStartPathParams;

export const useOldCommandRemoteStart = ({ id, ...props }: UseOldCommandRemoteStartProps) =>
  useMutate<ResponseCommand, unknown, void, RequestCommandRemoteStart, OldCommandRemoteStartPathParams>(
    'POST',
    (paramsInPath: OldCommandRemoteStartPathParams) => `/v3/charge-points/${paramsInPath.id}/commands/remote-start`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandRemoteStopPathParams {
  id: Uuid;
}

export type OldCommandRemoteStopProps = Omit<
  MutateProps<ResponseCommand, unknown, void, RequestCommandRemoteStop, OldCommandRemoteStopPathParams>,
  'path' | 'verb'
> &
  OldCommandRemoteStopPathParams;

/**
 * Remote Stop command
 *
 * End the charging session in progress.
 */
export const OldCommandRemoteStop = ({ id, ...props }: OldCommandRemoteStopProps) => (
  <Mutate<ResponseCommand, unknown, void, RequestCommandRemoteStop, OldCommandRemoteStopPathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/remote-stop`}
    {...props}
  />
);

export type UseOldCommandRemoteStopProps = Omit<
  UseMutateProps<ResponseCommand, unknown, void, RequestCommandRemoteStop, OldCommandRemoteStopPathParams>,
  'path' | 'verb'
> &
  OldCommandRemoteStopPathParams;

/**
 * Remote Stop command
 *
 * End the charging session in progress.
 */
export const useOldCommandRemoteStop = ({ id, ...props }: UseOldCommandRemoteStopProps) =>
  useMutate<ResponseCommand, unknown, void, RequestCommandRemoteStop, OldCommandRemoteStopPathParams>(
    'POST',
    (paramsInPath: OldCommandRemoteStopPathParams) => `/v3/charge-points/${paramsInPath.id}/commands/remote-stop`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandUnlockConnectorPathParams {
  id: Uuid;
}

export type OldCommandUnlockConnectorProps = Omit<
  MutateProps<ResponseCommand, unknown, void, RequestCommandUnlockConnector, OldCommandUnlockConnectorPathParams>,
  'path' | 'verb'
> &
  OldCommandUnlockConnectorPathParams;

/**
 * Unlock Connector command
 *
 * Command a connector to release the cable plugged into it.
 */
export const OldCommandUnlockConnector = ({ id, ...props }: OldCommandUnlockConnectorProps) => (
  <Mutate<ResponseCommand, unknown, void, RequestCommandUnlockConnector, OldCommandUnlockConnectorPathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/unlock-connector`}
    {...props}
  />
);

export type UseOldCommandUnlockConnectorProps = Omit<
  UseMutateProps<ResponseCommand, unknown, void, RequestCommandUnlockConnector, OldCommandUnlockConnectorPathParams>,
  'path' | 'verb'
> &
  OldCommandUnlockConnectorPathParams;

/**
 * Unlock Connector command
 *
 * Command a connector to release the cable plugged into it.
 */
export const useOldCommandUnlockConnector = ({ id, ...props }: UseOldCommandUnlockConnectorProps) =>
  useMutate<ResponseCommand, unknown, void, RequestCommandUnlockConnector, OldCommandUnlockConnectorPathParams>(
    'POST',
    (paramsInPath: OldCommandUnlockConnectorPathParams) =>
      `/v3/charge-points/${paramsInPath.id}/commands/unlock-connector`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandUpdateFirmwarePathParams {
  id: Uuid;
}

export type OldCommandUpdateFirmwareProps = Omit<
  MutateProps<void, unknown, void, RequestCommandUpdateFirmware, OldCommandUpdateFirmwarePathParams>,
  'path' | 'verb'
> &
  OldCommandUpdateFirmwarePathParams;

/**
 * Update Firmware command
 *
 * Tell the charge point to update its firmware.
 */
export const OldCommandUpdateFirmware = ({ id, ...props }: OldCommandUpdateFirmwareProps) => (
  <Mutate<void, unknown, void, RequestCommandUpdateFirmware, OldCommandUpdateFirmwarePathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/update-firmware`}
    {...props}
  />
);

export type UseOldCommandUpdateFirmwareProps = Omit<
  UseMutateProps<void, unknown, void, RequestCommandUpdateFirmware, OldCommandUpdateFirmwarePathParams>,
  'path' | 'verb'
> &
  OldCommandUpdateFirmwarePathParams;

/**
 * Update Firmware command
 *
 * Tell the charge point to update its firmware.
 */
export const useOldCommandUpdateFirmware = ({ id, ...props }: UseOldCommandUpdateFirmwareProps) =>
  useMutate<void, unknown, void, RequestCommandUpdateFirmware, OldCommandUpdateFirmwarePathParams>(
    'POST',
    (paramsInPath: OldCommandUpdateFirmwarePathParams) =>
      `/v3/charge-points/${paramsInPath.id}/commands/update-firmware`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandClearChargingProfilePathParams {
  id: Uuid;
}

export type OldCommandClearChargingProfileProps = Omit<
  MutateProps<
    ResponseCommand,
    unknown,
    void,
    RequestCommandClearChargingProfile,
    OldCommandClearChargingProfilePathParams
  >,
  'path' | 'verb'
> &
  OldCommandClearChargingProfilePathParams;

/**
 * Clear Charging Profile command
 *
 * Remove either a specific charging profile (by its ID) or a selection of charging profiles
 * (by connector ID, stack level, and charging profile purpose).
 */
export const OldCommandClearChargingProfile = ({ id, ...props }: OldCommandClearChargingProfileProps) => (
  <Mutate<ResponseCommand, unknown, void, RequestCommandClearChargingProfile, OldCommandClearChargingProfilePathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/clear-charging-profile`}
    {...props}
  />
);

export type UseOldCommandClearChargingProfileProps = Omit<
  UseMutateProps<
    ResponseCommand,
    unknown,
    void,
    RequestCommandClearChargingProfile,
    OldCommandClearChargingProfilePathParams
  >,
  'path' | 'verb'
> &
  OldCommandClearChargingProfilePathParams;

/**
 * Clear Charging Profile command
 *
 * Remove either a specific charging profile (by its ID) or a selection of charging profiles
 * (by connector ID, stack level, and charging profile purpose).
 */
export const useOldCommandClearChargingProfile = ({ id, ...props }: UseOldCommandClearChargingProfileProps) =>
  useMutate<
    ResponseCommand,
    unknown,
    void,
    RequestCommandClearChargingProfile,
    OldCommandClearChargingProfilePathParams
  >(
    'POST',
    (paramsInPath: OldCommandClearChargingProfilePathParams) =>
      `/v3/charge-points/${paramsInPath.id}/commands/clear-charging-profile`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandClearCachePathParams {
  id: Uuid;
}

export type OldCommandClearCacheProps = Omit<
  MutateProps<ResponseCommand, unknown, void, void, OldCommandClearCachePathParams>,
  'path' | 'verb'
> &
  OldCommandClearCachePathParams;

/**
 * Clear Cache command
 *
 * Tell the charge point to clear its authorization cache.
 */
export const OldCommandClearCache = ({ id, ...props }: OldCommandClearCacheProps) => (
  <Mutate<ResponseCommand, unknown, void, void, OldCommandClearCachePathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/clear-cache`}
    {...props}
  />
);

export type UseOldCommandClearCacheProps = Omit<
  UseMutateProps<ResponseCommand, unknown, void, void, OldCommandClearCachePathParams>,
  'path' | 'verb'
> &
  OldCommandClearCachePathParams;

/**
 * Clear Cache command
 *
 * Tell the charge point to clear its authorization cache.
 */
export const useOldCommandClearCache = ({ id, ...props }: UseOldCommandClearCacheProps) =>
  useMutate<ResponseCommand, unknown, void, void, OldCommandClearCachePathParams>(
    'POST',
    (paramsInPath: OldCommandClearCachePathParams) => `/v3/charge-points/${paramsInPath.id}/commands/clear-cache`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandSudoPathParams {
  id: Uuid;
}

export type OldCommandSudoProps = Omit<
  MutateProps<ResponseCommand, unknown, void, RequestCommandSudo, OldCommandSudoPathParams>,
  'path' | 'verb'
> &
  OldCommandSudoPathParams;

/**
 * Sudo command
 *
 * Send a custom OCPP message to the charge point
 */
export const OldCommandSudo = ({ id, ...props }: OldCommandSudoProps) => (
  <Mutate<ResponseCommand, unknown, void, RequestCommandSudo, OldCommandSudoPathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/sudo`}
    {...props}
  />
);

export type UseOldCommandSudoProps = Omit<
  UseMutateProps<ResponseCommand, unknown, void, RequestCommandSudo, OldCommandSudoPathParams>,
  'path' | 'verb'
> &
  OldCommandSudoPathParams;

/**
 * Sudo command
 *
 * Send a custom OCPP message to the charge point
 */
export const useOldCommandSudo = ({ id, ...props }: UseOldCommandSudoProps) =>
  useMutate<ResponseCommand, unknown, void, RequestCommandSudo, OldCommandSudoPathParams>(
    'POST',
    (paramsInPath: OldCommandSudoPathParams) => `/v3/charge-points/${paramsInPath.id}/commands/sudo`,
    { pathParams: { id }, ...props },
  );

export interface OldCommandUploadDiagnosticsPathParams {
  id: Uuid;
}

export type OldCommandUploadDiagnosticsProps = Omit<
  MutateProps<ResponseCommand, unknown, void, RequestCommandUploadDiagnostics, OldCommandUploadDiagnosticsPathParams>,
  'path' | 'verb'
> &
  OldCommandUploadDiagnosticsPathParams;

/**
 * Upload Diagnostics command
 *
 * Upload charge point diagnostics logs using the specified protocol
 */
export const OldCommandUploadDiagnostics = ({ id, ...props }: OldCommandUploadDiagnosticsProps) => (
  <Mutate<ResponseCommand, unknown, void, RequestCommandUploadDiagnostics, OldCommandUploadDiagnosticsPathParams>
    verb="POST"
    path={`/v3/charge-points/${id}/commands/upload-diagnostics`}
    {...props}
  />
);

export type UseOldCommandUploadDiagnosticsProps = Omit<
  UseMutateProps<
    ResponseCommand,
    unknown,
    void,
    RequestCommandUploadDiagnostics,
    OldCommandUploadDiagnosticsPathParams
  >,
  'path' | 'verb'
> &
  OldCommandUploadDiagnosticsPathParams;

/**
 * Upload Diagnostics command
 *
 * Upload charge point diagnostics logs using the specified protocol
 */
export const useOldCommandUploadDiagnostics = ({ id, ...props }: UseOldCommandUploadDiagnosticsProps) =>
  useMutate<ResponseCommand, unknown, void, RequestCommandUploadDiagnostics, OldCommandUploadDiagnosticsPathParams>(
    'POST',
    (paramsInPath: OldCommandUploadDiagnosticsPathParams) =>
      `/v3/charge-points/${paramsInPath.id}/commands/upload-diagnostics`,
    { pathParams: { id }, ...props },
  );

export interface AssignInstallersPathParams {
  /**
   * ID of job
   */
  jobId: Uuid;
}

export type AssignInstallersProps = Omit<
  MutateProps<ResponseBase, unknown, void, RequestModifyAssignedInstallers, AssignInstallersPathParams>,
  'path' | 'verb'
> &
  AssignInstallersPathParams;

/**
 * Add assigned installers
 *
 * Add users to the list of assigned installers for a job. It will appear for these users when they list pending jobs assigned to them.
 */
export const AssignInstallers = ({ jobId, ...props }: AssignInstallersProps) => (
  <Mutate<ResponseBase, unknown, void, RequestModifyAssignedInstallers, AssignInstallersPathParams>
    verb="POST"
    path={`/jobs/${jobId}/relationships/assigned-installers`}
    {...props}
  />
);

export type UseAssignInstallersProps = Omit<
  UseMutateProps<ResponseBase, unknown, void, RequestModifyAssignedInstallers, AssignInstallersPathParams>,
  'path' | 'verb'
> &
  AssignInstallersPathParams;

/**
 * Add assigned installers
 *
 * Add users to the list of assigned installers for a job. It will appear for these users when they list pending jobs assigned to them.
 */
export const useAssignInstallers = ({ jobId, ...props }: UseAssignInstallersProps) =>
  useMutate<ResponseBase, unknown, void, RequestModifyAssignedInstallers, AssignInstallersPathParams>(
    'POST',
    (paramsInPath: AssignInstallersPathParams) => `/jobs/${paramsInPath.jobId}/relationships/assigned-installers`,
    { pathParams: { jobId }, ...props },
  );

export interface UnassignInstallersPathParams {
  /**
   * ID of job
   */
  jobId: Uuid;
}

export type UnassignInstallersProps = Omit<
  MutateProps<ResponseBase, unknown, void, RequestModifyAssignedInstallers, UnassignInstallersPathParams>,
  'path' | 'verb'
> &
  UnassignInstallersPathParams;

/**
 * Remove assigned installers
 *
 * Remove users from the list of assigned installers for a job. They will no longer see this job in their list of pending jobs.
 */
export const UnassignInstallers = ({ jobId, ...props }: UnassignInstallersProps) => (
  <Mutate<ResponseBase, unknown, void, RequestModifyAssignedInstallers, UnassignInstallersPathParams>
    verb="DELETE"
    path={`/jobs/${jobId}/relationships/assigned-installers`}
    {...props}
  />
);

export type UseUnassignInstallersProps = Omit<
  UseMutateProps<ResponseBase, unknown, void, RequestModifyAssignedInstallers, UnassignInstallersPathParams>,
  'path' | 'verb'
> &
  UnassignInstallersPathParams;

/**
 * Remove assigned installers
 *
 * Remove users from the list of assigned installers for a job. They will no longer see this job in their list of pending jobs.
 */
export const useUnassignInstallers = ({ jobId, ...props }: UseUnassignInstallersProps) =>
  useMutate<ResponseBase, unknown, void, RequestModifyAssignedInstallers, UnassignInstallersPathParams>(
    'DELETE',
    (paramsInPath: UnassignInstallersPathParams) => `/jobs/${paramsInPath.jobId}/relationships/assigned-installers`,
    { pathParams: { jobId }, ...props },
  );

export interface AddAttachmentsPathParams {
  jobId: Uuid;
}

export type AddAttachmentsProps = Omit<
  MutateProps<ResponseAttachments, unknown, void, RequestAddAttachments, AddAttachmentsPathParams>,
  'path' | 'verb'
> &
  AddAttachmentsPathParams;

/**
 * Add attachments
 *
 * Create file upload URLs to attach to a job. Each attachment's ID is a signed URL where the file may be uploaded.
 */
export const AddAttachments = ({ jobId, ...props }: AddAttachmentsProps) => (
  <Mutate<ResponseAttachments, unknown, void, RequestAddAttachments, AddAttachmentsPathParams>
    verb="POST"
    path={`/jobs/${jobId}/relationships/attachments`}
    {...props}
  />
);

export type UseAddAttachmentsProps = Omit<
  UseMutateProps<ResponseAttachments, unknown, void, RequestAddAttachments, AddAttachmentsPathParams>,
  'path' | 'verb'
> &
  AddAttachmentsPathParams;

/**
 * Add attachments
 *
 * Create file upload URLs to attach to a job. Each attachment's ID is a signed URL where the file may be uploaded.
 */
export const useAddAttachments = ({ jobId, ...props }: UseAddAttachmentsProps) =>
  useMutate<ResponseAttachments, unknown, void, RequestAddAttachments, AddAttachmentsPathParams>(
    'POST',
    (paramsInPath: AddAttachmentsPathParams) => `/jobs/${paramsInPath.jobId}/relationships/attachments`,
    { pathParams: { jobId }, ...props },
  );

export interface GetAttachmentsPathParams {
  jobId: Uuid;
}

export type GetAttachmentsProps = Omit<GetProps<ResponseAttachments, unknown, void, GetAttachmentsPathParams>, 'path'> &
  GetAttachmentsPathParams;

/**
 * List attachments
 *
 * Get file download URLs for a job. Each attachment's ID is a signed URL where the file may be downloaded from.
 */
export const GetAttachments = ({ jobId, ...props }: GetAttachmentsProps) => (
  <Get<ResponseAttachments, unknown, void, GetAttachmentsPathParams>
    path={`/jobs/${jobId}/relationships/attachments`}
    {...props}
  />
);

export type UseGetAttachmentsProps = Omit<
  UseGetProps<ResponseAttachments, unknown, void, GetAttachmentsPathParams>,
  'path'
> &
  GetAttachmentsPathParams;

/**
 * List attachments
 *
 * Get file download URLs for a job. Each attachment's ID is a signed URL where the file may be downloaded from.
 */
export const useGetAttachments = ({ jobId, ...props }: UseGetAttachmentsProps) =>
  useGet<ResponseAttachments, unknown, void, GetAttachmentsPathParams>(
    (paramsInPath: GetAttachmentsPathParams) => `/jobs/${paramsInPath.jobId}/relationships/attachments`,
    { pathParams: { jobId }, ...props },
  );

export interface GetJobChargePointPathParams {
  /**
   * ID of the Job
   */
  jobId: Uuid;
}

export type GetJobChargePointProps = Omit<
  GetProps<ResponseGetJobChargePoint, unknown, void, GetJobChargePointPathParams>,
  'path'
> &
  GetJobChargePointPathParams;

/**
 * Get job charge point
 */
export const GetJobChargePoint = ({ jobId, ...props }: GetJobChargePointProps) => (
  <Get<ResponseGetJobChargePoint, unknown, void, GetJobChargePointPathParams>
    path={`/jobs/${jobId}/charge-point`}
    {...props}
  />
);

export type UseGetJobChargePointProps = Omit<
  UseGetProps<ResponseGetJobChargePoint, unknown, void, GetJobChargePointPathParams>,
  'path'
> &
  GetJobChargePointPathParams;

/**
 * Get job charge point
 */
export const useGetJobChargePoint = ({ jobId, ...props }: UseGetJobChargePointProps) =>
  useGet<ResponseGetJobChargePoint, unknown, void, GetJobChargePointPathParams>(
    (paramsInPath: GetJobChargePointPathParams) => `/jobs/${paramsInPath.jobId}/charge-point`,
    { pathParams: { jobId }, ...props },
  );

export interface ReinviteCustomerPathParams {
  jobId: Uuid;
}

export type ReinviteCustomerProps = Omit<
  MutateProps<void, unknown, void, void, ReinviteCustomerPathParams>,
  'path' | 'verb'
> &
  ReinviteCustomerPathParams;

/**
 * Re-invite customer
 *
 * Re-send the invitation email to the job's assigned customer.
 */
export const ReinviteCustomer = ({ jobId, ...props }: ReinviteCustomerProps) => (
  <Mutate<void, unknown, void, void, ReinviteCustomerPathParams>
    verb="POST"
    path={`/jobs/${jobId}/commands/resend-invitation`}
    {...props}
  />
);

export type UseReinviteCustomerProps = Omit<
  UseMutateProps<void, unknown, void, void, ReinviteCustomerPathParams>,
  'path' | 'verb'
> &
  ReinviteCustomerPathParams;

/**
 * Re-invite customer
 *
 * Re-send the invitation email to the job's assigned customer.
 */
export const useReinviteCustomer = ({ jobId, ...props }: UseReinviteCustomerProps) =>
  useMutate<void, unknown, void, void, ReinviteCustomerPathParams>(
    'POST',
    (paramsInPath: ReinviteCustomerPathParams) => `/jobs/${paramsInPath.jobId}/commands/resend-invitation`,
    { pathParams: { jobId }, ...props },
  );

export interface CommissionChargePointPathParams {
  jobId: Uuid;
}

export type CommissionChargePointProps = Omit<
  MutateProps<void, unknown, void, RequestCommandCommissionChargePoint, CommissionChargePointPathParams>,
  'path' | 'verb'
> &
  CommissionChargePointPathParams;

/**
 * Commission charge point
 *
 * Trigger the commissioning process for a charge point. Prerequisites: Job must not be in a
 * non-complete state, have a non-null `installer` relationship, and every distribution board
 * must have specified a `supplyCurrentLimit`.
 */
export const CommissionChargePoint = ({ jobId, ...props }: CommissionChargePointProps) => (
  <Mutate<void, unknown, void, RequestCommandCommissionChargePoint, CommissionChargePointPathParams>
    verb="POST"
    path={`/jobs/${jobId}/commands/commission-charge-point`}
    {...props}
  />
);

export type UseCommissionChargePointProps = Omit<
  UseMutateProps<void, unknown, void, RequestCommandCommissionChargePoint, CommissionChargePointPathParams>,
  'path' | 'verb'
> &
  CommissionChargePointPathParams;

/**
 * Commission charge point
 *
 * Trigger the commissioning process for a charge point. Prerequisites: Job must not be in a
 * non-complete state, have a non-null `installer` relationship, and every distribution board
 * must have specified a `supplyCurrentLimit`.
 */
export const useCommissionChargePoint = ({ jobId, ...props }: UseCommissionChargePointProps) =>
  useMutate<void, unknown, void, RequestCommandCommissionChargePoint, CommissionChargePointPathParams>(
    'POST',
    (paramsInPath: CommissionChargePointPathParams) => `/jobs/${paramsInPath.jobId}/commands/commission-charge-point`,
    { pathParams: { jobId }, ...props },
  );

export interface ConfirmCTStatusPathParams {
  jobId: Uuid;
}

export type ConfirmCTStatusProps = Omit<
  MutateProps<void, unknown, void, RequestConfirmCTStatus, ConfirmCTStatusPathParams>,
  'path' | 'verb'
> &
  ConfirmCTStatusPathParams;

export const ConfirmCTStatus = ({ jobId, ...props }: ConfirmCTStatusProps) => (
  <Mutate<void, unknown, void, RequestConfirmCTStatus, ConfirmCTStatusPathParams>
    verb="POST"
    path={`/jobs/${jobId}/commands/confirm-ct-status`}
    {...props}
  />
);

export type UseConfirmCTStatusProps = Omit<
  UseMutateProps<void, unknown, void, RequestConfirmCTStatus, ConfirmCTStatusPathParams>,
  'path' | 'verb'
> &
  ConfirmCTStatusPathParams;

export const useConfirmCTStatus = ({ jobId, ...props }: UseConfirmCTStatusProps) =>
  useMutate<void, unknown, void, RequestConfirmCTStatus, ConfirmCTStatusPathParams>(
    'POST',
    (paramsInPath: ConfirmCTStatusPathParams) => `/jobs/${paramsInPath.jobId}/commands/confirm-ct-status`,
    { pathParams: { jobId }, ...props },
  );

export interface GetJobCustomerPathParams {
  /**
   * ID of the Job
   */
  jobId: Uuid;
}

export type GetJobCustomerProps = Omit<
  GetProps<ResponseGetJobCustomer, unknown, void, GetJobCustomerPathParams>,
  'path'
> &
  GetJobCustomerPathParams;

/**
 * Get job customer
 */
export const GetJobCustomer = ({ jobId, ...props }: GetJobCustomerProps) => (
  <Get<ResponseGetJobCustomer, unknown, void, GetJobCustomerPathParams> path={`/jobs/${jobId}/customer`} {...props} />
);

export type UseGetJobCustomerProps = Omit<
  UseGetProps<ResponseGetJobCustomer, unknown, void, GetJobCustomerPathParams>,
  'path'
> &
  GetJobCustomerPathParams;

/**
 * Get job customer
 */
export const useGetJobCustomer = ({ jobId, ...props }: UseGetJobCustomerProps) =>
  useGet<ResponseGetJobCustomer, unknown, void, GetJobCustomerPathParams>(
    (paramsInPath: GetJobCustomerPathParams) => `/jobs/${paramsInPath.jobId}/customer`,
    { pathParams: { jobId }, ...props },
  );

export interface UpdateJobCustomerPathParams {
  /**
   * ID of Job
   */
  jobId: Uuid;
}

export type UpdateJobCustomerProps = Omit<
  MutateProps<ResponseUpdateJobCustomer, unknown, void, RequestUpdateJobCustomer, UpdateJobCustomerPathParams>,
  'path' | 'verb'
> &
  UpdateJobCustomerPathParams;

/**
 * Update job customer
 */
export const UpdateJobCustomer = ({ jobId, ...props }: UpdateJobCustomerProps) => (
  <Mutate<ResponseUpdateJobCustomer, unknown, void, RequestUpdateJobCustomer, UpdateJobCustomerPathParams>
    verb="PATCH"
    path={`/jobs/${jobId}/customer`}
    {...props}
  />
);

export type UseUpdateJobCustomerProps = Omit<
  UseMutateProps<ResponseUpdateJobCustomer, unknown, void, RequestUpdateJobCustomer, UpdateJobCustomerPathParams>,
  'path' | 'verb'
> &
  UpdateJobCustomerPathParams;

/**
 * Update job customer
 */
export const useUpdateJobCustomer = ({ jobId, ...props }: UseUpdateJobCustomerProps) =>
  useMutate<ResponseUpdateJobCustomer, unknown, void, RequestUpdateJobCustomer, UpdateJobCustomerPathParams>(
    'PATCH',
    (paramsInPath: UpdateJobCustomerPathParams) => `/jobs/${paramsInPath.jobId}/customer`,
    { pathParams: { jobId }, ...props },
  );

export interface UpdateJobLocationPathParams {
  /**
   * ID of Job
   */
  jobId: Uuid;
}

export type UpdateJobLocationProps = Omit<
  MutateProps<ResponseUpdateLocation, unknown, void, RequestUpdateLocation, UpdateJobLocationPathParams>,
  'path' | 'verb'
> &
  UpdateJobLocationPathParams;

/**
 * Update job location
 */
export const UpdateJobLocation = ({ jobId, ...props }: UpdateJobLocationProps) => (
  <Mutate<ResponseUpdateLocation, unknown, void, RequestUpdateLocation, UpdateJobLocationPathParams>
    verb="PATCH"
    path={`/jobs/${jobId}/location`}
    {...props}
  />
);

export type UseUpdateJobLocationProps = Omit<
  UseMutateProps<ResponseUpdateLocation, unknown, void, RequestUpdateLocation, UpdateJobLocationPathParams>,
  'path' | 'verb'
> &
  UpdateJobLocationPathParams;

/**
 * Update job location
 */
export const useUpdateJobLocation = ({ jobId, ...props }: UseUpdateJobLocationProps) =>
  useMutate<ResponseUpdateLocation, unknown, void, RequestUpdateLocation, UpdateJobLocationPathParams>(
    'PATCH',
    (paramsInPath: UpdateJobLocationPathParams) => `/jobs/${paramsInPath.jobId}/location`,
    { pathParams: { jobId }, ...props },
  );

export interface ListJobsQueryParams {
  /**
   * Only return jobs with this status (case-insensitive label, or numeric value)
   */
  status?: string;
  /**
   * Only return jobs with this reference ID (case-insensitive, special characters should be URL encoded)
   */
  'reference-id'?: UrlEncodedString;
  /**
   * Return jobs created after this date
   */
  from?: string;
  /**
   * Return jobs created before this date
   */
  to?: string;
}

export type ListJobsProps = Omit<GetProps<ResponseListJobs, unknown, ListJobsQueryParams, void>, 'path'>;

/**
 * List jobs
 *
 * List all available jobs, filtered by status if a status query param is provided.
 */
export const ListJobs = (props: ListJobsProps) => (
  <Get<ResponseListJobs, unknown, ListJobsQueryParams, void> path={`/jobs`} {...props} />
);

export type UseListJobsProps = Omit<UseGetProps<ResponseListJobs, unknown, ListJobsQueryParams, void>, 'path'>;

/**
 * List jobs
 *
 * List all available jobs, filtered by status if a status query param is provided.
 */
export const useListJobs = (props: UseListJobsProps) =>
  useGet<ResponseListJobs, unknown, ListJobsQueryParams, void>(`/jobs`, props);

export type CreateJobProps = Omit<
  MutateProps<ResponseUpdateJob, unknown, void, RequestCreateJob, void>,
  'path' | 'verb'
>;

/**
 * Create job
 *
 * Create a pending job for a customer at a location.
 */
export const CreateJob = (props: CreateJobProps) => (
  <Mutate<ResponseUpdateJob, unknown, void, RequestCreateJob, void> verb="POST" path={`/jobs`} {...props} />
);

export type UseCreateJobProps = Omit<
  UseMutateProps<ResponseUpdateJob, unknown, void, RequestCreateJob, void>,
  'path' | 'verb'
>;

/**
 * Create job
 *
 * Create a pending job for a customer at a location.
 */
export const useCreateJob = (props: UseCreateJobProps) =>
  useMutate<ResponseUpdateJob, unknown, void, RequestCreateJob, void>('POST', `/jobs`, props);

export interface GetJobPathParams {
  jobId: Uuid;
}

export type GetJobProps = Omit<GetProps<ResponseGetJob, unknown, void, GetJobPathParams>, 'path'> & GetJobPathParams;

/**
 * Get job
 *
 * Retrieve a job's details by its ID.
 */
export const GetJob = ({ jobId, ...props }: GetJobProps) => (
  <Get<ResponseGetJob, unknown, void, GetJobPathParams> path={`/jobs/${jobId}`} {...props} />
);

export type UseGetJobProps = Omit<UseGetProps<ResponseGetJob, unknown, void, GetJobPathParams>, 'path'> &
  GetJobPathParams;

/**
 * Get job
 *
 * Retrieve a job's details by its ID.
 */
export const useGetJob = ({ jobId, ...props }: UseGetJobProps) =>
  useGet<ResponseGetJob, unknown, void, GetJobPathParams>(
    (paramsInPath: GetJobPathParams) => `/jobs/${paramsInPath.jobId}`,
    { pathParams: { jobId }, ...props },
  );

export interface UpdateJobPathParams {
  jobId: Uuid;
}

export type UpdateJobProps = Omit<
  MutateProps<ResponseUpdateJob, unknown, void, RequestUpdateJob, UpdateJobPathParams>,
  'path' | 'verb'
> &
  UpdateJobPathParams;

/**
 * Update job
 *
 * Update the properties of a Job.
 */
export const UpdateJob = ({ jobId, ...props }: UpdateJobProps) => (
  <Mutate<ResponseUpdateJob, unknown, void, RequestUpdateJob, UpdateJobPathParams>
    verb="PATCH"
    path={`/jobs/${jobId}`}
    {...props}
  />
);

export type UseUpdateJobProps = Omit<
  UseMutateProps<ResponseUpdateJob, unknown, void, RequestUpdateJob, UpdateJobPathParams>,
  'path' | 'verb'
> &
  UpdateJobPathParams;

/**
 * Update job
 *
 * Update the properties of a Job.
 */
export const useUpdateJob = ({ jobId, ...props }: UseUpdateJobProps) =>
  useMutate<ResponseUpdateJob, unknown, void, RequestUpdateJob, UpdateJobPathParams>(
    'PATCH',
    (paramsInPath: UpdateJobPathParams) => `/jobs/${paramsInPath.jobId}`,
    { pathParams: { jobId }, ...props },
  );

export type CreateJobOperationProps = Omit<
  MutateProps<ResponseCreateJobOperation, unknown, void, RequestAtomicOperations, void>,
  'path' | 'verb'
>;

/**
 * Add, update and remove multiple entities in a single request
 *
 * Perform multiple operations in a single request.
 *
 * Based on the Atomic Operations extension to JSON:API described at
 * https://jsonapi.org/ext/atomic/, except that atomicity of the operations
 * is not supported.
 */
export const CreateJobOperation = (props: CreateJobOperationProps) => (
  <Mutate<ResponseCreateJobOperation, unknown, void, RequestAtomicOperations, void>
    verb="POST"
    path={`/operations/create-job`}
    {...props}
  />
);

export type UseCreateJobOperationProps = Omit<
  UseMutateProps<ResponseCreateJobOperation, unknown, void, RequestAtomicOperations, void>,
  'path' | 'verb'
>;

/**
 * Add, update and remove multiple entities in a single request
 *
 * Perform multiple operations in a single request.
 *
 * Based on the Atomic Operations extension to JSON:API described at
 * https://jsonapi.org/ext/atomic/, except that atomicity of the operations
 * is not supported.
 */
export const useCreateJobOperation = (props: UseCreateJobOperationProps) =>
  useMutate<ResponseCreateJobOperation, unknown, void, RequestAtomicOperations, void>(
    'POST',
    `/operations/create-job`,
    props,
  );

export interface OldAssignInstallersPathParams {
  /**
   * ID of job
   */
  jobId: Uuid;
}

export type OldAssignInstallersProps = Omit<
  MutateProps<ResponseBase, unknown, void, RequestModifyAssignedInstallers, OldAssignInstallersPathParams>,
  'path' | 'verb'
> &
  OldAssignInstallersPathParams;

/**
 * Add assigned installers
 *
 * Add users to the list of assigned installers for a job. It will appear for these users when they list pending jobs assigned to them.
 */
export const OldAssignInstallers = ({ jobId, ...props }: OldAssignInstallersProps) => (
  <Mutate<ResponseBase, unknown, void, RequestModifyAssignedInstallers, OldAssignInstallersPathParams>
    verb="POST"
    path={`/v3/jobs/${jobId}/relationships/assigned-installers`}
    {...props}
  />
);

export type UseOldAssignInstallersProps = Omit<
  UseMutateProps<ResponseBase, unknown, void, RequestModifyAssignedInstallers, OldAssignInstallersPathParams>,
  'path' | 'verb'
> &
  OldAssignInstallersPathParams;

/**
 * Add assigned installers
 *
 * Add users to the list of assigned installers for a job. It will appear for these users when they list pending jobs assigned to them.
 */
export const useOldAssignInstallers = ({ jobId, ...props }: UseOldAssignInstallersProps) =>
  useMutate<ResponseBase, unknown, void, RequestModifyAssignedInstallers, OldAssignInstallersPathParams>(
    'POST',
    (paramsInPath: OldAssignInstallersPathParams) => `/v3/jobs/${paramsInPath.jobId}/relationships/assigned-installers`,
    { pathParams: { jobId }, ...props },
  );

export interface OldUnassignInstallersPathParams {
  /**
   * ID of job
   */
  jobId: Uuid;
}

export type OldUnassignInstallersProps = Omit<
  MutateProps<ResponseBase, unknown, void, RequestModifyAssignedInstallers, OldUnassignInstallersPathParams>,
  'path' | 'verb'
> &
  OldUnassignInstallersPathParams;

/**
 * Remove assigned installers
 *
 * Remove users from the list of assigned installers for a job. They will no longer see this job in their list of pending jobs.
 */
export const OldUnassignInstallers = ({ jobId, ...props }: OldUnassignInstallersProps) => (
  <Mutate<ResponseBase, unknown, void, RequestModifyAssignedInstallers, OldUnassignInstallersPathParams>
    verb="DELETE"
    path={`/v3/jobs/${jobId}/relationships/assigned-installers`}
    {...props}
  />
);

export type UseOldUnassignInstallersProps = Omit<
  UseMutateProps<ResponseBase, unknown, void, RequestModifyAssignedInstallers, OldUnassignInstallersPathParams>,
  'path' | 'verb'
> &
  OldUnassignInstallersPathParams;

/**
 * Remove assigned installers
 *
 * Remove users from the list of assigned installers for a job. They will no longer see this job in their list of pending jobs.
 */
export const useOldUnassignInstallers = ({ jobId, ...props }: UseOldUnassignInstallersProps) =>
  useMutate<ResponseBase, unknown, void, RequestModifyAssignedInstallers, OldUnassignInstallersPathParams>(
    'DELETE',
    (paramsInPath: OldUnassignInstallersPathParams) =>
      `/v3/jobs/${paramsInPath.jobId}/relationships/assigned-installers`,
    { pathParams: { jobId }, ...props },
  );

export interface OldAddAttachmentsPathParams {
  jobId: Uuid;
}

export type OldAddAttachmentsProps = Omit<
  MutateProps<OldResponseAttachments, unknown, void, RequestAddAttachments, OldAddAttachmentsPathParams>,
  'path' | 'verb'
> &
  OldAddAttachmentsPathParams;

/**
 * Add attachments
 *
 * Create file upload URLs to attach to a job. Each attachment's ID is a signed URL where the file may be uploaded.
 */
export const OldAddAttachments = ({ jobId, ...props }: OldAddAttachmentsProps) => (
  <Mutate<OldResponseAttachments, unknown, void, RequestAddAttachments, OldAddAttachmentsPathParams>
    verb="POST"
    path={`/v3/jobs/${jobId}/relationships/attachments`}
    {...props}
  />
);

export type UseOldAddAttachmentsProps = Omit<
  UseMutateProps<OldResponseAttachments, unknown, void, RequestAddAttachments, OldAddAttachmentsPathParams>,
  'path' | 'verb'
> &
  OldAddAttachmentsPathParams;

/**
 * Add attachments
 *
 * Create file upload URLs to attach to a job. Each attachment's ID is a signed URL where the file may be uploaded.
 */
export const useOldAddAttachments = ({ jobId, ...props }: UseOldAddAttachmentsProps) =>
  useMutate<OldResponseAttachments, unknown, void, RequestAddAttachments, OldAddAttachmentsPathParams>(
    'POST',
    (paramsInPath: OldAddAttachmentsPathParams) => `/v3/jobs/${paramsInPath.jobId}/relationships/attachments`,
    { pathParams: { jobId }, ...props },
  );

export interface OldGetAttachmentsPathParams {
  jobId: Uuid;
}

export type OldGetAttachmentsProps = Omit<
  GetProps<OldResponseAttachments, unknown, void, OldGetAttachmentsPathParams>,
  'path'
> &
  OldGetAttachmentsPathParams;

/**
 * List attachments
 *
 * Get file download URLs for a job. Each attachment's ID is a signed URL where the file may be downloaded from.
 */
export const OldGetAttachments = ({ jobId, ...props }: OldGetAttachmentsProps) => (
  <Get<OldResponseAttachments, unknown, void, OldGetAttachmentsPathParams>
    path={`/v3/jobs/${jobId}/relationships/attachments`}
    {...props}
  />
);

export type UseOldGetAttachmentsProps = Omit<
  UseGetProps<OldResponseAttachments, unknown, void, OldGetAttachmentsPathParams>,
  'path'
> &
  OldGetAttachmentsPathParams;

/**
 * List attachments
 *
 * Get file download URLs for a job. Each attachment's ID is a signed URL where the file may be downloaded from.
 */
export const useOldGetAttachments = ({ jobId, ...props }: UseOldGetAttachmentsProps) =>
  useGet<OldResponseAttachments, unknown, void, OldGetAttachmentsPathParams>(
    (paramsInPath: OldGetAttachmentsPathParams) => `/v3/jobs/${paramsInPath.jobId}/relationships/attachments`,
    { pathParams: { jobId }, ...props },
  );

export interface OldListJobsQueryParams {
  /**
   * Only return jobs with this status (case-insensitive label, or numeric value)
   */
  status?: string;
  /**
   * Only return jobs with this reference ID (case-insensitive, special characters should be URL encoded)
   */
  'reference-id'?: UrlEncodedString;
}

export type OldListJobsProps = Omit<GetProps<OldResponseListJobs, unknown, OldListJobsQueryParams, void>, 'path'>;

/**
 * List jobs
 *
 * List all available jobs, filtered by status if a status query param is provided.
 */
export const OldListJobs = (props: OldListJobsProps) => (
  <Get<OldResponseListJobs, unknown, OldListJobsQueryParams, void> path={`/v3/jobs`} {...props} />
);

export type UseOldListJobsProps = Omit<UseGetProps<OldResponseListJobs, unknown, OldListJobsQueryParams, void>, 'path'>;

/**
 * List jobs
 *
 * List all available jobs, filtered by status if a status query param is provided.
 */
export const useOldListJobs = (props: UseOldListJobsProps) =>
  useGet<OldResponseListJobs, unknown, OldListJobsQueryParams, void>(`/v3/jobs`, props);

export type OldCreateJobProps = Omit<
  MutateProps<OldResponseUpdateJob, unknown, void, RequestCreateJob, void>,
  'path' | 'verb'
>;

/**
 * Create job
 *
 * Create a pending job for a customer at a location.
 */
export const OldCreateJob = (props: OldCreateJobProps) => (
  <Mutate<OldResponseUpdateJob, unknown, void, RequestCreateJob, void> verb="POST" path={`/v3/jobs`} {...props} />
);

export type UseOldCreateJobProps = Omit<
  UseMutateProps<OldResponseUpdateJob, unknown, void, RequestCreateJob, void>,
  'path' | 'verb'
>;

/**
 * Create job
 *
 * Create a pending job for a customer at a location.
 */
export const useOldCreateJob = (props: UseOldCreateJobProps) =>
  useMutate<OldResponseUpdateJob, unknown, void, RequestCreateJob, void>('POST', `/v3/jobs`, props);

export interface OldGetJobPathParams {
  jobId: Uuid;
}

export type OldGetJobProps = Omit<GetProps<OldResponseGetJob, unknown, void, OldGetJobPathParams>, 'path'> &
  OldGetJobPathParams;

/**
 * Get job
 *
 * Retrieve a job's details by its ID.
 */
export const OldGetJob = ({ jobId, ...props }: OldGetJobProps) => (
  <Get<OldResponseGetJob, unknown, void, OldGetJobPathParams> path={`/v3/jobs/${jobId}`} {...props} />
);

export type UseOldGetJobProps = Omit<UseGetProps<OldResponseGetJob, unknown, void, OldGetJobPathParams>, 'path'> &
  OldGetJobPathParams;

/**
 * Get job
 *
 * Retrieve a job's details by its ID.
 */
export const useOldGetJob = ({ jobId, ...props }: UseOldGetJobProps) =>
  useGet<OldResponseGetJob, unknown, void, OldGetJobPathParams>(
    (paramsInPath: OldGetJobPathParams) => `/v3/jobs/${paramsInPath.jobId}`,
    { pathParams: { jobId }, ...props },
  );

export interface OldUpdateJobPathParams {
  jobId: Uuid;
}

export type OldUpdateJobProps = Omit<
  MutateProps<OldResponseUpdateJob, unknown, void, RequestUpdateJob, OldUpdateJobPathParams>,
  'path' | 'verb'
> &
  OldUpdateJobPathParams;

/**
 * Update job
 *
 * Update the properties of a Job.
 */
export const OldUpdateJob = ({ jobId, ...props }: OldUpdateJobProps) => (
  <Mutate<OldResponseUpdateJob, unknown, void, RequestUpdateJob, OldUpdateJobPathParams>
    verb="PATCH"
    path={`/v3/jobs/${jobId}`}
    {...props}
  />
);

export type UseOldUpdateJobProps = Omit<
  UseMutateProps<OldResponseUpdateJob, unknown, void, RequestUpdateJob, OldUpdateJobPathParams>,
  'path' | 'verb'
> &
  OldUpdateJobPathParams;

/**
 * Update job
 *
 * Update the properties of a Job.
 */
export const useOldUpdateJob = ({ jobId, ...props }: UseOldUpdateJobProps) =>
  useMutate<OldResponseUpdateJob, unknown, void, RequestUpdateJob, OldUpdateJobPathParams>(
    'PATCH',
    (paramsInPath: OldUpdateJobPathParams) => `/v3/jobs/${paramsInPath.jobId}`,
    { pathParams: { jobId }, ...props },
  );
